import {
  Container,
  KGContext,
  ContentHeader,
  TableDocs,
  ActionIcon,
  DialogPre,
  Paragraph,
  SkeletonController,
} from "@knowgistics/core";
import { SubjectiveContext, connectSubjective } from "./index.context";
import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { faEye, faPaperclip } from "@fortawesome/pro-duotone-svg-icons";
import { firebase } from "Controller/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PageSubjective = connectSubjective(({ ...props }) => {
  const { id, secid, sjvid, controller, ...store } =
    React.useContext(SubjectiveContext);
  const { t } = React.useContext(KGContext);
  const [state] = store.state;

  const handleApprove = (studentID) => async () => {
    if (studentID) {
      const control = new SkeletonController(firebase);
      await control.path("courses", sjvid).update({
        [`${studentID}.status`]: "complete",
      });
      return true;
    }
    return false;
  };

  const config = (data) => ({
    checkbox: true,
    fetched: state.fetched,
    search: ["studentID", "studentName"],
    docs: data && data.users,
    rowId: "id",
    defaultSort: "edit",
    defaultOrder: "desc",
    columns: [
      { label: "ID", id: "studentID", width: 20, sortable: true },
      { label: "Name", id: "studentName", width: 45, sortable: true },
      {
        label: "status",
        id: "status",
        value: (row) => (
          <Typography variant="caption" noWrap>
            {row.status}
          </Typography>
        ),
        width: 20,
        sortable: true,
      },
      {
        id: "action",
        value: (row) => {
          if (state?.course?.[row?.studentID]?.student) {
            const { value, file } =
              state?.course?.[row?.studentID]?.student ?? {};
            return value ? (
              <DialogPre
                title={row?.studentName}
                button={<ActionIcon icon={faEye} />}
                onConfirm={
                  state?.course?.[row?.studentID]?.status !== "complete"
                    ? handleApprove(row?.studentID)
                    : null
                }
                confirmButtonProps={{ children: t("Approve") }}
              >
                <Paragraph value={value} />
                {file && (
                  <Link
                    href={file.content?.original}
                    target="_blank"
                    variant="caption"
                    color="textSecondary"
                  >
                    <FontAwesomeIcon icon={faPaperclip} />
                    &nbsp;
                    {file?.content?.name}
                  </Link>
                )}
              </DialogPre>
            ) : null;
          } else {
            return null;
          }
        },
      },
    ],
  });
  return (
    <Box py={6}>
      <Container maxWidth="sm">
        <ContentHeader
          label={state.course && state.course.title}
          breadcrumbs={[{ label: t("Home"), to: "/" }]}
        />
        {state?.course?.question && (
          <Box pb={4}>
            <Paragraph value={state?.course?.question} />
            {console.log(state.course)}
          </Box>
        )}
        {secid && state.fetched && (
          <TableDocs {...config(state.sections.find((s) => s.id === secid))} />
        )}
      </Container>
    </Box>
  );
});
