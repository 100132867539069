import { DialogPre, KuiActionIcon } from "@knowgistics/core";
import { MenuItem, Select } from "@material-ui/core";
import { useContext, useState } from "react";
import { LessonListsContext } from "./index.context";

export const LessonClone = ({ id, ...props }) => {
  const {
    state: [state],
    controller,
  } = useContext(LessonListsContext);
  const [parent, setParent] = useState("");

  const handleChangeParent = ({ target: { value } }) => setParent(value);
  const handleCopy = async (user) => {
    await controller.lesson.clone({ lessonId: id, courseId: parent });
  };

  return (
    <>
      <DialogPre
        title="Copy to"
        button={<KuiActionIcon tx="copy" />}
        confirmButtonProps={{ disabled: !Boolean(parent) }}
        onConfirm={handleCopy}
      >
        <Select
          fullWidth
          variant="outlined"
          displayEmpty
          value={parent}
          onChange={handleChangeParent}
        >
          <MenuItem value="" disabled>
            -- Select Course --
          </MenuItem>
          {state?.courses?.map((doc) => (
            <MenuItem key={doc.id} value={doc.id}>
              {doc.title}
            </MenuItem>
          ))}
        </Select>
      </DialogPre>
    </>
  );
};
