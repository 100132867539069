import { KGContext } from "@knowgistics/core";
import { GradingController } from "./ctl";
import { firebase } from "Controller/firebase";
import { getTypeIcon } from "main.comp";

const { createContext, useContext, useEffect, useState } = require("react");

const GradingContext = createContext({});

const GradingProvider = ({ children, ...props }) => {
  const { id, secid } = props.match.params;
  const { user, t } = useContext(KGContext);
  const [control, setControl] = useState(null);
  const [state, setState] = useState({
    fetched: false,
  });

  const columns = () => {
    const fields = (state?.fields ?? []).map((f) => ({
      field: f?.id,
      headerName: (
        <>
          {getTypeIcon(f?.type, { style: { marginRight: 8 } })}
          {f?.title}
        </>
      ),
      width: 200,
      type: "number",
      editable: f?.type?.includes("question") ? false : true,
      valueGetter: ({ value, hasFocus, field, row, ...params }) => {
        return hasFocus ? value : `${row[`${field}-weighted`]} (${value})`;
      },
    }));
    return [
      { field: "id", headerName: "ID", width: 120 },
      { field: "studentName", headerName: "Name", width: 200 },
      { field: "total", headerName: "Total", width: 120 },
      { field: "grade", headerName: "Grade", width: 130 },
    ].concat(fields);
  };
  const rows = () => {
    const weightSum = (state?.fields ?? []).reduce((total, f) => {
      return total + parseFloat(f?.weight ?? control.dfWeight);
    }, 0);
    const r = (state?.students ?? []).map((row) => {
      let total = 0;
      const fields = state?.fields?.map((f) => {
        let score = 0,
          weighted = 0;
        if (f?.type?.includes("question")) {
          const quizScore = state?.scores?.[`${row?.id}-${f?.id}`];
          score = quizScore?.score ?? 0;
          weighted =
            (score * (parseFloat(f?.weight ?? 0) || control.dfWeight)) /
            weightSum;
        } else if (
          f?.type?.includes("subjective") ||
          f?.type?.includes("custom-score")
        ) {
          score = f?.["score-" + row?.id] ?? 0;
          weighted =
            (score * (parseFloat(f?.weight ?? 0) || control.dfWeight)) /
            weightSum;
        } else {
          console.log("DataGrid => rows => ", f);
        }
        total += weighted;
        return {
          [f?.id]: control.fixed(score),
          [`${f?.id}-weighted`]: control.fixed(weighted),
        };
      });
      return Object.assign({}, row, ...fields, {
        total: control.fixed(total),
        grade: control.getGrade(total),
      });
    }).filter(r=>r?.studentID);
    return r;
  };

  const store = {
    id,
    secid,
    states: [state, setState],
    columns,
    rows,
    control,
    user,
    t,
  };

  useEffect(() => {
    if (id && secid && user) {
      const control = new GradingController (firebase, user, id, secid);
      setControl(control);
      const unwatch = control.watch((data) =>
        setState((s) => Object.assign({}, s, { fetched: true }, data))
      );
      return async () => (await unwatch)();
    }
  }, [id, secid, user]);

  return (
    <GradingContext.Provider value={store}>{children}</GradingContext.Provider>
  );
};

export const connectGrading = (Comp) => (props) =>
  <GradingProvider {...props} children={<Comp {...props} />} />;

export const injectGrading = (Comp) => (props) => {
  const store = useContext(GradingContext);
  return <Comp {...props} store={store} />;
};
