import "@knowgistics/core/dist/index.css";
import { connectKG, KGContext, MainContainer } from "@knowgistics/core";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import { firebaseConfig } from "./Controller/firebase";
import logo from "./chula_logo.svg";

import { PageCourse } from "./Pages/Course";
import { PageCourseEdit } from "./Pages/CourseEdit";
import { PageCourseHome } from "./Pages/CourseHome";
import { PageNotFound } from "./Pages/NotFound";

import { PageLessonEdit } from "./Pages/Lesson/Edit";
import { QuestionBankEdit } from "./Pages/QuestionBankEdit";
import { QuestionView } from "./Pages/Preview/QuestionView";
import { CourseView } from "./Pages/Preview/CourseView";
import { LessonView } from "./Pages/Preview/LessonView";

import QuestionSortingEdit from "./Pages/QuestionType/sorting";
import QuestionTruefalseEdit from "./Pages/QuestionType/truefalse";
import QuestionmultipleEdit from "./Pages/QuestionType/multiple";
import QuestionmatchingEdit from "./Pages/QuestionType/matching";

import { PageSectionEdit } from "./Pages/Section/SectionEdit";
import { PageClassSection } from "./Pages/Section/ClassSection";
import { PageViewCourse } from "Pages/ViewCourse";
import { PageQuestionEdit } from "Pages/QuestionEdit";
import { PageViewQuestion } from "Pages/ViewQuestion";
import { SubjectiveEdit } from "Pages/Subjective";
import { PageIntroductionEdit } from "Pages/Introduction";

import { PageManageStudent } from "Pages/ManageStudent";
import { PageManageTeacher } from "Pages/ManageTeacher";
import React, { useContext } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { connectMain } from "context";
// import { NotAllow } from "Pages/NotAllow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGraduate,
  faChalkboardTeacher,
  fad,
} from "@fortawesome/pro-duotone-svg-icons";
import { PageChat } from "Pages/Chat";
import { KGStore } from "kg.core";
import { PageManageSection } from "Pages/ManageSection";
import { PageTaSectionEdit } from "Pages/TaSectionEdit";
import { PageTaSectionChat } from "Pages/TaSectionChat";
import { library } from "@fortawesome/fontawesome-svg-core";
import { PageSectionSubjectiveList } from "Pages/SectionSubjectiveList";

library.add(fad);

const App = connectMain(({ store }: { store: KGStore }) => {
  const { state: kgstates } = useContext(KGContext);
  const [kgstate] = kgstates;
  const { state: states } = store;
  const [state] = states;

  console.log(state?.role)

  if (!state.fetched || !kgstate.userFetched)
    return (
      <>
        <BrowserRouter>
          <MainContainer loading />
        </BrowserRouter>
      </>
    );
  else {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path={[
              "/edit/:id/section/:secid/subjective/:sjvid",
              "/ta/:id/:secid/subjective/:sjvid",
            ]}
            component={PageSectionSubjectiveList}
          />
          <Route path="/manage/student" component={PageManageStudent} />
          <Route path="/manage/teacher" component={PageManageTeacher} />
          <Route
            path="/ta/section/:id/:secid/chat/:studentid?"
            component={PageTaSectionChat}
          />
          <Route
            path="/ta/section/:id/:secid/:tab?"
            component={PageTaSectionEdit}
          />
          <Route path="/ta/section" component={PageManageSection} />
          {/* START VIEW */}
          <Route
            path="/view/:id/subjective/:sjvid/section/:secid?"
            exact
            component={SubjectiveEdit}
          />
          <Route path="/view/:id/question/:qid" component={PageViewQuestion} />
          <Route
            path={["/view/:id/lesson/:lid", "/view/:id/"]}
            component={PageViewCourse}
          />
          {/* END VIEW */}
          <Route
            path="/edit/:id/section/:secid/chat/:studentid?"
            component={PageChat}
          />
          <Route path="/edit/:id/home" component={PageCourseHome} />
          <Route
            path="/edit/:id/section/:secid/class/:classid"
            component={PageClassSection}
          />
          <Route
            path="/edit/:id/section/:secid/:tab?"
            component={PageSectionEdit}
          />
          <Route
            path="/edit/:id/lesson/edit/:lsid"
            component={PageLessonEdit}
          />
          {/* START VIEW */}
          <Route
            path="/edit/:id/question/:catid/:quizid?"
            component={PageQuestionEdit}
          />
          <Route
            path="/question/edit/sorting/:parent/:id?"
            component={QuestionSortingEdit}
          />
          <Route
            path="/question/edit/matching/:parent/:id?"
            component={QuestionmatchingEdit}
          />
          <Route
            path="/question/edit/multiple/:parent/:id?"
            component={QuestionmultipleEdit}
          />
          <Route
            path="/question/edit/truefalse/:parent/:id?"
            component={QuestionTruefalseEdit}
          />
          {/* END VIEW */}
          <Route path="/question/view/:id/:parentID" component={QuestionView} />
          <Route path="/course/view/:id" component={CourseView} />
          <Route path="/lesson/view/:id" component={LessonView} />
          <Route
            path="/introduction/edit/:id"
            component={PageIntroductionEdit}
          />
          <Route
            path="/question/edit/:id/:parentID"
            component={QuestionBankEdit}
          />

          <Route path="/edit/:id/:tab?" exact component={PageCourseEdit} />
          <Route path="/" exact component={PageCourse} />
          <Route path="/" component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
  // else {
  //   if (user) {
  //     return <NotAllow />;
  //   } else {
  //     return (
  //       <BrowserRouter>
  //         <MainContainer signInOnly />
  //       </BrowserRouter>
  //     );
  //   }
  // }
});

const EnhanceProfileSecondary = () => {
  const { t } = useContext(KGContext);
  return (
    <React.Fragment>
      <ListItem button component={Link} to="/manage/student">
        <ListItemIcon>
          <FontAwesomeIcon icon={faUserGraduate} />
        </ListItemIcon>
        <ListItemText primary={t("Student")} />
      </ListItem>
      <ListItem button component={Link} to="/ta/section">
        <ListItemIcon>
          <FontAwesomeIcon icon={["fad", "user-edit"]} />
        </ListItemIcon>
        <ListItemText primary={t("Staff")} />
      </ListItem>
      <ListItem button component={Link} to="/manage/teacher">
        <ListItemIcon>
          <FontAwesomeIcon icon={faChalkboardTeacher} />
        </ListItemIcon>
        <ListItemText primary={t("Teacher")} />
      </ListItem>
    </React.Fragment>
  );
};

export default connectKG({
  firebaseConfig,
  Link,
  logo,
  theme: {
    palette: {
      text: {
        primary: "#1d1d1f",
        secondary: "#86868b",
      },
    },
  },
  profileSecondaryActions: <EnhanceProfileSecondary />,
})(App);
