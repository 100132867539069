import { React } from "react";
import { BackLink, SaveButton } from "@knowgistics/core";

export const Sidebar = (props) => {

  return (
    <>
      <BackLink to={"/"} />
      <SaveButton />
    </>
  );
};
