import { KGContext } from "@knowgistics/core";
import { useContext } from "react";
import { ViewCourse } from '../../Components/ViewCourse'

export const PageViewCourse = (props) => {
  const { id, lid } = props.match.params;
  const { t } = useContext(KGContext);

  return (
    <ViewCourse
      back="/"
      id={id}
      selected={lid}
      baseURL={`/view/${id}`}
      sitename={process.env.REACT_APP_SITE_NAME}
      breadcrumbs={[{ label: t("Home"), to: "/" }]}
    />
  );
};
