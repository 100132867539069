import { KGContext } from "@knowgistics/core";
import { firebase } from "Controller/firebase";
import { createContext, useEffect, useState, useContext } from "react";

class SectionController {
    constructor({ user, id, secid, addAlert }) {
        this.addAlert = addAlert;
        this.user = user;
        this.id = id;
        this.secid = secid;
    }
    watch = (callback) => {
        return firebase
            .firestore()
            .collection("sections")
            .doc(this.secid)
            .onSnapshot((snap) => {
                callback({ ...snap.data() });
            });
    };
    watchStudent = (callback) => {
        return firebase
            .firestore()
            .collection("students")
            .where("studentName", ">=", "")
            .onSnapshot((snap) => {
                const docs = snap.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                callback(docs);
            });
    };

    watchClass = (callback) => {
        return firebase
            .firestore()
            .collection("sections")
            .where("parent", "==", this.secid)
            .onSnapshot((snap) => {
                const docs = snap.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                callback(docs);
            });
    };

    addClass = (data) => {
        return firebase
            .firestore()
            .collection("sections")
            .add({
                ...data,
                datecreate: firebase.firestore.FieldValue.serverTimestamp(),
                datemodified: firebase.firestore.FieldValue.serverTimestamp(),
                parent: this.secid,
                user: this.user.uid,
                prefix: process.env.REACT_APP_PREFIX,
            });
    };
    updateClass = (data) => {
        return firebase
            .firestore()
            .collection("sections")
            .doc(data.id)
            .update({
                ...data,
                datemodified: firebase.firestore.FieldValue.serverTimestamp(),
            });
    };

    removeClass = (id) => {
        return firebase.firestore().collection("sections").doc(id).delete();
    };

    update = async (data) => {
        await firebase
            .firestore()
            .collection("sections")
            .doc(this.secid)
            .update({
                ...data,
                datemodified: firebase.firestore.FieldValue.serverTimestamp(),
            });
    };
    addstudent = async (data) => {
        return firebase
            .firestore()
            .collection("students")
            .doc(data.studentID)
            .set({
                ...data,
                prefix: process.env.REACT_APP_PREFIX,
            });
    };
    Importexcel = async (data) => {
        if (Array.isArray(data)) {
            // student ID length must be 11 digit
            const filteredData = data
                .map((row) => row.map((cell) => cell.toString().trim()))
                .filter((row) => row.length > 1 && row[1].length > 8);
            if (filteredData.length) {
                const batch = firebase.firestore().batch();
                filteredData.map((row) =>
                    batch.set(
                        firebase.firestore().collection("students").doc(row[1]),
                        {
                            studentName: row[2],
                            studentID: row[1],
                            prefix: process.env.REACT_APP_PREFIX,
                        },
                        { merge: true }
                    )
                );
                await batch.commit();
                await firebase
                    .firestore()
                    .collection("sections")
                    .doc(this.secid)
                    .update({
                        students: firebase.firestore.FieldValue.arrayUnion(
                            ...filteredData.map((row) => row[1])
                        ),
                    });
                this.addAlert({ label: "Import data success.", severity: "success" });
            } else {
                this.addAlert({ label: "No valid data to import.", severity: "error" });
            }
        } else {
            this.addAlert({ label: "Invalid data.", severity: "error" });
        }
    };
}

export const SectionContext = createContext({});

const SectionProvider = ({ children, ...props }) => {
    const { user, addAlert } = useContext(KGContext);
    const [control, setControl] = useState(null);

    const [state, setState] = useState({
        fetched: false,
    });
    const [data, setData] = useState({
        section: [],
        student: [],
        studentSec: [],
        selected: [],
    });
    const [classes, setClasses] = useState({
        docs: [],
        edit: [],
    });

    const store = {
        ...props,
        ...props.match.params,
        control,
        data: [data, setData],
        state: [state, setState],
        classes: [classes, setClasses],
    };


    useEffect(() => {
        document.title = `Section — ${data.section.title} | ${process.env.REACT_APP_SITE_NAME}`;

        if (user) {
            const control = new SectionController({ user, id: store.id, secid: store.secid, addAlert });
            setControl(control);
            return control.watchStudent((data => {
                control.watch((section) => {
                    let student = [];
                    (section.students || []).forEach(sectionStd => {
                        let selected = data.find(stdData => stdData.studentID === sectionStd);
                        if (selected) {
                            student.push(selected)
                        }
                    })
                    setState(s => ({ ...s, fetched: true }));
                    setData(s => ({ ...s, section: section, student: data, studentSec: student }));
                })
                control.watchClass((classData) => {
                    setState(s => ({ ...s, fetched: true }));
                    setClasses({ docs: classData || [] })
                })
            }))
        }
    }, [user, store.id, store.secid, data.section.title, addAlert]);
    return (
        <SectionContext.Provider value={store}>{children}</SectionContext.Provider>
    );
};

export const connectSection = (Comp) => (props) =>
(
    <SectionProvider {...props}>
        <Comp {...props} />
    </SectionProvider>
);
