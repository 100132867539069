import { useState, useEffect, useContext } from "react";
import {
  Container,
  ContentHeader,
  DuotoneButton,
  KGContext,
  DialogRemove,
  KuiActionIcon,
} from "@knowgistics/core";
import { Box } from "@material-ui/core";
import { faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { QuestionController } from "Controller/questionController";
import { Link } from "react-router-dom";
import { QBClone } from "./clone";
import { connectClone } from "./ctx";
import { QuestionBankBin } from "./bin";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";

export const QuestionBank = connectClone(({ history, ...props }) => {
  const { id } = props.match.params;
  const { user, t } = useContext(KGContext);
  const [controller, setController] = useState(null);
  const [state, setState] = useState({
    fetched: false,
    docs: [],
  });

  const handleAdd = async (props) => {
    const result = await controller.add();
    if (result.id) {
      history.push(`/question/edit/${result.id}/${id}`);
    }
  };

  const handleRemove = (remove) => () => setState((s) => ({ ...s, remove }));
  const handleRemoveConfirm = async () => {
    if (state.remove) {
      await controller.remove(state.remove.id);
      setState((s) => ({ ...s, remove: null }));
    }
  };

  useEffect(() => {
    document.title = `Course Manager | ${process.env.REACT_APP_SITE_NAME}`;
    if (user) {
      const controller = new QuestionController(user, id);
      setController(controller);
      return controller.watch((docs) => {
        setState((s) => ({ ...s, docs, fetched: true }));
      });
    }
  }, [user, id]);

  return (
    <Box py={6}>
      <Container maxWidth="sm">
        <ContentHeader
          label={t("Courses.QuestionBank")}
          breadcrumbs={[
            { label: t("Home"), to: "/" },
            { label: t("Courses.MyCourse"), to: "/" },
            { label: t("Courses.QuestionBank") },
          ]}
          actions={
            <>
              <DuotoneButton
                variant="outlined"
                icon={faPlus}
                onClick={handleAdd}
              >
                {t("Add")}
              </DuotoneButton>
              &nbsp;
              <QuestionBankBin courseId={id} />
            </>
          }
        />
        <DataGrid
          loading={!state?.fetched}
          rows={state?.docs}
          columns={[
            { field: "title", headerName: t("Title"), width: 360 },
            {
              field: "datemodified",
              headerName: t("Date"),
              valueGetter: (params) => params?.value?.toMillis(),
              renderCell: (params) =>
                moment(params?.value).format("YYYY/MM/DD HH:mm"),
              filterable: false,
              width: 240,
            },
            {
              field: "action",
              headerName: "",
              align: "center",
              renderCell: ({ row }) => {
                return (
                  <>
                    <QBClone questionBankId={row?.id} />
                    <KuiActionIcon
                      tx="edit"
                      component={Link}
                      to={`/question/edit/${row.id}/${row.parent}`}
                    />
                    <KuiActionIcon tx="remove" onClick={handleRemove(row)} />
                  </>
                );
              },
            },
          ]}
          sortModel={[{ field: "datemodified", sort: "desc" }]}
          autoHeight
          disableSelectionOnClick
        />
        <DialogRemove
          title="Remove Course"
          label={t("Courses.DoYouWantToRemove", {
            name: state.remove ? state.remove.title : "",
          })}
          open={Boolean(state.remove)}
          onClose={handleRemove(null)}
          onConfirm={handleRemoveConfirm}
        />
      </Container>
    </Box>
  );
});
