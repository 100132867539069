import { faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { DuotoneButton, KuiButton } from "@knowgistics/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField as tf,
  withStyles,
} from "@material-ui/core";
import { useRef, useState } from "react";
import { injectGrading } from "./ctx";

const TextField = withStyles((theme) => ({
  root: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
}))(tf);

export const visibilities = [
  { label: "Public", value: `${process.env.REACT_APP_PREFIX}custom-score` },
  {
    label: "Private",
    value: `${process.env.REACT_APP_PREFIX}custom-score-private`,
  },
];
const defaultData = {
  title: "",
  weight: 0,
  full: 0,
  type: `${process.env.REACT_APP_PREFIX}custom-score`,
};
export const DialogAddField = injectGrading(
  ({
    store: {
      control,
      t,
    },
    ...props
  }) => {
    const refs = useRef({});
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({ ...defaultData });
    const isComplete = Boolean(
      data.title && data.weight && data.full && data.type
    );

    const genProps = (field) => ({
      ref: (r) => (refs.current[field] = r),
      value: data[field],
      onChange: ({ target: { value } }) =>
        setData((d) => ({ ...d, [field]: value })),
    });
    const handleOpen = (o) => () => setOpen(o);
    const handleConfirm = async () => {
      ["full", "weight", "title"].forEach((field) => {
        if (!data[field]) {
          refs.current?.[field]?.focus();
          return false;
        }
      });
      await control.header.add(data);
      setData({ ...defaultData });
      setOpen(false);
    };

    return (
      <>
        <DuotoneButton icon={faPlus} onClick={handleOpen(true)}>
          {t("Add")}
        </DuotoneButton>
        <Dialog fullWidth maxWidth="xs" open={open} onClose={handleOpen(false)}>
          <DialogTitle>{t("Add")}</DialogTitle>
          <DialogContent>
            <TextField label={t("Title")} {...genProps("title")} />
            <TextField
              label={t("Weight")}
              type="number"
              {...genProps("weight")}
            />
            <TextField label={t("Full")} type="number" {...genProps("full")} />
            <FormControl fullWidth variant="outlined">
              <InputLabel id="visibility-add" shrink>
                {t("Visibility")}
              </InputLabel>
              <Select
                labelId="visibility-add"
                labelWidth={86}
                value={data?.type}
                onChange={({ target: { value } }) =>
                  setData((d) => ({ ...d, type: value }))
                }
              >
                {visibilities.map((vs) => (
                  <MenuItem value={vs?.value} key={vs?.value}>
                    {t(vs?.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <KuiButton
              tx="confirm"
              disabled={!isComplete}
              onClick={handleConfirm}
            />
            <KuiButton tx="close" onClick={handleOpen(false)} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
);
