import React, { useContext } from "react";
import {
  Container,
  ContentHeader,
  ImageDisplay,
  MainContainer,
  KGContext,
  DialogRemove,
  Paragraph,
} from "@knowgistics/core";
import {
  Button,
  Box,
  Divider,
  Toolbar,
  Typography,
  IconButton,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Sidebar } from "./Sidebar";
import { AddButton, Heading, MatchBox, SubHeading } from "../components";
import { connectContext, MatchingContext, newAnswer } from "./Context";
import { Skeleton } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { ImagePicker } from "@knowgistics/imagepicker";
import { firebaseConfig } from "Controller/firebase";

const QuestionmatchingEdit = (props) => {
  const { user } = useContext(KGContext);
  const store = useContext(MatchingContext);
  const [data, setData] = store.data;
  const [state, setState] = store.state;

  const handleChange = (key) => (e) =>
    e
      ? setData((d) => ({ ...d, [key]: e.target ? e.target.value : e }))
      : false;
  const handleTypeChange = (index) => (ev) =>
    setData((d) => {
      let answers = [...d.answers];
      answers[index].type = ev.target.value;
      return { ...d, answers };
    });
  const handleAskChange = (index) => (content) =>
    setData((d) => {
      let answers = [...d.answers];
      answers[index].label = content;
      return { ...d, answers };
    });
  const handleAnswerChange = (index) => (ev) =>
    setData((d) => {
      let answers = [...d.answers];
      answers[index].value = ev.target.value;
      return { ...d, answers };
    });
  const handleAddAnswer = () =>
    setData((d) => {
      let answers = [...d.answers];
      answers.push(newAnswer());
      return { ...d, answers };
    });
  const handleRemoveAnswer = (remove) => () =>
    setState((s) => ({ ...s, remove }));
  const handleRemoveAnswerConfirm = () =>
    setData((d) => {
      let answers = [...d.answers];
      answers.splice(state.remove, 1);
      setState((s) => ({ ...s, remove: -1 }));
      return { ...d, answers };
    });
  const handleChangeImage =
    (index) =>
    ([id]) =>
      setData((d) => {
        let answers = [...d.answers];
        answers[index].data = {
          ...id.content,
          id: id.id,
        };
        return { ...d, answers };
      });

  return (
    <MainContainer dense signInOnly sidebar={<Sidebar />}>
      <Box py={6}>
        <Container maxWidth="sm">
          <ContentHeader label="Matching" breadcrumbs={[{ label: "Home" }]} />
          <Heading>Instruction</Heading>
          {state.fetching ? (
            <Skeleton width="50%" />
          ) : (
            <Paragraph
              editOnly
              value={data.question}
              onChange={handleChange("question")}
            />
          )}

          <Box my={5} children={<Divider />} />
          {data.answers.map((ans, index) => (
            <MatchBox key={ans.key}>
              <Toolbar>
                <Typography variant="h5">Matching {index + 1}</Typography>
                <Box flexGrow={1} />
                {data.answers.length > 2 && (
                  <IconButton
                    color="secondary"
                    size="small"
                    edge="end"
                    onClick={handleRemoveAnswer(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                )}
              </Toolbar>
              <Divider />
              <Box p={3}>
                <Box display="flex" alignItems="center" mb={1}>
                  <SubHeading>Quiz</SubHeading>
                  <Box flexGrow={1} />
                  <Typography variant="caption" color="textSecondary">
                    Type:&nbsp;
                  </Typography>
                  <Select
                    value={ans.type}
                    onChange={handleTypeChange(index)}
                    disabled={state.fetching}
                  >
                    <MenuItem value="paragraph">Paragraph</MenuItem>
                    <MenuItem value="image">Image</MenuItem>
                  </Select>
                </Box>
                {(() => {
                  switch (ans.type) {
                    case "paragraph":
                      return state.fetching ? (
                        <Skeleton width="50%" />
                      ) : (
                        <Paragraph
                          editOnly
                          value={ans.label}
                          onChange={handleAskChange(index)}
                        />
                      );
                    case "image":
                      return (
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          {ans.data.id && (
                            <ImageDisplay
                              image={ans.data}
                              mb={1}
                              id={ans.data.id}
                              style={{
                                width: "100%",
                                maxWidth: 256,
                                marginBottom: 24,
                              }}
                            />
                          )}
                          {!state.fetching && (
                            <ImagePicker
                              user={user}
                              config={firebaseConfig}
                              onConfirm={handleChangeImage(index)}
                            >
                              <Button
                                variant="outlined"
                                startIcon={
                                  <FontAwesomeIcon icon={faFolderOpen} />
                                }
                              >
                                Browse
                              </Button>
                            </ImagePicker>
                          )}
                        </Box>
                      );
                    default:
                      return null;
                  }
                })()}
                <Box mb={3} />
                <SubHeading>Answer</SubHeading>
                <TextField
                  fullWidth
                  label="คำตอบ"
                  variant="outlined"
                  value={ans.value}
                  disabled={state.fetching}
                  onChange={handleAnswerChange(index)}
                />
              </Box>
            </MatchBox>
          ))}
          <AddButton onClick={handleAddAnswer} />
          <DialogRemove
            title="Remove Answer"
            label={state.remove ? <>Do you want to remove ?</> : null}
            open={state.remove > -1}
            onClose={handleRemoveAnswer(-1)}
            onConfirm={handleRemoveAnswerConfirm}
          />
        </Container>
      </Box>
    </MainContainer>
  );
};

export default connectContext(QuestionmatchingEdit);
