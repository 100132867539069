import {
  BackLink,
  KGContext,
  KuiListItemText,
  ListItemLink,
  TitleEdit,
} from "@knowgistics/core";
import { List } from "@material-ui/core";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { SectionContext } from "./context";

export const Sidebar = (props) => {
  const { addAlert, t } = useContext(KGContext);
  const { id, secid, tab, control, ...store } = useContext(SectionContext);
  const [data] = store.data;

  const handleChange = (key) => async (value) => {
    const filterValue = JSON.parse(JSON.stringify(value));
    await control.update({ [key]: filterValue });
    addAlert({ label: "Update Success." });
  };

  return (
    <>
      <BackLink to={props.back || `/edit/${id}/section`} />
      <TitleEdit value={data.section.title} onChange={handleChange("title")} />
      <List disablePadding>
        {[
          { label: t("Student"), key: "student" },
          { label: t("Class"), key: "class" },
          { label: t("Subjective"), key: "subjective" },
          { label: t("Grading"), key: "grading" },
          { label: t("Chat"), key: "chat" },
          { label: t("Staff"), key: "manageTA"},
        ].map((item, index) => (
          <ListItemLink
            button
            dense
            divider
            component={Link}
            key={item.key}
            to={
              props.back
                ? props.back + `/${id}/${secid}/${item.key}`
                : `/edit/${id}/section/${secid}/${item.key}`
            }
            selected={tab === item.key || (index === 0 && !tab)}
          >
            <KuiListItemText tx="sidebar" primary={item.label} />
          </ListItemLink>
        ))}
      </List>
    </>
  );
};
