import { MainCtl } from "./mainctl";
import { firebase } from "Controller/firebase";

export interface SectionDocument {
  datecreate: firebase.firestore.Timestamp;
  datemodified: firebase.firestore.Timestamp;
  parent: string;
  prefix: string;
  students: string[];
  title: string;
  type: string;
  user: string;
}
export interface SubjectiveDocument {
  id: string;
  question?: any
  sort: number;
}
export interface SubjectiveInboxDocument {
  id: string;
}

export class SectionSubjectiveCtl extends MainCtl {
  static watchList(
    courseId: string,
    sectionId: string
  ): Promise<SubjectiveDocument[]> {
    return new Promise(async (resolve, reject) => {
      const sections = (
        await this.db()
          .collection("courses")
          .where("parent", "==", courseId)
          .where("type", "in", [
            `${this.prefix}subjective`,
            `${this.prefix}subjective-private`,
          ])
          .get()
      ).docs
        .map((doc) => this.toDoc<SubjectiveDocument>(doc))
        .sort((a, b) => a.sort - b.sort);
      resolve(sections);
    });
  }
  static watchOne(
    sectionId: string,
    subjectiveId: string
  ): Promise<{
    section: SectionDocument;
    subjective: SubjectiveDocument;
    docs: SubjectiveInboxDocument[];
  }> {
    return new Promise(async (resolve, reject) => {
      const section = (
        await this.db().collection("sections").doc(sectionId).get()
      ).data() as SectionDocument;
      const subjective = (
        await this.db().collection("courses").doc(subjectiveId).get()
      ).data() as SubjectiveDocument;
      const docs = (
        await this.db()
          .collection("courses")
          .doc(subjectiveId)
          .collection("inbox")
          .get()
      ).docs.map((doc) => this.toDoc<SubjectiveInboxDocument>(doc));
      resolve({ section, subjective, docs });
    });
  }
}
