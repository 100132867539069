import React from "react";
import { Container, MainContainer, ContentHeader } from "@knowgistics/core";
import { Box } from "@material-ui/core";

export const LessonView = (props) => {
  const { id } = props.match.params;

  return (
    <MainContainer dense signInOnly>
      <Box py={6}>
        <Container maxWidth="sm">
          <ContentHeader
            label={`View Lesson || ${id}`}
            breadcrumbs={[{ label: "Home" }]}
          />
        </Container>
      </Box>
    </MainContainer>
  );
};
