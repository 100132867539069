import { firebase } from "Controller/firebase";
const { createContext, useState, useContext, useEffect } = require("react");

const getRole = async (uid) => {
  const users =
    (
      await firebase
        .firestore()
        .collection("roles")
        .doc(process.env.REACT_APP_PREFIX)
        .get()
    ).data() || {};
  return users[uid] || "user";
};

const MainContext = createContext({});

export const MainProvider = ({ children, ...props }) => {
  const [state, setState] = useState({
    fetched: false,
    role: "user",
  });

  const store = {
    state: [state, setState],
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if(user && user.uid){
        getRole(user.uid).then((role) => setState((s) => ({ ...s, role, fetched: true })));
      } else {
        setState(s=>({ ...s, fetched:true, role:"user" }))
      }
    })
  }, []);

  return <MainContext.Provider value={store}>{children}</MainContext.Provider>;
};

export const connectMain = (Comp) => (props) => {
  const store = useContext(MainContext);
  return <Comp {...props} store={store} />;
};
