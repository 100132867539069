import { DialogPre, KuiActionIcon } from "@knowgistics/core";
import { MenuItem, Select } from "@material-ui/core";
import { useState } from "react";
import { injectClone } from "./ctx";
import pt from "prop-types";

export const QBClone = injectClone(
  ({
    store: {
      states: [state, setState],
      control,
    },
    questionBankId,
    ...props
  }) => {
    const [course, setCourse] = useState("");

    const handleChangeCourse = ({ target: { value } }) => setCourse(value);
    const handleConfirm = async () => {
      await control.questionbank.clone({ questionBankId, courseId: course });
    };

    return (
      <>
        <DialogPre
          title="Copy to"
          button={<KuiActionIcon tx="copy" />}
          confirmButtonProps={{ disabled: !Boolean(course) }}
          onConfirm={handleConfirm}
        >
          <Select
            fullWidth
            variant="outlined"
            value={course}
            onChange={handleChangeCourse}
            displayEmpty
          >
            <MenuItem value="" disabled>
              -- Select Course --
            </MenuItem>
            {state?.docs?.map((doc) => (
              <MenuItem key={doc?.id} value={doc?.id}>
                {doc?.title}
              </MenuItem>
            ))}
          </Select>
        </DialogPre>
      </>
    );
  }
);
QBClone.propTypes = {
  questionBankId: pt.string.isRequired,
};
