import { faFolderOpen, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import {
  ActionIcon,
  DuotoneButton,
  KGContext,
  ReadTxt,
  TableDocs,
} from "@knowgistics/core";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useContext, useState } from "react";
import update from "react-addons-update";
import { firebase } from "Controller/firebase";
import propTypes from "prop-types";

const STITxtConvert = (data) => {
  const cats = data
    .map(
      ([
        studentID,
        studentName,
        studentEnName,
        gender,
        faculty,
        department,
        un,
        courseId,
        section,
        instructor,
        timestamp,
      ]) => ({
        id: studentID,
        studentID,
        studentName,
        studentEnName,
        gender,
        faculty,
        department,
        courseId,
        section,
        instructor,
        timestamp,
        email: studentID + "@student.chula.ac.th",
      })
    )
    .filter((doc) => doc.studentID && doc.studentName && doc.section)
    .reduce((total, doc) => {
      if (total[doc.section]) {
        return update(total, { [doc.section]: { $push: [doc] } });
      } else {
        total[doc.section] = [doc];
        return total;
      }
    }, {});
  return cats;
};

const defaultState = {
  docs: null,
  lists: [],
  selectedList: "",
};
export const ImportButton = ({ sectionId, ...props }) => {
  const { t } = useContext(KGContext);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({ ...defaultState });

  const getFromList = () =>
    (state.selectedList && state.docs?.[state.selectedList]) || null;

  const handleOpen = (o) => () => setOpen(o);
  const handleTxtReady = (data) => {
    const docs = STITxtConvert(data);
    setState((s) => ({
      ...s,
      docs,
      lists: Object.keys(docs),
      selectedList: "",
    }));
  };
  const handleRemove = (row) => () => {
    const index = state.docs[state.selectedList].findIndex(
      (doc) => doc.studentID === row.studentID
    );
    setState((s) =>
      update(s, { docs: { [s.selectedList]: { $splice: [[index, 1]] } } })
    );
  };
  const handleAdd = async () => {
    const ids = getFromList().map((doc) => doc.studentID);
    const sectionDoc = firebase
      .firestore()
      .collection("sections")
      .doc(sectionId);
    const studentCol = firebase.firestore().collection("students");

    await sectionDoc.update({
      students: firebase.firestore.FieldValue.arrayUnion(...ids),
    });
    const batches = getFromList()
      .reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / 20);
        if (!result[chunkIndex]) {
          result[chunkIndex] = firebase.firestore().batch();
        }
        result[chunkIndex].set(
          studentCol.doc(process.env.REACT_APP_PREFIX + item.id),
          { ...item, prefix: process.env.REACT_APP_PREFIX },
          { merge: true }
        );
        return result;
      }, [])
      .map(async (batch) => await batch.commit());
    setState({...defaultState});
    setOpen(false);
    return await Promise.all(batches);
  };

  return (
    <>
      <DuotoneButton icon={faFolderOpen} onClick={handleOpen(true)}>
        {t("Import")}
      </DuotoneButton>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOpen(false)}>
        <DialogTitle>{t("Import")}</DialogTitle>
        <DialogContent>
          {Boolean(state.docs) ? (
            <Select
              fullWidth
              variant="outlined"
              value={state.docs?.[state.selectedList] ? state.selectedList : ""}
              displayEmpty
              onChange={({ target: { value } }) =>
                setState((s) => ({ ...s, selectedList: value }))
              }
            >
              <MenuItem value="">-- {t("Select")} --</MenuItem>
              {Array.isArray(state.lists) &&
                state.lists.map((list) => (
                  <MenuItem key={list} value={list}>
                    {list}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <Typography color="textSecondary" style={{ textAlign: "center" }}>
              {t("PleaseUploadFile")}
            </Typography>
          )}

          {getFromList() && (
            <Box mt={2}>
              <TableDocs
                {...{
                  checkbox: true,
                  fetched: true,
                  search: ["studentName", "studentID"],
                  docs: getFromList(),
                  rowId: "studentID",
                  defaultSort: "studentID",
                  defaultOrder: "asc",
                  columns: [
                    { label: "ID", id: "studentID", width: 20, sortable: true },
                    {
                      label: "Name",
                      id: "studentName",
                      width: 75,
                      sortable: true,
                    },
                    {
                      id: "action",
                      value: (row) => (
                        <ActionIcon
                          icon={faTrash}
                          color="secondary"
                          onClick={handleRemove(row)}
                        />
                      ),
                      width: 5,
                      padding: "none",
                    },
                  ],
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <ReadTxt onReady={handleTxtReady}>
            <Button variant="outlined">{t("Upload")} .TXT</Button>
          </ReadTxt>
          <Box flex={1} />
          <Button
            color="primary"
            disabled={!Boolean(state.docs && state.selectedList)}
            onClick={handleAdd}
          >
            {t("Confirm")}
          </Button>
          <Button onClick={handleOpen(false)}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
ImportButton.propTypes = {
  sectionId: propTypes.string.isRequired,
};
