import { SubjectiveEdit } from "Pages/Subjective";
import { RouteComponentProps } from "react-router-dom";

export interface PageSectionSubjectiveListProps
  extends RouteComponentProps<{
    id: string;
    secid: string;
    subjectid: string;
  }> {
  ta?: boolean;
}

export const PageSectionSubjectiveList = (
  props: PageSectionSubjectiveListProps
) => {
  return <SubjectiveEdit {...props} hideSection ta />;
};
