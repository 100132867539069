import { ContentEdit } from '@knowgistics/core';
import { useContext, forwardRef } from "react";
import { connectIntroductionEdit, IntroductionEditContext } from "./Context";
import {
  Box,
  List,
  Select,
  ListItemText,
  ListItem,
  MenuItem,
} from "@material-ui/core";

const SelectVisibility = forwardRef(({ value, onChange, ...props }, ref) => {
  const items = [
    `${process.env.REACT_APP_PREFIX}lesson`,
    `${process.env.REACT_APP_PREFIX}lesson-private`,
  ];
  const labels = ["Public", "Private"];
  const filterValue = items.includes(value) ? value : items[1];

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };

  return (
    <Select
      fullWidth
      variant="outlined"
      ref={ref}
      value={filterValue}
      onChange={handleChange}
      {...props}
    >
      {items.map((item, index) => (
        <MenuItem key={item} value={item}>
          {labels[index]}
        </MenuItem>
      ))}
    </Select>
  );
});

export const PageIntroductionEdit = connectIntroductionEdit(props => {
  const { id, lsid, control, ...store } = useContext(IntroductionEditContext);
  const [data, setData] = store.data;

  const LessonConfig = {
    "prefix": "mek-",
    "show": ["title", "heading", "paragraph", "image", "vdo"],
    "back": `/edit/${id}/lesson`,
  }

  const handleChange = (key) => async (value) => {
    const filterValue = JSON.parse(JSON.stringify(value));
    await setData((s) => ({ ...s, [key]: filterValue }))
  };

  const handleSave = (newData) => {
    control.update(newData);
  }

  return (
    <ContentEdit
      data={data}
      config={LessonConfig}
      onSave={handleSave}
      sidebarSecondaryActions={
        <List disablePadding>
          <ListItem divider>
            <ListItemText
              primary="Visibility"
              secondary={
                <Box flex={1}>
                  <SelectVisibility
                    value={data.type}
                    onChange={handleChange("type")}
                  />
                </Box>
              }
              primaryTypographyProps={{
                variant: "caption",
                color: "textSecondary",
                style: { marginBottom: 8 },
              }}
              secondaryTypographyProps={{ component: "div" }}
            />
          </ListItem>
        </List>}
    />
  );
})

