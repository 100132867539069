import { createContext, useContext, useEffect, useState } from "react";
import { firebase } from "Controller/firebase";
import { KGContext, SkeletonController } from "@knowgistics/core";
import { UserCtl } from "Controller/users";

class Controller extends SkeletonController {
  constructor({user, id, secid}) {
    super(firebase);

    this.user = user;
    this.id = id;
    this.secid = secid;
    this.prefix = process.env.REACT_APP_PREFIX;
  }

  watch = (callback) => {
    return this.path("sections", this.secid).onSnapshot(async (snapshot) => {
      const section = snapshot.data()
      let users = []
      if(Array.isArray(section?.ta)){
        const info = await UserCtl.getUsers(this.user, section.ta);
        users = section.ta.map(id => {
          return {id,info: info.find(i=>i.uid === id) }
        })
      } 
      callback(users)
    });
  };
  add = async ({ uid, secid }) => {
    await this.path("sections", secid).update({ ta: firebase.firestore.FieldValue.arrayUnion(uid) });
  };
  removeUser = async ({ uid }) => {
    await this.path("sections", this.secid).update({ ta: firebase.firestore.FieldValue.arrayRemove(uid) });
  };
}

const ManageTAContext = createContext({});

const ManageTAProvider = ({ children, ...props }) => {
  const { t, addAlert, user } = useContext(KGContext);
  const [control, setControl] = useState();
  const [state, setState] = useState({
    fetched: false,
    docs: [],
    remove: null,
  });
  const store = {
    states: [state, setState],
    control,
    addAlert,
    user,
    t,
    ...props.match.params,
  };
  
  useEffect(() => {
    if (user) {
      const control = new Controller({ user, id: store.id, secid: store.secid });
      setControl(control);
      return control.watch((docs) => {
        setState((s) => ({ ...s, fetched: true, docs }));
      });
    }
  }, [user, store.id, store.secid]);

  return (
    <ManageTAContext.Provider value={store}>
      {children}
    </ManageTAContext.Provider>
  );
};

export const connectManageTA = (Comp) => (props) =>
  (
    <ManageTAProvider {...props}>
      <Comp {...props} />
    </ManageTAProvider>
  );

export const injectManageTA = (Comp) => (props) => {
  const store = useContext(ManageTAContext);
  return <Comp {...props} store={store} />;
};
