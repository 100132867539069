import { faDownload } from "@fortawesome/pro-duotone-svg-icons";
import { DuotoneButton } from "@knowgistics/core";
import { CreateCSV } from "Components/CreateCSV";
import { injectGrading } from "./ctx";

export const GradingDownload = injectGrading(
  ({
    store: {
      t,
      rows,
      states: [state],
    },
    ...props
  }) => {
    const handleDownload = () => {
      const content = [["ID", "Name", "Total", "Grade"]].concat(
        rows()?.map((row) => {
          const { studentID, studentName, total, grade } = row;
          return [studentID, studentName, total, grade];
        })
      );
      CreateCSV(content, `grading-${Date.now()}.csv`);
    };

    return state?.fetched ? (
      <>
        <DuotoneButton icon={faDownload} onClick={handleDownload}>
          {t("Download")}
        </DuotoneButton>
      </>
    ) : null;
  }
);
