import { Container, ContentHeader, KGContext } from "@knowgistics/core";
import {
  Box,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useCallback, useContext } from "react";
import { CourseEditContext } from "../CourseEdit/context";
import { categories } from "Controller/variables";
import debounce from "lodash.debounce";

const update = debounce((control, key, value, addAlert) => {
  control.update({ [key]: value });
  addAlert({ label: "Update Success." });
}, 1000);

export const PageCourseDesc = (props) => {
  const { addAlert, t } = useContext(KGContext);
  const { control, data, setData } = useContext(CourseEditContext);

  const debouncedSave = useCallback(update, []);
  const genProps = (key) => ({
    fullWidth: true,
    variant: "outlined",
    value: data?.[key] || "",
    onChange: async ({ target }) => {
      const value = target.value;
      setData((s) => ({ ...s, [key]: value }));
      debouncedSave(control, key, value, addAlert);
    },
    style: {
      marginBottom: 16,
    },
  });

  return (
    <Box py={6}>
      <Container maxWidth="sm">
        <ContentHeader
          label={t("Detail")}
          breadcrumbs={[
            { label: t("Home"), to: "/" },
            { label: t("Courses.MyCourse"), to: "/" },
            { label: t("Detail") },
          ]}
        />
        <TextField label={t("Teacher")} {...genProps("teacher")} />
        <TextField
          label={t("Detail")}
          multiline
          rows={5}
          {...genProps("desc")}
        />
        <FormControl fullWidth variant="outlined">
          <InputLabel id="subject-label">{t("Subject")}</InputLabel>
          <Select
            labelId="subject-label"
            labelWidth={64}
            {...genProps("category")}
            value={
              data?.category && categories.includes(data.category)
                ? data.category
                : ""
            }
          >
            <MenuItem value="" disabled>-- {t("Subject")} --</MenuItem>
            {categories.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Container>
    </Box>
  );
};
