import {
  faFileAlt,
  faListOl,
  faPlus,
  faQuestion,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getTypeIcon = (type, props = {}) => {
  let icon = faQuestion;

  if (type.includes("question")) {
    icon = faListOl;
  } else if (type.includes("subjective")) {
    icon = faFileAlt;
  } else if (type.includes("custom-score")) {
    icon = faPlus;
  }

  return <FontAwesomeIcon icon={icon} {...props} />;
};
