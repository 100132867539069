import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import { LessonController } from "Controller/lessonController";
import { KGContext } from "@knowgistics/core";

export const LessonListsContext = createContext({});

const LessonListsProvider = ({ children, ...props }) => {
  const { user } = useContext(KGContext);
  const [state, setState] = useState({
    fetched: false,
    docs: [],
  });
  const [controller, setController] = useState(null);

  const store = {
    ...props,
    ...props.match.params,
    controller,
    state: [state, setState],
  };

  useEffect(() => {
    document.title = `Course Manager | ${process.env.REACT_APP_SITE_NAME}`;
    if (user) {
      const controller = new LessonController(user, store.id);
      setController(controller);
      return controller.watch((docs, courses) => {
        setState((s) => ({ ...s, docs, courses, fetched: true }));
      });
    }
  }, [user, store.id]);

  return (
    <LessonListsContext.Provider value={store}>
      {children}
    </LessonListsContext.Provider>
  );
};

export const connectLessonLists = (Comp) => (props) =>
  (
    <LessonListsProvider {...props}>
      <Comp {...props} />
    </LessonListsProvider>
  );
