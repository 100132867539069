import { firebase } from "Controller/firebase";

export class SubjectiveController {
  constructor(user, id, sjvid, secid) {
    this.user = user;
    this.id = id;
    this.sjvid = sjvid;
    this.secid = secid;
  }
  timestamp = () => firebase.firestore.FieldValue.serverTimestamp();

  watch = (callback) => {
    const unwatchCourse = firebase
      .firestore()
      .collection("courses")
      .doc(this.sjvid)
      .onSnapshot(async (snap) => {
        const course = { ...snap.data(), id: this.sjvid };
        const snapSections = (
          await firebase
            .firestore()
            .collection("sections")
            .where("parent", "==", course.parent)
            .get()
        ).docs.map(async (snapSection) => {
          const doc = snapSection.data();
          const users = (doc.students || []).map(async (stdId) => {
            const user = (
              await firebase
                .firestore()
                .collection("students")
                .where("studentID", "==", stdId)
                .get()
            ).docs
              .map((d) => ({ ...d.data(), id: d.id }))
              .find((d) => d.studentID === stdId);
            return user;
          });
          return {
            ...doc,
            id: snapSection.id,
            users: (await Promise.all(users)).map(user => ({
              ...user,
              status: (()=>{
                if(course?.[user?.studentID]?.status === "complete"){
                  return 'complete'
                } else if(course?.[user?.studentID]?.teacher){
                  return 'wait for edit';
                } else if(course?.[user?.studentID]?.student){
                  return 'waiting for review'
                } else {
                  return 'has not receive'
                }
              })(),
            })),
          };
        });
        callback({
          course,
          sections: await Promise.all(snapSections),
        });
      });
    return () => {
      unwatchCourse();
    };
  };

  fileDownload = async (id) => {
    const getfile = await firebase
      .firestore()
      .collection("documents")
      .doc(id)
      .get();
    return getfile.data();
  };
}
