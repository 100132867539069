import { faEdit, faUsersClass } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  ContentHeader,
  DateToStr,
  KGContext,
  MainContainer,
  TableDocs,
} from "@knowgistics/core";
import { Box, Container, Typography } from "@material-ui/core";
import { SectionController } from "Controller/sectionController";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const PageManageSection = () => {
  const { user, t } = useContext(KGContext);
  const [state, setState] = useState({
    fetched: false,
    docs: [],
  });
  const [, setController] = useState(null);
  const config = {
    checkbox: true,
    fetched: state.fetched,
    search: ["title"],
    docs: state.docs,
    rowId: "id",
    defaultSort: "title",
    defaultOrder: "asc",
    columns: [
      {
        width: 4,
        padding: "checkbox",
        value: (row) => <FontAwesomeIcon icon={faUsersClass} color="inherit" />,
        id: "icon",
      },
      { label: t("Title"), id: "title", width: 60, sortable: true },
      {
        label: t("Date"),
        value: (row) => (
          <Typography noWrap variant="body2" color="textPrimary">
            {DateToStr(row, "YYYY-MM-DD HH:mm")}
          </Typography>
        ),
        width: 25,
        align: "center",
        sortable: true,
        id: "date",
      },
      {
        id: "action",
        value: (row) => {
          return (
            <>
              <ActionIcon
                component={Link}
                to={`/ta/section/${row.parent}/${row.id}/`}
                icon={faEdit}
                color="primary"
              />
            </>
          );
        },
        width: 10,
        padding: "none",
      },
    ],
  };

  useEffect(() => {
    document.title = `Course Manager | ${process.env.REACT_APP_SITE_NAME}`;
    if (user) {
      const controller = new SectionController(user);
      setController(controller);
      return controller.watchstaff((docs) => {
        setState((s) => ({ ...s, docs, fetched: true }));
      });
    }
  }, [user]);

  return (
    <MainContainer dense signInOnly>
      <Box py={6}>
        <Container maxWidth="sm">
          <ContentHeader
            label={t("Section")}
            breadcrumbs={[
              {
                label: "Home",
                to: "/edit/iMH4y50Psp62PbGivj2D/section/GAbs1wMtNVL9KTGkcc8q/student",
              },
            ]}
          />
          <TableDocs {...config} />
        </Container>
      </Box>
    </MainContainer>
  );
};
