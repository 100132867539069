import { KGContext } from "@knowgistics/core";
import { firebase } from "Controller/firebase";
import { createContext, useEffect, useState, useContext } from "react";

class IntroductionController {
  constructor({ user, id }) {
    this.user = user;
    this.id = id;
  }
  watch = (callback) => {
    return firebase
      .firestore()
      .collection("courses")
      .doc(this.id)
      .onSnapshot((snap) => {
        callback({ ...snap.data() });
      });
  };
  update = async (data) => {
    await firebase
      .firestore()
      .collection("courses")
      .doc(this.id)
      .update({
        contents: data.contents,
        datemodified: firebase.firestore.FieldValue.serverTimestamp(),
      });
  };
}

export const IntroductionEditContext = createContext({});

const IntroductionEditProvider = ({ children, ...props }) => {
  const { user } = useContext(KGContext);
  const [control, setControl] = useState(null);

  const [state, setState] = useState({
    fetched: false,
  });
  const [data, setData] = useState({});

  const store = {
    ...props,
    ...props.match.params,
    control,
    data: [data, setData],
    state: [state, setState],
  };

  useEffect(() => {
    document.title = `Edit — Introduction | ${process.env.REACT_APP_SITE_NAME}`;

    if (user) {
      const control = new IntroductionController({
        user,
        id: store.id,
      });
      setControl(control);
      return control.watch((data) => {
        setState((s) => ({ ...s, fetched: true }));
        setData(data);
      });
    }
  }, [user, store.id]);
  return (
    <IntroductionEditContext.Provider value={store}>
      {children}
    </IntroductionEditContext.Provider>
  );
};

export const connectIntroductionEdit = (Comp) => (props) =>
  (
    <IntroductionEditProvider {...props}>
      <Comp {...props} />
    </IntroductionEditProvider>
  );
