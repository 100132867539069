import { BackLink, ListItemLink } from "@knowgistics/core";
import { List, ListItemText } from "@material-ui/core";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { SubjectiveContext } from "./index.context";

export const Sidebar = (props) => {
  const { id, secid, control, ...store } = useContext(SubjectiveContext);
  const [state] = store.state;

  return (
    <>
      <BackLink
        to={(() => {
          if (store.hideSection && store.ta) {
            return `/ta/section/${id}/${secid}/subjective`;
          } else if (store.hideSection) {
            return `/edit/${id}/section/${secid}/subjective`;
          }
          return `/edit/${id}/lesson`;
        })()}
      />
      {Boolean(store.hideSection) === false && (
        <List disablePadding>
          {state.sections &&
            state.sections.map((item, index) => (
              <ListItemLink
                button
                divider
                dense
                component={Link}
                key={item.id}
                to={`/view/${id}/subjective/${state.course.id}/section/${item.id}`}
                selected={secid === item.id || (index === 0 && !secid)}
              >
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemLink>
            ))}
        </List>
      )}
    </>
  );
};
