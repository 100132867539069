import {
  Container,
  ContentHeader,
  DuotoneButton,
  KGContext,
  ActionIcon,
  DialogRemove,
  TableDocs,
  DateToStr,
} from "@knowgistics/core";
import { useContext, useEffect, useState } from "react";
import { SectionController } from "Controller/sectionController";
import { Link } from "react-router-dom";
import {
  faEdit,
  faPlus,
  faTrash,
  faUsersClass,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import "moment/locale/th";

export const PageSection = ({ history, ...props }) => {
  const { id } = props.match.params;
  const { user, addAlert, t } = useContext(KGContext);
  const [state, setState] = useState({
    fetched: false,
    docs: [],
  });
  const [controller, setController] = useState(null);
  const config = {
    checkbox: true,
    fetched: state.fetched,
    search: ["title"],
    docs: state.docs,
    rowId: "id",
    defaultSort: "title",
    defaultOrder: "asc",
    columns: [
      {
        width: 4,
        padding: "checkbox",
        value: (row) => <FontAwesomeIcon icon={faUsersClass} color="inherit" />,
        id: "icon",
      },
      { label: t("Title"), id: "title", width: 60, sortable: true },
      {
        label: t("Date"),
        value: (row) => (
          <Typography noWrap variant="body2" color="textPrimary">
            {DateToStr(row, "YYYY-MM-DD HH:mm")}
          </Typography>
        ),
        width: 25,
        align: "center",
        sortable: true,
        id: "date",
      },
      {
        id: "action",
        value: (row) => {
          return (
            <>
              <ActionIcon
                component={Link}
                to={`/edit/${id}/section/${row.id}/student`}
                icon={faEdit}
                color="primary"
              />
              <ActionIcon
                color="secondary"
                icon={faTrash}
                onClick={handleRemove(row)}
              />
            </>
          );
        },
        width: 10,
        padding: "none",
      },
    ],
  };

  const handleAdd = async (props) => {
    const result = await controller.add();
    if (result.id) {
      history.push(`/edit/${id}/section/${result.id}/student`);
    }
  };
  const handleRemove = (remove) => () => setState((s) => ({ ...s, remove }));
  const handleRemoveConfirm = async () => {
    if (state.remove) {
      await controller.remove(state.remove.id);
      setState((s) => ({ ...s, remove: null }));
    }
    addAlert({ label: "Delete Success." });
  };

  useEffect(() => {
    document.title = `Course Manager | ${process.env.REACT_APP_SITE_NAME}`;
    if (user) {
      const controller = new SectionController(user, id);
      setController(controller);
      return controller.watch((docs) => {
        setState((s) => ({ ...s, docs, fetched: true }));
      });
    }
  }, [user, id]);

  return (
    <Box py={6}>
      <Container maxWidth="sm">
        <ContentHeader
          label={t("Section")}
          breadcrumbs={[
            { label: t("Home"), to: "/" },
            { label: t("Courses.MyCourse"), to: `/` },
            { label: t("Section") },
          ]}
          actions={
            <DuotoneButton variant="outlined" icon={faPlus} onClick={handleAdd}>
              {t("Add")}
            </DuotoneButton>
          }
        />
        <TableDocs {...config} />
      </Container>
      <DialogRemove
        title="Remove Section"
        label={t("Courses.DoYouWantToRemove", {
          name: state.remove ? state.remove.title : "",
        })}
        open={Boolean(state.remove)}
        onClose={handleRemove(null)}
        onConfirm={handleRemoveConfirm}
      />
    </Box>
  );
};
