import 'index.d'
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MainProvider } from "./context";
import { createTheme, ThemeProvider } from "@mui/material";
import { defaultTheme } from "./default.theme";
import { PopupProvider } from "Components/react-popup";

const theme = createTheme(defaultTheme);

ReactDOM.render(
  <MainProvider>
    <PopupProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </PopupProvider>
  </MainProvider>,
  document.getElementById("root")
);

reportWebVitals();
