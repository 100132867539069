import { useContext } from "react";
import {
  DuotoneButton,
  Container,
  ContentHeader,
  MainContainer,
  ActionIcon,
  DialogRemove,
  KGContext,
  parseParagraph,
} from "@knowgistics/core";
import { Box, Link as MLink } from "@material-ui/core";
import { Sidebar } from "./Sidebar";
import { connectQuestionBankEdit } from "./context";
import { QuestionBankEditContext } from "./context";
import { faEdit, faTrash, faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { Link } from "react-router-dom";
import { ViewQuiz } from "./view";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";

const types = {
  multiple: "Question.MultipleChoice",
  matching: "Question.Matching",
  truefalse: "Question.TrueFalse",
  sorting: "Question.Sorting",
};

export const QuestionBankEdit = connectQuestionBankEdit((props) => {
  const { t } = useContext(KGContext);
  const { controller, id, parentID, handle, ...store } = useContext(
    QuestionBankEditContext
  );
  const [data] = store.data;
  const [state, setState] = store.state;

  const handleRemoveSet = (remove) => () => setState((s) => ({ ...s, remove }));
  const handleRemoveConfirm = async () => await handle.remove();
  const handleView = (show) => () => setState((s) => ({ ...s, show }));

  return (
    <MainContainer dense signInOnly sidebar={<Sidebar />}>
      <Box py={6}>
        <Container maxWidth="sm">
          <ContentHeader
            label={data.title}
            breadcrumbs={[
              { label: t("Home"), to: "/" },
              { label: t("Courses.MyCourse"), to: "/" },
              {
                label: t("Courses.QuestionBank"),
                to: `/edit/${parentID}/question`,
              },
              { label: t("Bank") },
            ]}
            actions={
              <DuotoneButton
                icon={faPlus}
                component={Link}
                to={`/edit/${parentID}/question/${id}/`}
              >
                {t("Add")}
              </DuotoneButton>
            }
          />
          <DataGrid
            loading={!state?.fetched}
            rows={state?.docs}
            columns={[
              {
                field: "title",
                headerName: t("Title"),
                renderCell: ({ row, value }) => (
                  <MLink
                    onClick={handleView(row)}
                    style={{
                      cursor: "pointer",
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value}
                  </MLink>
                ),
                valueGetter: (params) => parseParagraph(params?.row?.question),
                width: 360,
              },
              {
                field: "type",
                headerName: t("Type"),
                valueGetter: (params) => t(types?.[params?.value]),
                width: 120,
              },
              {
                field: "datemodified",
                headerName: t("Date"),
                filterable: false,
                renderCell: ({ value }) =>
                  moment(value).format("YYYY/MM/DD HH:mm"),
                valueGetter: (params) => params?.value?.toMillis(),
                width: 140,
              },
              {
                field: "action",
                headerName: " ",
                renderCell: ({ row: doc }) => {
                  return (
                    <>
                      <ActionIcon
                        icon={faEdit}
                        color="primary"
                        component={Link}
                        to={`/edit/${parentID}/question/${id}/${doc.id}`}
                      />
                      <ActionIcon
                        icon={faTrash}
                        color="secondary"
                        onClick={handleRemoveSet(doc)}
                      />
                    </>
                  );
                },
                width: 80,
                align: "center",
                filterable: false,
                sortable: false,
              },
            ]}
            autoHeight
            disableSelectionOnClick
          />
        </Container>
        <DialogRemove
          title="Remove Question"
          label={state.remove ? <>Do you want to remove question?</> : null}
          open={Boolean(state.remove)}
          onConfirm={handleRemoveConfirm}
          onClose={handleRemoveSet(null)}
        />
      </Box>
      <ViewQuiz />
    </MainContainer>
  );
});
