import { faRedo, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import {
  ActionIcon,
  DateToStr,
  DialogRemove,
  DuotoneButton,
  KGContext,
  KuiActionIcon,
} from "@knowgistics/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { CourseController } from "Controller/courseController";
import { useContext, useEffect, useState } from "react";

export const QuestionBankBin = ({ courseId, ...props }) => {
  const { user, t } = useContext(KGContext);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    loading: true,
    docs: [],
    remove: null,
  });
  const [control, setControl] = useState();

  const handleOpen = (o) => () => setOpen(o);
  const handleRestore = (id) => async () => {
    await control.questionbank.restoreBin(id);
    setState((s) => {
      const docs = s?.docs?.filter((doc) => doc?.id !== id);
      return { ...s, docs };
    });
  };
  const handleRemove = (remove) => () => setState((s) => ({ ...s, remove }));
  const handleRemoveConfirm = async () => {
    await control.questionbank.removeBin(state?.remove?.id);
    setState((s) => {
      const docs = s.docs.filter((doc) => doc?.id !== s?.remove?.id);
      return { ...s, docs, remove: null };
    });
  };

  useEffect(() => {
    if (user && courseId && open) {
      const control = new CourseController(user);
      setControl(control);
      control.questionbank
        .getBin(courseId)
        .then((docs) => setState((s) => ({ ...s, loading: false, docs })));
      return () => setState((s) => ({ ...s, loading: true, docs: [] }));
    }
  }, [user, courseId, open]);

  return (
    <>
      <DuotoneButton icon={faTrash} onClick={handleOpen(true)}>
        {t("Trash")}
      </DuotoneButton>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleOpen(false)}
        disableEnforceFocus
      >
        <DialogTitle>{t("Trash")}</DialogTitle>
        <DialogContent>
          <DataGrid
            loading={state?.loading}
            rows={state?.docs}
            columns={[
              { field: "title", headerName: "Title", width: 240 },
              {
                field: "datemodified",
                headerName: "Date",
                filterable: false,
                valueGetter: (params) => params?.value?.toMillis(),
                renderCell: (params) => DateToStr(params?.row),
                width: 240,
              },
              {
                field: "action",
                headerName: " ",
                renderCell: (params) => (
                  <>
                    <ActionIcon
                      color="primary"
                      icon={faRedo}
                      onClick={handleRestore(params?.id)}
                    />
                    <KuiActionIcon
                      tx="remove"
                      onClick={handleRemove(params?.row)}
                    />
                  </>
                ),
                sortable: false,
                filterable: false,
                align: "center",
              },
            ]}
            sortModel={[{ sort: "asc", field: "title" }]}
            autoHeight
            disableSelectionOnClick
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen(false)}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>
      <DialogRemove
        title={t("Remove")}
        label={`Do you want to remove "${state?.remove?.title}" forever?`}
        open={Boolean(state?.remove)}
        onClose={handleRemove(null)}
        onConfirm={handleRemoveConfirm}
      />
    </>
  );
};
