import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BackLink,
  Container,
  ContentHeader,
  DialogPre,
  KGContext,
  MainContainer,
  SaveButton,
  QuizDisplay,
  QuizEditor,
} from "@knowgistics/core";
import { List, ListItem, Button } from "@material-ui/core";
import { faEye, faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Context = createContext({});
const Provider = ({ children, ...props }) => {
  const { user, addAlert, firebase, t } = useContext(KGContext);
  const [data, setData] = useState({});
  const isComplete = () => {
    if (data.type === "multiple") {
      return Boolean(data.question && data.options.length >= 2 && data.answer);
    } else if (data.type === "truefalse") {
      return Boolean(data.question);
    } else if (data.type === "matching") {
      return Boolean(
        data.question &&
          data.answers
            .map((ans) => Boolean((ans.data || ans.label) && ans.value))
            .filter((a) => Boolean(a)).length === data.answers.length &&
          data.answers.length >= 2
      );
    } else if (data.type === "sorting") {
      return Boolean(
        data.question &&
          data.options.filter((o) => Boolean(o.value)).length ===
            data.options.length &&
          data.options.length >= 2
      );
    }
    return false;
  };
  const store = {
    ...props,
    ...props.match.params,
    data: [data, setData],
    addAlert,
    firebase,
    isComplete,
    user,
    t,
  };
  useEffect(() => {
    if (store.quizid && firebase.apps.length) {
      firebase
        .firestore()
        .collection("questions")
        .doc(store.quizid)
        .get()
        .then((snapshot) => setData({ ...snapshot.data() }));
    } else {
      setData({});
    }
  }, [store.quizid, firebase]);
  return <Context.Provider value={store}>{children}</Context.Provider>;
};
const connect = (Comp) => (props) =>
  <Provider {...props} children={<Comp {...props} />} />;

const Preview = (props) => {
  const { id, catid, quizid, firebase, user, history, isComplete, ...store } =
    useContext(Context);
  const [data] = store.data;
  const [checked, setChecked] = useState(null);
  const handleCheck = (c) => setChecked(c);
  return (
    <DialogPre
      title="Preview"
      maxWidth="sm"
      button={
        <List disablePadding>
          <ListItem divider>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              startIcon={<FontAwesomeIcon icon={faEye} />}
              disabled={!isComplete()}
            >
              Preview
            </Button>
          </ListItem>
        </List>
      }
    >
      {isComplete && (
        <QuizDisplay
          value={data}
          checked={checked}
          onCheckChange={handleCheck}
        />
      )}
    </DialogPre>
  );
};
const AddNewQuestion = (props) => {
  const { id, catid, quizid } = useContext(Context);
  return quizid ? (
    <List disablePadding>
      <ListItem divider>
        <Button
          fullWidth
          variant="outlined"
          size="large"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          component={Link}
          to={`/edit/${id}/question/${catid}`}
        >
          Add Question
        </Button>
      </ListItem>
    </List>
  ) : null;
};

const Sidebar = (props) => {
  const {
    id,
    catid,
    quizid,
    firebase,
    user,
    history,
    isComplete,
    addAlert,
    ...store
  } = useContext(Context);
  const [data] = store.data;

  const handleSave = async () => {
    const newData = JSON.parse(JSON.stringify(data));
    const ref = firebase
      .firestore()
      .collection("questions");
    if (quizid) {
      await ref.doc(quizid).update({
        ...newData,
        datemodified: firebase.firestore.FieldValue.serverTimestamp(),
      });
      addAlert({ label: "Update success" });
    } else {
      const result = await ref.add({
        ...newData,
        datecreate: firebase.firestore.FieldValue.serverTimestamp(),
        datemodified: firebase.firestore.FieldValue.serverTimestamp(),
        user: user.uid,
        parent: catid,
      });
      if (result && result.id) {
        history.push(`/edit/${id}/question/${catid}/${result.id}`);
        addAlert({ label: "Save success" });
      }
    }
  };

  return (
    <Fragment>
      <BackLink to={`/question/edit/${catid}/${id}`} />
      <SaveButton onSave={handleSave} disabled={!isComplete()} />
      <Preview />
      <AddNewQuestion />
    </Fragment>
  );
};

export const PageQuestionEdit = connect((props) => {
  const { id, catid, t, ...store } = useContext(Context);
  const [data, setData] = store.data;

  return (
    <MainContainer signInOnly sidebar={<Sidebar />}>
      <Container maxWidth="sm">
        <ContentHeader
          label={t("Courses.Question")}
          breadcrumbs={[
            { label: t("Home"), to: "/" },
            { label: t("Courses.MyCourse"), to: `/` },
            { label: t("Courses.QuestionBank"), to: `/edit/${catid}/question` },
            { label: t("Bank"), to: `/question/edit/${catid}/${id}` },
            { label: t("Courses.Question") },
          ]}
        />
        <QuizEditor data={[data, setData]} />
      </Container>
    </MainContainer>
  );
});
