import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { firebase } from "Controller/firebase";
import { CourseDocument } from "Controller/courseController.t";

export class CourseControl {
  user: firebase.User;
  id: string;

  constructor({ user, id }: { user: firebase.User; id: string }) {
    this.user = user;
    this.id = id;
  }

  watch = (callback: (doc: CourseDocument) => void) => {
    return firebase
      .firestore()
      .collection("courses")
      .doc(this.id)
      .onSnapshot((snap) => {
        callback({ ...snap.data(), id: this.id } as CourseDocument);
      });
  };
  update = async (data: Record<string, any>) => {
    await firebase
      .firestore()
      .collection("courses")
      .doc(this.id)
      .update({
        ...data,
        datemodified: firebase.firestore.FieldValue.serverTimestamp(),
      });
  };
}

export type CourseEditState = {
  fetched: boolean;
};

export type CourseEditContextTypes = {
  id: string;
  tab?: string;
  control?: CourseControl;
  data?: CourseDocument;
  setData: Dispatch<SetStateAction<CourseDocument | undefined>>;
  state: CourseEditState;
  setState: Dispatch<SetStateAction<CourseEditState>>;
};
export const CourseEditContext = createContext<CourseEditContextTypes>({
  id: "",
  setData: () => {},
  state: { fetched: false },
  setState: () => {},
});
export const useCourseEdit = () => useContext(CourseEditContext);
