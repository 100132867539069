import { React, useContext, Fragment } from "react";
import { BackLink, SaveButton, KGContext } from "@knowgistics/core";
import { TruefalseContext } from "./Context";
import { firebase } from "Controller/firebase";
import CTLQuestion from "../components/CTLQuestion";
import { Link } from "react-router-dom";
import {
  List,
  ListSubheader,
  ListItem,
  Button,
  Divider,
} from "@material-ui/core";

export const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp;

export const Sidebar = (props) => {
  const { user, addAlert } = useContext(KGContext);
  const store = useContext(TruefalseContext);
  const [data] = store.data;
  const [state, setState] = store.state;

  const handleSave = async () => {
    if (user) {
      setState((s) => ({ ...s, fetching: true }));
      if (store.id) {
        await firebase
          .firestore()
          .collection("questions")
          .doc(store.id)
          .update({
            ...data,
            dateedit: dbTimestamp(),
          });
      } else {
        const result = await firebase
          .firestore()
          .collection("questions")
          .add({
            ...data,
            user: user.uid,
            datecreate: dbTimestamp(),
            dateedit: dbTimestamp(),
            parent: store.parent,
            type: "truefalse",
          });
        if (result.id) {
          store.history.push(
            `/question/edit/truefalse/${store.parent}/${result.id}/`
          );
        }
      }
      addAlert({ label: "Update Success." });
      setState((s) => ({ ...s, fetching: false }));
    }
  };

  return (
    <>
      <BackLink to={"/"} />
      <SaveButton
        loading={state.fetching}
        disabled={
          !Boolean(
            data.question &&
              data.question.blocks.filter((block) => block.text).length
          )
        }
        onSave={handleSave}
      />
      {store.parent && store.id && (
        <Fragment>
          <List subheader={<ListSubheader>Add New Question</ListSubheader>}>
            {Object.keys(CTLQuestion.labels).map((key) => (
              <ListItem
                component={Link}
                to={`/question/edit/${key}/${store.parent}/`}
                key={key}
              >
                <Button fullWidth size="large" variant="outlined">
                  {CTLQuestion.labels[key]}
                </Button>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Fragment>
      )}
    </>
  );
};
