import { MainContainer } from "@knowgistics/core";
import { Route, Switch } from "react-router-dom";
import { PageSecStudent } from "../Student";
import { PageSecClass } from "../Class";
import { connectSection } from "./context";
import { Sidebar } from "./Sidebar";
import { ViewGrading } from "Views/Grade";
import { PageManageTA } from "../ManageTA";
import { SectionSubjective } from "Pages/SectionSubjective";

export const PageSectionEdit = connectSection((props) => {
  return (
    <MainContainer dense signInOnly sidebar={<Sidebar back={props.back} />}>
      <Switch>
        <Route
          path={"/edit/:id/section/:secid/subjective"}
          component={SectionSubjective}
        />
        <Route
          path={"/ta/section/:id/:secid/subjective"}
          component={(props) => <SectionSubjective {...props} ta />}
        />
        <Route
          path="/edit/:id/section/:secid/grading"
          component={ViewGrading}
        />
        <Route path="/edit/:id/section/:secid/class" component={PageSecClass} />
        <Route
          path="/edit/:id/section/:secid/student"
          component={PageSecStudent}
        />
        <Route
          path="/edit/:id/section/:secid/manageTA"
          component={PageManageTA}
        />
        <Route path="/ta/section/:id/:secid/grading" component={ViewGrading} />
        <Route path="/ta/section/:id/:secid/class" component={PageSecClass} />
        <Route path="/ta/section/:id/:secid/" component={PageSecStudent} />
      </Switch>
    </MainContainer>
  );
});
