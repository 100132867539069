export class MainCtl {
  /**
   * @param options
   * @param {boolean} options.local
   */
  static baseUrl = (options = {local:false}) => options.local ? "http://localhost:8080" : "https://nest.phra.in";
  static get = async (user, input, method, body) => {
    const token = await user.getIdToken();
    const result = await fetch(input, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body,
    }).then(res => res.json())
    return result;
  }
}