import { KGContext } from "@knowgistics/core";
import { CourseController } from "Controller/courseController";
import { createContext, useContext, useEffect, useState } from "react";

const CloneCtx = createContext({});

const ClonePvd = ({ children, ...props }) => {
  const { user } = useContext(KGContext);
  const [state, setState] = useState({
    loading: true,
    docs: [],
  });
  const [control, setControl] = useState();

  const store = {
    ...props,
    states: [state, setState],
    control,
  };

  useEffect(() => {
    if (user) {
      const control = new CourseController(user);
      setControl(control);
      control.course
        .get()
        .then((docs) => setState((s) => ({ ...s, loading: false, docs })));
      return () => setState((s) => ({ ...s, docs: [], loading: true }));
    }
  }, [user]);

  return <CloneCtx.Provider value={store}>{children}</CloneCtx.Provider>;
};

export const connectClone = (Comp) => (props) =>
  (
    <ClonePvd {...props}>
      <Comp {...props} />
    </ClonePvd>
  );

export const injectClone = (Comp) => (props) => {
  const store = useContext(CloneCtx);
  return <Comp {...props} store={store} />;
};
