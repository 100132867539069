import { KGContext } from "@knowgistics/core";
import { firebase } from 'Controller/firebase'
import { createContext, useEffect, useState, useContext } from "react";

export const ClassSectionContext = createContext({});

class SectionController {
  constructor({ user, secid, classid }) {
    this.user = user;
    this.secid = secid;
    this.classid = classid;
  }

  watch = callback => {
    //list student in Section
    const unwatchSection = firebase.firestore()
      .collection("sections")
      .doc(this.secid)
      .onSnapshot(async (snap) => {
        const data = snap.data();
        const users = (data.students || []).map(async (id) => {
          const user = await firebase.firestore()
            .collection("students")
            .where('studentID', '==', id)
            .get().then(std => {
              const list = std.docs.map((doc) => ({
                ...doc.data(), id
              }))
              return ((list)[0])
            });
          return (user)
        });
        callback("section")({ ...data, users: await Promise.all(users) });
      });
    //class Detail
    const unwatchData = firebase.firestore()
      .collection("sections")
      .doc(this.classid)
      .onSnapshot((snap) => {
        const data = snap.data();
        callback("data")(data);
      });
    //class checklist student
    const unwatchChecked = firebase.firestore()
      .collection("sections")
      .doc(this.classid)
      .onSnapshot(async (snap) => {
        const data = snap.data();
        const student = Object.keys(data).filter(key => key.includes("chk-") && data[key])
          .map(key => ({ time: data[key], studentID: key }))
        const checked = student.map(async (doc) => {
          const studentID = doc.studentID.replace("chk-", "");
          const userData = (await firebase.firestore().collection("students").doc(studentID).get()).data()

          return { ...doc, userData, id: studentID };
        });
        callback("checked")(await Promise.all(checked));
      });
    return () => {
      unwatchData();
      unwatchChecked();
      unwatchSection();
    };
  };

  checkoutStudent = (studentid) => {
    firebase.firestore()
      .collection("sections")
      .doc(this.classid)
      .update(
        { [`chk-${studentid}`]: null }
      );
  };

  checkInStudent = (studentid) => {
    firebase.firestore()
      .collection("sections")
      .doc(this.classid)
      .update(
        { [`chk-${studentid}`]: firebase.firestore.FieldValue.serverTimestamp() }
      );
  }
}

const ClassSectionProvider = ({ children, ...props }) => {
  const { user } = useContext(KGContext);
  const [control, setControl] = useState(null);
  const [state, setState] = useState({
    fetched: false,
    data: null,
    checked: [],
    section: null,
    remove: null,
    stdchecked: null,
  });
  const store = {
    ...props,
    ...props.match.params,
    control,
    state: [state, setState],
  };
  useEffect(() => {
    document.title = `CheckIn — Section | ${process.env.REACT_APP_SITE_NAME}`;

    if (user) {
      const control = new SectionController({ user, secid: store.secid, classid: store.classid });
      setControl(control);
      return control.watch((key) => (value) => {
        setState((s) => ({ ...s, [key]: value, fetched: true }));
      });
    }
  }, [user, store.secid, store.classid]);

  return (
    <ClassSectionContext.Provider value={store}>
      {children}
    </ClassSectionContext.Provider>
  );
};

export const connectClassSection = (Comp) => (props) => (
  <ClassSectionProvider {...props}>
    <Comp {...props} />
  </ClassSectionProvider>
);
