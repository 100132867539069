import { Box } from "@material-ui/core";
import { Container, ContentHeader, KGContext } from "@knowgistics/core";
import { connectGrading, injectGrading } from "./ctx";
import { GradingSetting } from "./setting";
import { GradeSummary } from "./grade.sum";
import { GradingDownload } from "./download";
import { useContext, useEffect, useState } from "react";
import { GradingCtl } from "./ctl";
import { DataGrid } from "@mui/x-data-grid";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const ViewGrading = connectGrading(
  injectGrading(({ store: { id, secid, rows, t }, ...props }) => {
    const { user } = useContext(KGContext);
    const [state, setState] = useState({
      loading: true,
    });
    const [focus, setFocus] = useState("");

    useEffect(() => {
      if (user && id && secid) {
        return GradingCtl.watch(id, secid, (data) => {
          setState((s) => ({
            ...s,
            ...data,
            loading: Boolean(data.count < 3),
          }));
        });
      }
    }, [user, id, secid]);

    return (
      <Box py={6}>
        <Container maxWidth="xl">
          <ContentHeader
            label={t("Grading")}
            breadcrumbs={[
              { label: t("Home"), to: `/` },
              { label: t("Course"), to: `/edit/${id}` },
              { label: t("Section"), to: `/edit/${id}/section/` },
            ]}
            actions={
              <>
                <GradingSetting />
                &nbsp;
                <GradingDownload />
              </>
            }
          />
          {/* <GradingDataGrid /> */}
          <FormControl size="small" sx={{ width: 240, mb: 2 }}>
            <InputLabel>Focus</InputLabel>
            <Select
              label="Focus"
              value={focus}
              onChange={({ target: { value } }) => setFocus(value)}
            >
              <MenuItem value="">-- Focus Field --</MenuItem>
              {state?.fields?.map((field) => (
                <MenuItem value={field.id} key={field.id}>
                  {field.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ height: 480 }}>
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "asc" }],
                },
              }}
              getRowId={(row) => row.id}
              loading={state.loading}
              rows={state?.students || []}
              columns={[
                {
                  field: "id",
                  headerName: "ID",
                  valueGetter: ({ row }) => row.studentID,
                  width: 120,
                },
                {
                  field: "name",
                  headerName: "Name",
                  valueGetter: ({ row }) => row.studentName,
                  width: 280,
                },
                {
                  field: "sum",
                  headerName: "Score",
                  width: 120,
                  align: "center",
                  valueGetter: ({ row }) =>
                    GradingCtl.sumScore(state.fields, state.scores, row.id),
                },
                {
                  field: "grade",
                  headerName: "Grade",
                  align: "center",
                  valueGetter: ({ row }) =>
                    GradingCtl.sumScore(state.fields, state.scores, row.id),
                  renderCell: ({ value }) => GradingCtl.getGrade(value),
                  width: 120,
                },
                ...(state.fields || []).map((field) => ({
                  field: field.id,
                  headerName: field.title,
                  align: "right",
                  valueGetter: ({ row }) => {
                    switch (GradingCtl.parseType(field.type)) {
                      case `question`:
                        return GradingCtl.getScore(
                          state.scores,
                          field.id,
                          row.id
                        );
                      case `custom-score`:
                        return field?.[`score-${row.studentID}`] || 0;
                      default:
                        return 0;
                    }
                  },
                  editable: ["custom-score","subjective"].includes(GradingCtl.parseType(field.type)),
                  width: focus === field.id ? 360 : 120,
                })),
              ]}
              columnVisibilityModel={{
                id: true,
                name: true,
                score: true,
                grade: true,
                ...{},
              }}
              disableSelectionOnClick
              onCellEditCommit={({ id, field, value }) =>
                GradingCtl.updateScore(field, `score-${id}`, value)
              }
            />
          </Box>
        </Container>
        <Box py={3} />
        <Container maxWidth="sm">
          <GradeSummary rows={rows()} />
        </Container>
      </Box>
    );
  })
);

/**
        `${this.prefix}subjective`,
        `${this.prefix}subjective-private`,
        `${this.prefix}custom-score`,
        `${this.prefix}custom-score-private`,
 */
