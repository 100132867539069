import { getStudentsInfo, SkeletonController } from "@knowgistics/core";
import { firebase } from "Controller/firebase";

/**
 * @typedef {Object} Score
 * @property {string} id
 * @property {string} title
 * @property {string} courseId
 * @property {string} questionId
 * @property {string} questionparentId
 * @property {string} studentId
 * @property {string} type
 * @property {string} userId
 * @property {unknown} date
 * @property {number} amount
 * @property {number} point
 * @property {number} score
 */

/**
 * @typedef {Object} Field
 * @property {string} id
 * @property {string} title
 * @property {string} fixnumber
 * @property {string} parent
 * @property {string} prefix
 * @property {string} questionid
 * @property {string} weight
 * @property {unknown} datecreate
 * @property {unknown} datemodified
 * @property {number} amount
 * @property {string} point
 * @property {number} sort
 * @property {string} type
 * @property {string} user
 */

/**
 * @typedef {Object} UserInfo
 * @property {string} id
 * @property {string} firstname
 * @property {string} lastname
 * @property {string} middlename
 * @property {string} prefix
 * @property {string} studentID
 * @property {string} studentName
 * @property {string} title
 * @property {string} uid
 */

/**
 * @typedef {Object} GradeData
 * @property {Array<Score>} scores
 * @property {Array<Field>} fields
 */

/**
 * @callback callbackWatch
 * @param {GradeData} data
 */

export class GradingCtl {
  static prefix = process.env.REACT_APP_PREFIX || "";

  /**
   * @param {string} courseId
   * @param {string} sectionId
   * @param {callbackWatch} callback
   * @returns
   */
  static watch(courseId, sectionId, callback) {
    let count = 0;
    const uwSection = firebase
      .firestore()
      .collection("sections")
      .doc(sectionId)
      .onSnapshot(async (snap) => {
        const section = snap.data();
        const studentsInfo = await getStudentsInfo(
          section?.students ?? [],
          this.prefix
        );
        const students = section?.students
          ?.map(
            (id) =>
              studentsInfo[id] && {
                ...studentsInfo[id],
                id: id,
              }
          )
          .filter((u) => u);
        count += 1;
        callback({ section, students, count });
      });

    const uwScores = firebase
      .firestore()
      .collection("scores")
      .where("courseId", "==", courseId)
      .onSnapshot((snap) => {
        const scores = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        count += 1;
        callback({ scores, count });
      });

    const uwFields = firebase
      .firestore()
      .collection("courses")
      .where("parent", "==", courseId)
      .where("type", "in", [
        `${this.prefix}question`,
        `${this.prefix}question-private`,
        `${this.prefix}subjective`,
        `${this.prefix}subjective-private`,
        `${this.prefix}custom-score`,
        `${this.prefix}custom-score-private`,
      ])
      .onSnapshot((snap) => {
        const fields = snap.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .sort((a, b) => (a?.sort ?? 9999) - (b?.sort ?? 9999));
        count += 1;
        callback({ fields, count });
      });

    return () => {
      uwSection();
      uwScores();
      uwFields();
    };
  }

  /**
   *
   * @param {Array<Field>} fields
   * @param {Array<Score>} scores
   * @param {string} userId
   * @returns
   */
  static sumScore(fields, scores, userId) {
    if (Array.isArray(fields) && Array.isArray(scores)) {
      const newScores = fields.map((field) => {
        switch (this.parseType(field.type)) {
          case "custom-score":
          case "subjective":
            return {
              id: field.id,
              score:
                field?.[`score-${userId}`] / (parseInt(field.weight) || 1) || 0,
            };
          default:
            return {
              id: field.id,
              score: this.getScore(scores, field.id, userId),
            };
        }
      });
      return newScores.reduce((total, field) => total + field.score, 0);
    }
    return 0;
  }

  /**
   *
   * @param {Array<Score>} scores
   * @param {string} quizId
   * @param {string} userId
   * @returns {number}
   */
  static getScore(scores, quizId, userId) {
    if (Array.isArray(scores)) {
      const score = scores
        .sort((a, b) => b.score - a.score)
        .find((s) => {
          return s.studentId === userId && s.questionId === quizId;
        });
      if (score) {
        return (
          Math.round(
            (score.score / parseFloat(score.amount)) *
              parseInt(score.point) *
              10000
          ) / 10000
        );
      }
    }
    return 0;
  }

  /**
   *
   * @param {string} type
   * @return {string}
   */
  static parseType(type) {
    return type
      .replace(this.prefix, "")
      .replace("-private", "")
      .replace("-trash", "");
  }

  /**
   *
   * @param {number} score
   * @returns
   */
  static getGrade(score) {
    const labels = [
      { label: "A", max: 100, min: 80 },
      { label: "B+", max: 80, min: 75 },
      { label: "B", max: 75, min: 70 },
      { label: "C+", max: 70, min: 65 },
      { label: "C", max: 65, min: 60 },
      { label: "D+", max: 60, min: 55 },
      { label: "D", max: 55, min: 50 },
      { label: "F", max: 50, min: 0 },
    ];
    for (let i = 0; i < labels.length; i++) {
      if (labels[i].max >= score && score > labels[i].min) {
        return labels[i].label;
      }
    }
    return "F";
  }

  /**
   *
   * @param {string} id
   * @param {string} field
   * @param {unknown} value
   */
  static async updateScore(id, field, value) {
    return await firebase
      .firestore()
      .collection("courses")
      .doc(id)
      .update({
        [field]: value,
      });
  }

  info;
  /**
   *
   * @param {UserInfo} user
   */
  constructor(user) {
    this.info = user;
  }
}

export class GradingController extends SkeletonController {
  constructor(firebase, user, id, secid) {
    super(firebase);

    this.user = user;
    this.id = id;
    this.secid = secid;
    this.prefix = process.env.REACT_APP_PREFIX;
  }
  #toDoc = (doc) => ({ ...doc.data(), id: doc.id });
  dfWeight = 1;
  fixed = (num) => Math.round(num * 100) / 100;
  getGrade = (score) => {
    const labels = [
      { label: "A", max: 100, min: 80 },
      { label: "B+", max: 80, min: 75 },
      { label: "B", max: 75, min: 70 },
      { label: "C+", max: 70, min: 65 },
      { label: "C", max: 65, min: 60 },
      { label: "D+", max: 60, min: 55 },
      { label: "D", max: 55, min: 50 },
      { label: "F", max: 50, min: 0 },
    ];
    for (let i = 0; i < labels.length; i++) {
      if (labels[i].max >= score && score > labels[i].min) {
        return labels[i].label;
      }
    }
    return "F";
  };

  watch = async (callback) => {
    const uwSection = this.path("sections", this.secid).onSnapshot(
      async (snap) => {
        const section = snap.data();
        const studentsInfo = await getStudentsInfo(
          section?.students ?? [],
          this.prefix
        );
        const students = section?.students
          ?.map(
            (id) =>
              studentsInfo[id] && {
                ...studentsInfo[id],
                id: id,
              }
          )
          .sort((a, b) => (a?.id).localeCompare(b?.id));
        callback({ section, students });
      }
    );

    const uwScores = this.path("scores")
      .where("courseId", "==", this.id)
      .onSnapshot((snap) => {
        const scores = snap.docs.map(this.#toDoc).reduce((sum, self) => {
          const { point, amount, score, title, studentId, questionId } = self;
          if (sum?.[`${studentId}-${questionId}`]) {
            const newScore =
              Math.round(
                (score / parseFloat(amount)) * parseFloat(point) * 10000
              ) / 10000;
            if (newScore > sum?.[`${studentId}-${questionId}`]?.score) {
              sum[`${studentId}-${questionId}`].score = newScore;
              sum[`${studentId}-${questionId}`].round++;
            }
          } else {
            sum[`${studentId}-${questionId}`] = {
              score:
                Math.round(
                  (score / parseFloat(amount)) * parseFloat(point) * 10000
                ) / 10000,
              title,
              studentId,
              questionId,
              round: 1,
            };
          }
          return sum;
        }, {});
        callback({ scores });
      });

    const uwFields = this.path("courses")
      .where("parent", "==", this.id)
      .where("type", "in", [
        `${this.prefix}question`,
        `${this.prefix}question-private`,
        `${this.prefix}subjective`,
        `${this.prefix}subjective-private`,
        `${this.prefix}custom-score`,
        `${this.prefix}custom-score-private`,
      ])
      .onSnapshot((snap) => {
        const fields = snap.docs
          .map(this.#toDoc)
          .sort((a, b) => (a?.sort ?? 9999) - (b?.sort ?? 9999));
        callback({ fields });
      });

    return () => {
      uwSection();
      uwScores();
      uwFields();
    };
  };

  header = {
    add: async (data) => {
      await this.path("courses").add({
        ...data,
        parent: this.id,
        prefix: this.prefix,
        user: this?.user?.uid,
      });
    },
    edit: async ({ id, field, value }) => {
      await this.path("courses", id).update({
        [field]: value,
      });
    },
    remove: async (id) => {
      await this.path("courses", id).update({
        type: `${this.prefix}custom-field-remove`,
      });
    },
  };

  cell = {
    edit: async ({ id, field, value }) => {
      await this.path("courses", field).update({
        [`score-${id}`]: value,
      });
    },
  };
}
