import { faRedo } from "@fortawesome/pro-duotone-svg-icons";
import {
  ActionIcon,
  DialogPre,
  DialogRemove,
  KGContext,
  KuiActionIcon,
  KuiButton,
} from "@knowgistics/core";
import { DataGrid } from "@mui/x-data-grid";
import {
  CourseController,
  CourseDocument,
} from "Controller/courseController.t";
import { useContext, useEffect, useState } from "react";

export const CourseBin = () => {
  const { t, user } = useContext(KGContext);
  const [control, setControl] = useState<CourseController>();
  const [state, setState] = useState<{
    loading: boolean;
    docs: CourseDocument[];
    remove?: CourseDocument;
  }>({
    loading: true,
    docs: [],
  });

  const handleRestore = (id: string) => async () =>
    await control?.restoreBin(id);
  const handleRemove = (remove?: CourseDocument) => () =>
    setState((s) => ({ ...s, remove }));
  const handleRemoveConfirm = async () => {
    if (state.remove?.id) {
      await control?.removeBin(state.remove.id);
      handleRemove()();
    }
  };

  useEffect(() => {
    if (user) {
      const control = new CourseController(user);
      setControl(control);
      return control.watchBin((docs) =>
        setState((s) => ({ ...s, loading: false, docs }))
      );
    }
  }, [user]);

  return (
    <>
      &nbsp;
      <DialogPre
        title={t("Trash")}
        maxWidth="sm"
        button={<KuiButton tx="bin" />}
      >
        <DataGrid
          loading={state?.loading}
          rows={state?.docs}
          columns={[
            { field: "title", headerName: t("Title"), width: 240 },
            {
              field: "action",
              headerName: "",
              renderCell: ({ row }) => (
                <>
                  <ActionIcon
                    icon={faRedo}
                    color="primary"
                    onClick={handleRestore(row.id)}
                  />
                  <KuiActionIcon tx="remove" onClick={handleRemove(row)} />
                </>
              ),
              align: "center",
            },
          ]}
          autoHeight
          disableSelectionOnClick
        />
      </DialogPre>
      <DialogRemove
        title={t("Remove")}
        label={`Do you want to remove "${state?.remove?.title}" forever?`}
        open={Boolean(state?.remove)}
        onClose={handleRemove()}
        onConfirm={handleRemoveConfirm}
      />
    </>
  );
};
