import {
  Container,
  ContentHeader,
  DuotoneButton,
  KGContext,
  MainContainer,
  DialogRemove,
  KuiActionIcon,
} from "@knowgistics/core";
import { useContext, useEffect, useState } from "react";
import {
  CourseController,
  CourseDocument,
} from "Controller/courseController.t";
import { Link, RouteComponentProps } from "react-router-dom";
import { faLock, faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { Avatar, Link as MLink } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-duotone-svg-icons";
import { DataGrid } from "@mui/x-data-grid";
import { CourseBin } from "./bin";

export interface PageCourseProps extends RouteComponentProps {}

export const PageCourse = ({ history }: PageCourseProps) => {
  const { addAlert, user, t } = useContext(KGContext);
  const [state, setState] = useState<{
    fetched: boolean;
    docs: CourseDocument[];
    remove?: CourseDocument;
  }>({
    fetched: false,
    docs: [],
  });
  const [controller, setController] = useState<CourseController | null>(null);

  const handleAdd = async () => {
    const result = await controller?.add().catch((err) => {
      addAlert({ label: `${err.message}`, severity: "error" });
      return null;
    });
    if (result?.id) {
      history.push(`/edit/${result.id}`);
    }
  };
  const handleRemove = (remove?: CourseDocument) => () =>
    setState((s) => ({ ...s, remove }));
  const handleRemoveConfirm = async () => {
    if (state.remove && controller) {
      await controller.remove(state.remove.id);
      setState((s) => ({ ...s, remove: undefined }));
    }
  };

  useEffect(() => {
    document.title = `My Course | ${process.env.REACT_APP_SITE_NAME}`;
    if (user) {
      const controller = new CourseController(user);
      setController(controller);
      return controller.watch((docs) => {
        setState((s) => ({ ...s, docs, fetched: true }));
      });
    }
  }, [user]);

  return (
    <MainContainer signInOnly>
      <Container maxWidth="md">
        <ContentHeader
          label={t("Courses.MyCourse")}
          breadcrumbs={[{ label: t("Home") }, { label: t("Courses.MyCourse") }]}
          actions={
            <>
              <DuotoneButton
                variant="outlined"
                icon={faPlus}
                onClick={handleAdd}
              >
                {t("Add")}
              </DuotoneButton>
              <CourseBin />
            </>
          }
        />
        <DataGrid
          loading={!state?.fetched}
          rows={state?.docs}
          columns={[
            {
              field: "cover",
              headerName: " ",
              renderCell: ({ row }) => {
                return row.cover ? (
                  <Avatar
                    variant="square"
                    src={row.cover.thumbnail || row.cover.original}
                  />
                ) : (
                  <Avatar variant="square">
                    <FontAwesomeIcon icon={faImage} />
                  </Avatar>
                );
              },
              width: 60,
            },
            {
              field: "title",
              headerName: t("Title"),
              width: 360,
              renderCell: ({ row }) => (
                <MLink component={Link} to={`/view/${row.id}`} target="_blank">
                  {row.type.includes("private") && (
                    <FontAwesomeIcon
                      icon={faLock}
                      style={{ marginRight: "0.25em" }}
                    />
                  )}
                  {row.title}
                </MLink>
              ),
            },
            {
              field: "date",
              headerName: t("Date"),
              width: 160,
            },
            {
              field: "action",
              headerName: " ",
              renderCell: ({ row }) => {
                return (
                  <>
                    <KuiActionIcon
                      tx="edit"
                      component={Link}
                      to={`/edit/${row.id}`}
                    />
                    <KuiActionIcon tx="remove" onClick={handleRemove(row)} />
                  </>
                );
              },
              align: "center",
            },
          ]}
          autoHeight
          disableSelectionOnClick
        />
      </Container>
      <DialogRemove
        title="Remove Course"
        label={t("Courses.DoYouWantToRemove", {
          name: state.remove ? state.remove.title : "",
        })}
        open={Boolean(state.remove)}
        onClose={handleRemove()}
        onConfirm={handleRemoveConfirm}
      />
    </MainContainer>
  );
};
