import {
  MainContainer,
  Container,
  ContentHeader,
  ActionIcon,
  DuotoneButton,
  KGContext,
  DialogPre,
  DialogRemove,
} from "@knowgistics/core";
import { useContext, useState } from "react";
import { connectManageStudent, ManageStudentContext } from "./context";
import { Box, TextField } from "@material-ui/core";
import {
  faEdit,
  faFolderOpen,
  faPlus,
  faTrash,
} from "@fortawesome/pro-duotone-svg-icons";
import React from "react";
import { readExcel, DialogExcel, ButtonBrowse } from "@knowgistics/kgui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";

export const PageManageStudent = connectManageStudent((props) => {
  const [pageSize, setPageSize] = useState(10);
  const { addAlert, t } = useContext(KGContext);
  const { control, ...store } = useContext(ManageStudentContext);
  const [data] = store.data;
  const [, setState] = store.state;
  const [excel, setExcel] = React.useState(null);
  const [addNew, setAddNew] = React.useState(null);
  const [remove, setRemove] = React.useState(null);
  const handleChange = (key) => (e) =>
    setAddNew((s) => ({ ...s, [key]: e.target.value }));
  const getProps = (key) => ({
    fullWidth: true,
    variant: "outlined",
    value: (addNew && addNew[key]) || "",
    onChange: handleChange(key),
    style: {
      marginBottom: 16,
    },
  });
  const handleAddStudent = () => {
    if (addNew) {
      control.add(addNew);
      addAlert({ label: "Add Data Success." });
      setAddNew(null);
    }
  };
  const handleEditStudent = () => {
    if (addNew) {
      control.update(addNew);
      addAlert({ label: "Update Success." });
      setAddNew(null);
    }
  };
  const handleExcelChange = async (e) => {
    const files = [...e.target.files];
    if (
      files.length &&
      files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const result = await readExcel(files[0]);
      setExcel(result);
    } else {
      addAlert({ label: "FILE NOT FOUND OR INVALID TYPE", severity: "error" });
    }
  };
  const handleImportSection = async (res) => {
    await control.Importexcel(res);
    setExcel(null);
  };
  const handleRemove = (remove) => () => setRemove(remove);
  const handleRemoveConfirm = () => {
    setState((s) => ({ ...s, fetched: false }));
    control.remove(remove.id);
    setRemove(null);
    setState((s) => ({ ...s, fetched: true }));
    addAlert({ label: "Delete Success." });
  };
  // const handleDeleteSelected = (selected) => {
  //   setState((s) => ({ ...s, fetched: false }));
  //   control.selectedremove(selected);
  //   setState((s) => ({ ...s, fetched: true }));
  //   addAlert({ label: "Delete Success." });
  // };

  return (
    <MainContainer dense signInOnly>
      <Box py={6}>
        <Container maxWidth="sm">
          <ContentHeader
            label={t("Courses.ManageStudent")}
            breadcrumbs={[{ label: t("Home"), to: "/" }]}
            actions={
              <React.Fragment>
                <ButtonBrowse onChange={handleExcelChange}>
                  <DuotoneButton
                    variant="outlined"
                    startIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                  >
                    {t("Import")}
                  </DuotoneButton>
                </ButtonBrowse>
                &nbsp;
                <DialogPre
                  title="Create Student"
                  button={
                    <DuotoneButton
                      variant="outlined"
                      startIcon={<FontAwesomeIcon icon={faPlus} />}
                      onMouseUp={() => {
                        setAddNew(null);
                      }}
                    >
                      {t("Add")}
                    </DuotoneButton>
                  }
                  onConfirm={handleAddStudent}
                >
                  <TextField label="Student ID" {...getProps("studentID")} />
                  <TextField
                    label="Student Name"
                    {...getProps("studentName")}
                  />
                </DialogPre>
              </React.Fragment>
            }
          />
          <DataGrid
            checkboxSelection
            initialState={{
              sorting: { sortModel: [{ field: "studentID", sort: "asc" }] },
            }}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={setPageSize}
            rows={data.student}
            columns={[
              {
                field: "action",
                headerName: " ",
                renderCell: ({ row }) => {
                  return (
                    <>
                      <DialogPre
                        title="Edit Student"
                        button={
                          <ActionIcon
                            icon={faEdit}
                            onMouseUp={() => {
                              setAddNew(row);
                            }}
                          />
                        }
                        onConfirm={handleEditStudent}
                      >
                        <TextField
                          label="Student ID"
                          {...getProps("studentID")}
                        />
                        <TextField
                          label="Student Name"
                          {...getProps("studentName")}
                        />
                      </DialogPre>
                      <ActionIcon
                        icon={faTrash}
                        color="secondary"
                        onClick={handleRemove(row)}
                      />
                    </>
                  );
                },
                width: 64,
                align: "center",
              },
              {
                field: "studentID",
                headerName: "ID",
                width: 120,
              },
              { field: "studentName", width: 360, headerName: "Name" },
            ]}
            autoHeight
            disableSelectionOnClick
          />
          <DialogExcel
            data={excel}
            open={Boolean(excel)}
            onClose={() => setExcel(null)}
            onImport={(res) => handleImportSection(res)}
          />
          <DialogRemove
            title="Remove Student"
            label={
              remove ? (
                <>
                  Do you want to remove{" "}
                  <strong>
                    "{remove.studentID} {remove.studentName}"
                  </strong>
                  ?
                </>
              ) : null
            }
            open={Boolean(remove)}
            onClose={handleRemove(null)}
            onConfirm={handleRemoveConfirm}
          />
        </Container>
      </Box>
    </MainContainer>
  );
});
