import {
    Typography,
    withStyles,
    Box,
    Checkbox,
    Grid,
  } from "@material-ui/core";
  import clsx from 'clsx';
  
  export const Heading = withStyles((theme) => ({
    root: {
      marginBottom: theme.spacing(1),
    },
  }))((props) => <Typography variant="h5" {...props} />);
  
  export const SubHeading = withStyles((theme) => ({
      root: {
        marginBottom: theme.spacing(1),
      },
    }))((props) => <Typography variant="h5" {...props} />);

  export const Complatestep = withStyles((theme) => ({
      root: {
        marginBottom: theme.spacing(1),
        color: theme.palette.success.main,
      },
    }))((props) => <Typography variant="subtitle2" gutterBottom {...props} />);
  
    export const MatchBox = withStyles((theme) => ({
      root: {
        border: `solid 1px ${theme.palette.grey[400]}`,
        borderRadius: theme.shape.borderRadius * 2,
        "&:not(:last-child)": {
          marginBottom: theme.spacing(3),
        },
      },
    }))(Box);

  export const ImageWrapper = withStyles(theme=>({
    root: {
      position: 'relative',
    },
  }))(Grid)
  export const ImageRatio = withStyles(theme=>({
    root: {
      position: 'relative',
      cursor: 'pointer',
      border: `solid 2px ${theme.palette.grey[300]}`,
      padding: theme.spacing(0.5,1),
      width: '100%',
      boxSizing: 'border-box',
      borderRadius: 4,
    },
    selected: {
      border: `solid 2px ${theme.palette.primary.main}`,
      boxSizing: 'border-box',
    },
  }))(({ classes, selected, onSelect, ...props })=>(<Box
    className={ selected ? clsx(classes.root,classes.selected) : classes.root}
    {...props}
  />));
   export const ImageCheckbox = withStyles(theme=>({
    root: {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
    },
  }))(props=><Checkbox color="primary" {...props} />);
  