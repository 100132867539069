import { React, useContext } from "react";
import {
  Container,
  ContentHeader,
  MainContainer,
  Paragraph,
} from "@knowgistics/core";
import { Box, Divider, Select, MenuItem } from "@material-ui/core";
import { Sidebar } from "./Sidebar";
import { Heading } from "../components";
import { connectContext, TruefalseContext } from "./Context";

const QuestionTruefalseEdit = (props) => {
  const store = useContext(TruefalseContext);
  const [data, setData] = store.data;

  const handleChange = (key) => (e) =>
    e
      ? setData((d) => ({ ...d, [key]: e.target ? e.target.value : e }))
      : false;

  return (
    <MainContainer dense signInOnly sidebar={<Sidebar />}>
      <Box py={6}>
        <Container maxWidth="sm">
          <ContentHeader label="True/False" breadcrumbs={[{ label: "Home" }]} />
          <Heading>Question</Heading>
          <Box mt={2} />
          <Paragraph
            editOnly
            value={data.question}
            onChange={handleChange("question")}
          />
          <Box my={5} children={<Divider />} />
          <Heading>Answer</Heading>
          <Select
            fullWidth
            variant="outlined"
            value={data.answers}
            onChange={handleChange("answers")}
          >
            {["True", "False"].map((label) => (
              <MenuItem value={label === "True" ? true : false} key={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Container>
      </Box>
    </MainContainer>
  );
};
export default connectContext(QuestionTruefalseEdit);
