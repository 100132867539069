import React, { useState } from 'react'
import { useContext } from 'react'
import { BackLink, ListItemLink, FeatureImage } from '@knowgistics/core'
import { ContextViewCourse } from './context'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  faChalkboard,
  faFileAlt,
  faListOl,
  faQuestion,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SubjectiveDialog } from './subjective.dialog'

const useStyles = makeStyles((theme) => ({
  cover: {
    position: 'relative'
  },
  more: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    color: 'white'
  }
}))

const icons = {
  lesson: faChalkboard,
  question: faListOl,
  subjective: faFileAlt
}

export const ViewCourseSidebar = (props) => {
  const {
    back,
    baseURL,
    selected,
    data: datas,
    state: states,
    more
  } = useContext(ContextViewCourse)
  const [data] = datas
  const [state] = states
  const cs = useStyles()
  const [open, setOpen] = useState(null)

  const handleOpen = (id) => () => setOpen(id)
  const getType = (type) => `${type}`.split('-').pop()

  return (
    <React.Fragment>
      {back && <BackLink to={back} />}
      <div className={cs.cover}>
        {state.fetched ? (
          data.cover && <FeatureImage image={data.cover} />
        ) : (
          <Skeleton
            variant='rect'
            width='100%'
            style={{ paddingTop: 'calc((100% / 3) - 19px)' }}
          />
        )}
        {more && <div className={cs.more}>{more}</div>}
      </div>
      <List>
        <ListItemLink
          button
          divider
          to={baseURL}
          selected={selected === 'home'}
        >
          <ListItemText
            primary={state.fetched ? data.title : <Skeleton width='75%' />}
            secondary={state.fetched ? data.teacher : <Skeleton width='50%' />}
            primaryTypographyProps={{ variant: 'h6' }}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </ListItemLink>
        {state.fetched === false ? (
          <ListItem divider>
            <ListItemText primary={<Skeleton width='50%' />} />
          </ListItem>
        ) : Array.isArray(data.lessons) && data.lessons.length ? (
          data.lessons
            .sort((a, b) => a.sort - b.sort)
            .map((lesson, index) => {
              switch (getType(lesson.type)) {
                case 'subjective':
                  return (
                    <React.Fragment key={lesson.id}>
                      <ListItemLink
                        divider
                        button
                        dense
                        to={'#'}
                        onClick={handleOpen(lesson.id)}
                      >
                        <ListItemIcon>
                          <Box textAlign='center' style={{ width: 40 }}>
                            <FontAwesomeIcon
                              size='2x'
                              icon={faFileAlt}
                              color='inherit'
                            />
                          </Box>
                        </ListItemIcon>
                        <ListItemText
                          primary={lesson.title}
                          primaryTypographyProps={{ variant: 'body2' }}
                        />
                      </ListItemLink>
                      <SubjectiveDialog
                        open={open === lesson.id}
                        index={index}
                        onClose={handleOpen(null)}
                      />
                    </React.Fragment>
                  )
                case 'lesson':
                case 'question':
                  return (
                    <ListItemLink
                      divider
                      button
                      dense
                      key={lesson.id}
                      to={baseURL + `/${getType(lesson.type)}/${lesson.id}`}
                      target={
                        getType(lesson.type) === 'lesson' ? '_self' : '_blank'
                      }
                      selected={selected === lesson.id}
                    >
                      <ListItemIcon>
                        <Box textAlign='center' style={{ width: 40 }}>
                          <FontAwesomeIcon
                            size='2x'
                            icon={icons[getType(lesson.type)] || faQuestion}
                            color='inherit'
                          />
                        </Box>
                      </ListItemIcon>
                      <ListItemText
                        primary={lesson.title}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItemLink>
                  )
                default:
                  return null
              }
            })
        ) : null}
      </List>
    </React.Fragment>
  )
}
