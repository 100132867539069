import { MainCtl } from "./main.ctl";

export class UserCtl extends MainCtl {
  /**
   * @params {string} uids users id
   */
  static async getUsers(user, uids) {
    const result = await this.get(
      user,
      `${this.baseUrl()}/user/list`,
      "POST",
      JSON.stringify({ uids })
    );
    return result;
  }
}
