const quoteReplace = (text) => `${text}`?.replace?.('"', '\\"');
const universalBOM = "\uFEFF";
export const CreateCSV = (contents, name) => {
  const text = contents
    .map((content) => {
      return '"' + content.map(quoteReplace).join('","') + '"';
    })
    .join("\r\n");
  let csvContent = `data:text/csv;charset=utf-8,${universalBOM}${text}`;
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
};
