import { MainContainer } from "@knowgistics/core";
import { Route, Switch } from "react-router-dom";
import { PageSubjective } from "../Subjective/index.subjective";
import { connectSubjective } from "./index.context";
import { Sidebar } from "./index.sidebar";

export const SubjectiveEdit = connectSubjective((props) => {
  return (
    <MainContainer dense signInOnly sidebar={<Sidebar />}>
      <Switch>
        <Route
          path={"/view/:id/subjective/:sjvid/section/:secid?"}
          component={PageSubjective}
        />
        <Route
          path={"/edit/:id/section/:secid/subjective/:sjvid"}
          component={(props) => <PageSubjective {...props} hideSection />}
        />
        <Route
          path={"/ta/:id/:secid/subjective/:sjvid"}
          component={(props) => <PageSubjective {...props} hideSection ta />}
        />
      </Switch>
    </MainContainer>
  );
});
