import { db, firebase } from "Controller/firebase";
import { CourseController } from "./courseController";

export class LessonController extends CourseController {
  constructor(user, id) {
    super(user);

    this.user = user;
    this.id = id;
  }
  timestamp = () => firebase.firestore.FieldValue.serverTimestamp();
  updateModified = async () =>
    await firebase
      .firestore()
      .collection("courses")
      .doc(this.id)
      .update({
        datemodified: this.timestamp(),
      });
  watch = (callback) => {
    return firebase
      .firestore()
      .collection("courses")
      .where("type", "in", [
        `${process.env.REACT_APP_PREFIX}lesson`,
        `${process.env.REACT_APP_PREFIX}lesson-private`,
        `${process.env.REACT_APP_PREFIX}question`,
        `${process.env.REACT_APP_PREFIX}question-private`,
        `${process.env.REACT_APP_PREFIX}subjective-private`,
        `${process.env.REACT_APP_PREFIX}subjective`,
      ])
      .where("user", "==", this.user.uid)
      .where("parent", "==", this.id)
      .orderBy("datemodified", "desc")
      .onSnapshot(async (snapshot) => {
        const docs = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .sort((a, b) => (a.sort || 0) - (b.sort || 0));
        const courses = await this.course.get();
        callback(docs, courses);
      });
  };
  add = async () => {
    await this.updateModified();
    const latest = (
      await db
        .collection("courses")
        .where("user", "==", this.user.uid)
        .where("parent", "==", this.id)
        .orderBy("sort", "asc")
        .limitToLast(1)
        .get()
    ).docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    const sort = Boolean(latest.length && typeof latest[0].sort === "number")
      ? latest[0].sort + 1
      : 0;
    return await firebase
      .firestore()
      .collection("courses")
      .add({
        title: `Untitled (${sort + 1})`,
        datecreate: firebase.firestore.FieldValue.serverTimestamp(),
        datemodified: firebase.firestore.FieldValue.serverTimestamp(),
        type: `${process.env.REACT_APP_PREFIX}lesson-private`,
        user: this.user.uid,
        parent: this.id,
        prefix: process.env.REACT_APP_PREFIX,
        sort,
      });
  };
  remove = async (lsid) => {
    await this.updateModified();
    return await firebase
      .firestore()
      .collection("courses")
      .doc(lsid)
      .update({
        type: `${process.env.REACT_APP_PREFIX}lesson-remove`,
      });
  };
  sorting = async (docs) => {
    await this.updateModified();
    const db = firebase.firestore();
    const batch = db.batch();
    docs.forEach(({ id }, index) => {
      batch.update(
        db
          .collection("courses")
          .doc(id),
        { sort: index }
      );
    });
    await batch.commit();
  };
  getQuestion = async () => {
    const docs = (
      await firebase
        .firestore()
        .collection("questions")
        .where("parent", "==", this.id)
        .get()
    ).docs
      .map((d) => ({ ...d.data(), id: d.id }))
      .sort((a, b) => a.title.localeCompare(b.title));
    return docs;
  };
  getSubjective = async () => {
    const docs = (
      await firebase
        .firestore()
        .collection("subjectives")
        .where("parent", "==", this.id)
        .where("prefix", "==", `${process.env.REACT_APP_PREFIX}`)
        .get()
    ).docs
      .map((d) => ({ ...d.data(), id: d.id }))
      .sort((a, b) => a.title.localeCompare(b.title));
    return docs;
  };
  selectCourse = async () => {
    const docs = (
      await firebase
      .firestore()
      .collection("courses")
      .where("user", "==", this.user.uid)
      .where("type", "in", [`${process.env.REACT_APP_PREFIX}course`, `${process.env.REACT_APP_PREFIX}course-private`])
      .get()).docs
      .map((d) => ({ ...d.data(), id: d.id }));
    return docs;
  };
  duplicate = async (olddoc) => {
    const id = olddoc.id;
    const doc = (await this.path("courses", id).get()).data();
    const ref = await db.collection("courses").doc();
    const count = await (await db.collection("courses")
      .where("user", "==", this.user.uid)
      .where("type", "in", [
        `${process.env.REACT_APP_PREFIX}lesson`,
        `${process.env.REACT_APP_PREFIX}lesson-private`,
        `${process.env.REACT_APP_PREFIX}question`,
        `${process.env.REACT_APP_PREFIX}subjective-private`,
        `${process.env.REACT_APP_PREFIX}subjective`,
      ])
      .where("ref", "==", id).get())
      .docs.map((d) => ({ ...d.data(), id: d.id }))
    const batch = db.batch();
    if (olddoc.type === `${process.env.REACT_APP_PREFIX}question`) {
      const oldqt = (await this.path("questions", doc.questionid).get());
      const child = await db.collection("questions").where("parent", "==", oldqt.id).get();
      const count = await (await db.collection("questions")
        .where("user", "==", this.user.uid)
        .where("type", "==", `${process.env.REACT_APP_PREFIX}category`)
        .where("ref", "==", oldqt.id).get())
        .docs.map((d) => ({ ...d.data(), id: d.id }))
      const ref_qt = await db.collection("questions").doc();
      batch.set(ref_qt, {
        ...oldqt.data(),
        ref: oldqt.id,
        title: `${doc.title} #${count.length + 1}`,
        datecreate: this.timestamp(),
        datemodified: this.timestamp(),
      })
      const childlist = child.docs.map(d => ({ ...d.data(), id: d.id }))
      childlist.forEach(async data => {
        batch.set(db.collection("questions").doc(), {
          ...data,
          ref: data.id,
          parent: ref_qt.id,
          datecreate: this.timestamp(),
          datemodified: this.timestamp(),
        })
      })
      batch.set(ref, {
        ...doc,
        ref: id,
        questionid: ref_qt.id,
        title: `${doc.title} #${count.length + 1}`,
        datecreate: this.timestamp(),
        datemodified: this.timestamp(),
      })
    } else {
      batch.set(ref, {
        ...doc,
        ref: id,
        title: `${doc.title} #${count.length + 1}`,
        datecreate: this.timestamp(),
        datemodified: this.timestamp(),
      })
    }
    await batch.commit();
  };
}
