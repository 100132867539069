import {
  ActionIcon,
  ContentEdit,
  KGContext,
  Schedule,
} from "@knowgistics/core";
import {
  useContext,
  forwardRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import { connectLessonEdit, LessonEditContext } from "./Context";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  Select,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
  MenuItem,
  Slide,
} from "@material-ui/core";
import React from "react";
import { faCalendar, faEdit } from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SelectVisibility = forwardRef(
  ({ id, value, onChange, ...props }, ref) => {
    const { t, user, firebase } = useContext(KGContext);
    const [open, setOpen] = useState(false);
    const [auto, setAuto] = useState(null);
    const [v, setV] = useState(`${process.env.REACT_APP_PREFIX}lesson-private`);
    const lists = {
      [`${process.env.REACT_APP_PREFIX}lesson`]: t("Public"),
      [`${process.env.REACT_APP_PREFIX}lesson-private`]: t("Private"),
    };
    const completed = Boolean(value !== v);

    const getAuto = useCallback(() => {
      return firebase
        .firestore()
        .collection("automates")
        .where("parent", "==", id)
        .onSnapshot((snap) => {
          const docs = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          if (docs.length) {
            setAuto(docs[0]);
          } else {
            setAuto(null);
          }
        });
    }, [firebase, id]);

    const handleOpen = (o) => () => setOpen(o);
    const handleChange = ({ target }) => setV(target.value);
    const handleSchedule = async (date) => {
      const data = {
        parent: id,
        path: [{ collection: "courses", doc: id }],
        time: date.utc,
        epoch: date.epoch,
        type: "update",
        user: user.uid,
        value: {
          type: v,
        },
      };
      await firebase.firestore().collection("automates").add(data);
    };
    const handleScheduleCancel = async () =>
      auto &&
      (await firebase
        .firestore()
        .collection("automates")
        .doc(auto.id)
        .delete());
    const handleSave = () => onChange(v);

    useEffect(() => {
      if (value) {
        setV(value);
      }
    }, [value]);

    useEffect(() => getAuto(), [getAuto]);

    return (
      <React.Fragment>
        <ListItem dense divider>
          <ListItemText
            primary={t("Visibility")}
            secondary={lists[value]}
            primaryTypographyProps={{
              variant: "caption",
              color: "textSecondary",
            }}
            secondaryTypographyProps={{
              variant: "body1",
              color: "textPrimary",
            }}
          />
          <ListItemSecondaryAction>
            <ActionIcon icon={faEdit} onClick={handleOpen(true)} />
          </ListItemSecondaryAction>
        </ListItem>
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={handleOpen(false)}
          TransitionComponent={Slide}
          TransitionProps={{ direction: "right" }}
        >
          <DialogTitle>{t("Change")}</DialogTitle>
          <DialogContent>
            {auto ? (
              <ListItem
                component="div"
                dense
                style={{
                  border: "solid 1px rgba(0,0,0,0.12)",
                  borderRadius: 8,
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faCalendar} size="2x" />
                </ListItemIcon>
                <ListItemText
                  primary={t("ChangeAt", {
                    name: lists[auto.value.type],
                    date: moment(auto.epoch).format("ll LT"),
                  })}
                />
              </ListItem>
            ) : (
              <Select
                fullWidth
                variant="outlined"
                value={v}
                onChange={handleChange}
              >
                {Object.keys(lists).map((key) => (
                  <MenuItem value={key} key={key}>
                    {lists[key]}
                  </MenuItem>
                ))}
              </Select>
            )}
          </DialogContent>
          <DialogActions>
            {auto ? (
              <Button color="secondary" onClick={handleScheduleCancel}>
                {t("Cancel")}
              </Button>
            ) : (
              <React.Fragment>
                <Schedule onChange={handleSchedule}>
                  <Button disabled={!completed}>{t("Schedule")}</Button>
                </Schedule>
                <Box flex={1} />
                <Button
                  color="primary"
                  disabled={!completed}
                  onClick={handleSave}
                >
                  {t("Save")}
                </Button>
              </React.Fragment>
            )}

            <Button onClick={handleOpen(false)}>{t("Close")}</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
);

export const PageLessonEdit = connectLessonEdit((props) => {
  const { id, lsid, control, ...store } = useContext(LessonEditContext);
  const [data, setData] = store.data;
  const LessonConfig = {
    prefix: "mek-",
    show: ["title", "heading", "paragraph", "vdo", "image", "table", "file", "divider"],
    back: `/edit/${id}/lesson`,
  };

  const handleChange = (key) => async (value) => {
    const filterValue = JSON.parse(JSON.stringify(value));
    await setData((s) => ({ ...s, [key]: filterValue }));
  };

  const handleSave = (newData) => {
    control.update(newData);
  };

  return (
    <ContentEdit
      data={data}
      config={LessonConfig}
      onSave={handleSave}
      post
      sidebarSecondaryActions={
        <List>
          <SelectVisibility
            id={lsid}
            value={data.type}
            onChange={handleChange("type")}
          />
        </List>
      }
    />
  );
});
