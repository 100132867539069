import { firebase } from "Controller/firebase";
import { createContext, useEffect, useState } from "react";
export const TruefalseContext = createContext({});

const TruefalseProvider = (props) => {
  const { parent, id } = props.match.params;
  const [data, setData] = useState({
    question: null,
    answers: true,
  });

  const defaultData = () => ({
    question: null,
    answers: true,
  });

  const [state, setState] = useState({
    fetching: false,
    remove: null,
  });

  const store = {
    parent,
    id,
    history: props.history,
    data: [data, setData],
    state: [state, setState],
  };

  useEffect(() => {
    if (id) {
      setState((s) => ({ ...s, fetching: true }));
      firebase
        .firestore()
        .collection("questions")
        .doc(id)
        .get()
        .then((snap) => {
          const data = snap.data();
          if (data) {
            setData((d) => ({ ...d, ...data }));
          }
          setState((s) => ({ ...s, fetching: false }));
        });
    } else {
      setData({ ...defaultData() });
    }
  }, [id]);

  return (
    <TruefalseContext.Provider value={store}>
      {props.children}
    </TruefalseContext.Provider>
  );
};

export const connectContext = (Comp) => (props) =>
  (
    <TruefalseProvider {...props}>
      <Comp {...props} />
    </TruefalseProvider>
  );
