import { BackLink, KGContext, TitleEdit } from "@knowgistics/core";
import { useContext } from "react";
import { QuestionBankEditContext } from "./context";

export const Sidebar = (props) => {
  const { addAlert } = useContext(KGContext);
  const { id, tab, parentID, control, ...store } = useContext(QuestionBankEditContext);
  const [data] = store.data;

  const handleChange = (key) => async (value) => {
    const filterValue = JSON.parse(JSON.stringify(value));
    await control.update({ [key]: filterValue });
    addAlert({label:"Update Success."})
  };

  return (
    <>
      <BackLink to={`/edit/${parentID}/question`} />
      <TitleEdit value={data.title} onChange={handleChange("title")} />
    </>
  );
};
