import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  ContentHeader,
  DialogRemove,
  KuiActionIcon,
  MainContainer,
} from "@knowgistics/core";
import { IconButton, Link, Tooltip } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { TeacherAdd } from "./add";
import { connectManageTeacher, injectManageTeacher } from "./context";
import { TeacherChangeRole } from "./edit";

export const roleLabels = {
  owner: "Administrator",
  teacher: "Teacher",
};

export const PageManageTeacher = connectManageTeacher(
  injectManageTeacher(
    ({
      store: {
        states: [state, setState],
        control,
        user,
        t,
      },
      ...props
    }) => {
      const role =
        state?.docs?.find((u) => u.id === user?.uid)?.role ?? "teacher";

      const handleChangeRole = (uid) => async (value) => {
        await control.changeRole(uid, value);
      };
      const handleRemove = (remove) => () =>
        setState((s) => ({ ...s, remove }));
      const handleRemoveConfirm = async () => {
        await control.removeUser({ uid: state?.remove?.id });
        handleRemove(null)();
      };

      return (
        <MainContainer>
          <Container maxWidth="md">
            <ContentHeader label="Manage Teacher" actions={<TeacherAdd />} />
            <DataGrid
              loading={!state?.fetched}
              columns={[
                {
                  field: "displayName",
                  headerName: "Name",
                  width: 360,
                  valueGetter: (params) => {
                    const { uid } = user;
                    return params.value + (uid === params.id ? ` (You)` : "");
                  },
                },
                {
                  field: "role",
                  headerName: "Role",
                  width: 150,
                  valueGetter: (params) => {
                    const { role } = params.row;
                    return roleLabels?.[role] ?? "Teacher";
                  },
                },
                {
                  field: "email",
                  headerName: "E-mail",
                  renderCell: (params) => (
                    <Link href={`mailto:${params.value}`} target="_blank">
                      {params.value}
                    </Link>
                  ),
                  width: 240,
                },
                {
                  field: "action",
                  headerName: "Edit",
                  sortable: false,
                  filterable: false,
                  renderCell: (params) => {
                    return (
                      <>
                        {role === "owner" && (
                          <>
                            <TeacherChangeRole
                              value={params?.row?.role}
                              onConfirm={handleChangeRole(params?.id)}
                            />
                            <KuiActionIcon
                              tx="remove"
                              onClick={handleRemove(params?.row)}
                            />
                          </>
                        )}
                        <Tooltip title={`User ID: ${params.id}`}>
                          <IconButton size="small">
                            <FontAwesomeIcon size="xs" icon={faInfoCircle} />
                          </IconButton>
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
              autoHeight
              rows={state?.docs}
              pageSize={25}
              disableSelectionOnClick
            />
          </Container>
          <DialogRemove
            title={t("Remove")}
            label={t("Do you want to remove", {
              name: state?.remove?.displayName,
            })}
            open={Boolean(state?.remove)}
            onClose={handleRemove(null)}
            onConfirm={handleRemoveConfirm}
          />
        </MainContainer>
      );
    }
  )
);
