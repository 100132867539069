import { React, useContext, Fragment } from "react";
import { BackLink, SaveButton, KGContext } from "@knowgistics/core";
import { db, dbTimestamp } from "Controller/firebase";
import { MatchingContext } from "./Context";
import CTLQuestion from "../components/CTLQuestion";
import { Link } from "react-router-dom";
import {
  List,
  ListSubheader,
  ListItem,
  Button,
  Divider,
} from "@material-ui/core";

export const Sidebar = (props) => {
  const { user, addAlert } = useContext(KGContext);
  const store = useContext(MatchingContext);
  const [data] = store.data;
  const [, setState] = store.state;

  const handleSave = async () => {
    if (user) {
      setState((s) => ({ ...s, fetching: true }));
      if (store.id) {
        await db
          .collection("questions")
          .doc(store.id)
          .update({
            ...data,
            dateedit: dbTimestamp(),
          });
      } else {
        const result = await db.collection("questions").add({
          ...data,
          user: user.uid,
          datecreate: dbTimestamp(),
          dateedit: dbTimestamp(),
          parent: store.parent,
          type: "matching",
        });
        if (result.id) {
          store.history.push(
            `/question/edit/matching/${store.parent}/${result.id}/`
          );
        }
      }
      addAlert({ label: "Update Success." });
      setState((s) => ({ ...s, fetching: false }));
    }
  };

  const isComplete =
    data.question &&
    data.answers.filter((d) => {
      switch (d.type) {
        case "paragraph":
          return Boolean(d.label && d.value);
        case "image":
          return Boolean(d.data.id);
        default:
          return false;
      }
    }).length === data.answers.length;

  return (
    <>
      <BackLink to={"/"} />
      <SaveButton disabled={!isComplete} onSave={handleSave} />
      {store.parent && store.id && (
        <Fragment>
          <List subheader={<ListSubheader>Add New Question</ListSubheader>}>
            {Object.keys(CTLQuestion.labels).map((key) => (
              <ListItem
                component={Link}
                to={`/question/edit/${key}/${store.parent}/`}
                key={key}
              >
                <Button fullWidth size="large" variant="outlined">
                  {CTLQuestion.labels[key]}
                </Button>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Fragment>
      )}
    </>
  );
};
