import { useEffect, useState } from "react";
import Chart from "react-google-charts";

const dfGrades = {
  A: 0,
  "B+": 0,
  B: 0,
  "C+": 0,
  C: 0,
  "D+": 0,
  D: 0,
  F: 0,
};
export const GradeSummary = ({ rows, ...props }) => {
  let [grades, setGrades] = useState({ ...dfGrades });

  useEffect(() => {
    if (rows) {
      setGrades((g) => {
        let grades = rows.reduce(
          (t, r) => {
            t[r?.grade] = t?.[r?.grade] + 1;
            return t;
          },
          { ...dfGrades }
        );
        return grades;
      });
    }
  }, [rows]);

  return (
    <Chart
      width="100%"
      height={300}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={[
        ["Grade", "ผู้เรียน (คน)"],
        ...Object.keys(grades).map((k) => [k, grades[k]]),
      ]}
      options={{
        title: "เกรดของผู้เรียน",
        chartArea: { width: "60%" },
        hAxis: {
          title: "เกรด",
          minValue: 0,
        },
        vAxis: {
          title: "จำนวน",
        },
      }}
    />
  );
};
