import {
  Container,
  ContentHeader,
  KGContext,
  ActionIcon,
  DialogRemove,
  Sortable,
  SortListItem,
  DateToStr,
  KuiListItemText,
} from "@knowgistics/core";
import { useContext} from "react";
import { Link } from "react-router-dom";
import {
  faChalkboard,
  faEdit,
  faFileAlt,
  faListOl,
  faLock,
  faTrash,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
} from "@material-ui/core";
import { arrayMove } from "react-sortable-hoc";
import { AddButton, QuizEdit, SubjectiveEdit } from "./index.add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connectLessonLists, LessonListsContext } from "./index.context";
import { LessonClone } from "./clone";

const getType = (type) => {
  switch (type) {
    case `${process.env.REACT_APP_PREFIX}question`:
      return "OnlineQuiz";
    case `${process.env.REACT_APP_PREFIX}subjective`:
      return "SubjectiveQuiz";
    default:
      return "Lesson";
  }
};

const ListItemLesson = ({ id, index, doc, onRemove, onDuplicate }) => {
  const { t } = useContext(KGContext);
  return (
    <SortListItem
      index={index}
      key={doc.id}
      listItemProps={{
        dense: true,
        divider: true,
        button: true,
        component: Link,
        to: `/view/${id}/lesson/${doc.id}`,
        target: "_blank",
      }}
    >
      <KuiListItemText
        tx="body"
        primary={
          <>
            <FontAwesomeIcon icon={faChalkboard} />
            &nbsp;&nbsp;
            {doc.type.includes("private") && (
              <FontAwesomeIcon icon={faLock} style={{ marginRight: 8 }} />
            )}
            {doc.title}
          </>
        }
        secondary={
          t(`${getType(doc.type)}`) +
          ` | ` +
          t("LastModified") +
          " " +
          DateToStr(doc)
        }
      />
      <ListItemSecondaryAction>
        <LessonClone id={doc.id} />
        <ActionIcon
          component={Link}
          to={`/edit/${id}/lesson/edit/${doc.id}`}
          icon={faEdit}
          color="primary"
        />
        <ActionIcon color="secondary" icon={faTrash} onClick={onRemove} />
      </ListItemSecondaryAction>
    </SortListItem>
  );
};

const ListItemQuestion = ({ id, index, doc, onRemove, onDuplicate }) => {
  const { t } = useContext(KGContext);
  return (
    <SortListItem
      index={index}
      key={doc.id}
      listItemProps={{
        button: true,
        dense: true,
        divider: true,
        component: Link,
        to: `/view/${id}/question/${doc.id}`,
        target: "_blank",
      }}
    >
      <KuiListItemText
        tx="body"
        primary={
          <>
            <FontAwesomeIcon icon={faListOl} />
            &nbsp;&nbsp;
            {doc.type.includes("private") && (
              <FontAwesomeIcon icon={faLock} style={{ marginRight: 8 }} />
            )}
            {doc.title}
          </>
        }
        secondary={
          t(`${getType(doc.type)}`) +
          ` | ` +
          t("LastModified") +
          " " +
          DateToStr(doc)
        }
        secondaryTypographyProps={{ variant: "caption" }}
      />
      <ListItemSecondaryAction>
        <LessonClone id={doc.id} />
        <QuizEdit data={doc}>
          <ActionIcon icon={faEdit} color="primary" />
        </QuizEdit>
        <ActionIcon color="secondary" icon={faTrash} onClick={onRemove} />
      </ListItemSecondaryAction>
    </SortListItem>
  );
};

const ListItemSubjective = ({ id, index, doc, onRemove}) => {
  const { t } = useContext(KGContext);
  return (
    <SortListItem
      index={index}
      key={doc.id}
      listItemProps={{
        button: true,
        dense: true,
        divider: true,
        component: Link,
        to: `/view/${id}/subjective/${doc.id}/section`,
      }}
    >
      <KuiListItemText
        tx="body"
        primary={
          <>
            <FontAwesomeIcon icon={faFileAlt} />
            &nbsp;&nbsp;
            {doc.type.includes("private") && (
              <FontAwesomeIcon icon={faLock} style={{ marginRight: 8 }} />
            )}
            {doc.title}
          </>
        }
        secondary={
          t(`${getType(doc.type)}`) +
          ` | ` +
          t("LastModified") +
          " " +
          DateToStr(doc)
        }
        secondaryTypographyProps={{ variant: "caption" }}
      />
      <ListItemSecondaryAction>
        <LessonClone id={doc.id} />
        <SubjectiveEdit doc={doc}>
          <ActionIcon icon={faEdit} color="primary" />
        </SubjectiveEdit>
        <ActionIcon color="secondary" icon={faTrash} onClick={onRemove} />
      </ListItemSecondaryAction>
    </SortListItem>
  );
};

export const PageLesson = connectLessonLists(({ history, ...props }) => {
  const { id, controller, ...store } = useContext(LessonListsContext);
  const { addAlert, t } = useContext(KGContext);
  const [state, setState] = store.state;

  const handleAdd = async (props) => {
    const result = await controller.add();
    if (result.id) {
      history.push(`lesson/edit/${result.id}`);
    }
  };
  const handleClone = (data) => async () => {
    await controller.duplicate(data);
  }
  const handleRemove = (remove) => () => setState((s) => ({ ...s, remove }));
  const handleRemoveConfirm = async () => {
    if (state.remove) {
      await controller.remove(state.remove.id);
      setState((s) => ({ ...s, remove: null }));
    }
  };
  const handleSortEnd = async ({ oldIndex, newIndex }) => {
    const docs = arrayMove(state.docs, oldIndex, newIndex);
    setState((s) => ({ ...s, docs }));
    await controller.sorting(docs);
    addAlert({ label: "Update Success" });
  };

  return (
    <Box py={6}>
      <Container maxWidth="sm">
        <ContentHeader
          label={t("Lesson")}
          breadcrumbs={[
            { label: t("Home"), to: "/" },
            { label: t("Courses.MyCourse"), to: "/" },
            { label: t("Lesson") },
          ]}
          actions={<AddButton onAddLesson={handleAdd} />}
        />
        <List disablePadding>
          <Sortable
            divider
            docs={state.docs}
            onSortEnd={handleSortEnd}
            component={(doc, index) => {
              switch (doc.type) {
                case `${process.env.REACT_APP_PREFIX}question`:
                case `${process.env.REACT_APP_PREFIX}question-private`:
                  return (
                    <ListItemQuestion
                      id={id}
                      doc={doc}
                      index={index}
                      key={doc.id}
                      onRemove={handleRemove(doc)}
                      onDuplicate={handleClone(doc)}
                    />
                  );
                case `${process.env.REACT_APP_PREFIX}subjective`:
                case `${process.env.REACT_APP_PREFIX}subjective-private`:
                  return (
                    <ListItemSubjective
                      id={id}
                      doc={doc}
                      index={index}
                      key={doc.id}
                      onRemove={handleRemove(doc)}
                      onDuplicate={handleClone(doc)}
                      selectCourse={controller.selectCourse()}
                    />
                  );
                default:
                  return (
                    <ListItemLesson
                      id={id}
                      doc={doc}
                      index={index}
                      key={doc.id}
                      onRemove={handleRemove(doc)}
                      onDuplicate={handleClone(doc)}
                    />
                  );
              }
            }}
          />
          {state.docs.length === 0 && (
            <ListItem divider>
              <ListItemText secondary={"No item"} />
            </ListItem>
          )}
        </List>
      </Container>
      <DialogRemove
        title="Remove Course"
        label={t("Courses.DoYouWantToRemove", {
          name: state.remove ? state.remove.title : "",
        })}
        open={Boolean(state.remove)}
        onClose={handleRemove(null)}
        onConfirm={handleRemoveConfirm}
      />
    </Box>
  );
});
