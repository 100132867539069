import { faPaperclip, faPlus } from "@fortawesome/pro-duotone-svg-icons";
import {
  DialogPre,
  DuotoneButton,
  FilePicker,
  KGContext,
  KuiActionIcon,
  KuiButton,
  Paragraph,
  Schedule,
  Transition,
} from "@knowgistics/core";
import {
  cloneElement,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  withStyles,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { LessonListsContext } from "./index.context";
import { faCalendar } from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AddButton = ({ classes, onAddLesson, ...props }) => {
  const { t } = useContext(KGContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <DuotoneButton icon={faPlus} onClick={handleClick}>
        {t("AddMaterial")}
      </DuotoneButton>
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onAddLesson}>{t("Lesson")}</MenuItem>
        <QuizEdit>
          <MenuItem>{t("OnlineQuiz")}</MenuItem>
        </QuizEdit>
        <SubjectiveEdit>
          <MenuItem>{t("SubjectiveQuiz")}</MenuItem>
        </SubjectiveEdit>
      </Menu>
    </>
  );
};

const defaultData = {
  title: "",
  questionid: "",
  amount: 0,
  point: 0,
  fixnumber: 1,
  type: `${process.env.REACT_APP_PREFIX}question-private`,
};
export const QuizEdit = withStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(2),
  },
}))(({ classes, ...props }) => {
  const { user, firebase, addAlert, t } = useContext(KGContext);
  const { id, controller } = useContext(LessonListsContext);
  const [v, setV] = useState(`${process.env.REACT_APP_PREFIX}question-private`);
  const [state, setState] = useState({
    fetched: false,
    cats: [],
  });
  const [auto, setAuto] = useState(null);
  const [data, setData] = useState({ ...defaultData });
  const [openQuiz, setOpenQuiz] = useState(false);
  const isComplete = Boolean(
    data.title && data.questionid && data.amount > 0 && data.point >= 0
  );

  const lists = {
    [`${process.env.REACT_APP_PREFIX}question`]: t("Public"),
    [`${process.env.REACT_APP_PREFIX}question-private`]: t("Private"),
  };
  const completed = Boolean(data.type !== v);

  const getAuto = useCallback(() => {
    if (data?.id) {
      return firebase
        .firestore()
        .collection("automates")
        .where("parent", "==", data.id)
        .onSnapshot((snap) => {
          const docs = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          if (docs.length) {
            setAuto(docs[0]);
          } else {
            setAuto(null);
          }
        });
    }
  }, [firebase, data.id]);

  const handleSchedule = async (date) => {
    const dataTime = {
      parent: data?.id,
      path: [{ collection: "courses", doc: data.id }],
      time: date.utc,
      epoch: date.epoch,
      type: "update",
      user: user.uid,
      value: {
        type: v,
      },
    };
    await firebase.firestore().collection("automates").add(dataTime);
  };

  const handleScheduleCancel = async () =>
    auto &&
    (await firebase.firestore().collection("automates").doc(auto.id).delete());

  const handleChangeTime = ({ target }) => setV(target.value);
  const handleOpen = (o) => () => setOpenQuiz(o);
  const handleChange =
    (key) =>
    ({ target }) =>
      setData((d) => ({ ...d, [key]: target.value }));
  const handleAdd = async () => {
    if (isComplete) {
      if (props.data) {
        await firebase
          .firestore()
          .collection("courses")
          .doc(props.data.id)
          .update({
            ...data,
            type: auto ? data.type : v,
            datemodified: firebase.firestore.FieldValue.serverTimestamp(),
          });
        addAlert({ label: "Update success" });
        setOpenQuiz(false);
      } else {
        const sort = (
          await firebase
            .firestore()
            .collection("courses")
            .where("user", "==", user.uid)
            .where("parent", "==", id)
            .get()
        ).docs.length;
        const doc = {
          ...data,
          type: v,
          datecreate: firebase.firestore.FieldValue.serverTimestamp(),
          datemodified: firebase.firestore.FieldValue.serverTimestamp(),
          user: user.uid,
          parent: id,
          sort: sort,
          prefix: process.env.REACT_APP_PREFIX,
        };
        const result = await firebase
          .firestore()
          .collection("courses")
          .add(doc);
        if (result.id) {
          addAlert({ label: "Save success" });
          setOpenQuiz(false);
          setData({ ...defaultData });
        }
      }
    }
  };

  const genProps = (key) => ({
    className: classes.input,
    variant: "outlined",
    fullWidth: true,
    value: data[key],
    onChange: handleChange(key),
  });
  const questionidparse = (value) =>
    state.cats.map((cat) => cat.id).includes(value);
  const handleChangeBank = async ({ target }) => {
    const questionid = target.value;
    setData((s) => ({ ...s, questionid }));
    if (questionid) {
      const snapshot = await firebase
        .firestore()
        .collection("questions")
        .where("parent", "==", questionid)
        .get();
      const amount = snapshot.docs.length;
      const find = state.cats.find((c) => c.id === questionid);
      setData((d) => ({
        ...d,
        amount,
        point: d.point ? d.point : amount,
        title: d.title ? d.title : find.title || "",
      }));
    }
  };
  const handleClear = () => setData({ ...defaultData });

  useEffect(() => {
    if (openQuiz && controller) {
      controller.getQuestion().then((cats) => {
        setState((s) => ({ ...s, cats, fetched: true }));
        if (props.data) {
          setData((d) => ({ ...d, ...props.data }));
        }
      });
    } else {
      setData({ ...defaultData });
      if (props.data) {
        setData((d) => ({ ...d, ...props.data }));
      }
    }
  }, [openQuiz, controller, props.data]);

  useEffect(() => {
    if (data.type) {
      setV(data.type);
    }
  }, [data.type]);

  useEffect(() => getAuto(), [getAuto]);

  return (
    <Fragment>
      {props.children &&
        cloneElement(props.children, {
          onClick: handleOpen(true),
        })}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openQuiz}
        onClose={handleOpen(false)}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add Question</DialogTitle>
        <DialogContent>
          <FormControl className={classes.input} fullWidth variant="outlined">
            <InputLabel id="add-quiz-label">แบบทดสอบ</InputLabel>
            <Select
              labelId="add-quiz-label"
              labelWidth={108}
              value={questionidparse(data.questionid) ? data.questionid : ""}
              onChange={handleChangeBank}
            >
              <MenuItem value="" disabled>
                -- เลือก Question Bank --
              </MenuItem>
              {state.cats.map((cat) => (
                <MenuItem value={cat.id} key={cat.id}>
                  {cat.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField label="ชื่อแบบทดสอบ" {...genProps("title")} />
          <TextField
            label="จำนวนข้อ"
            type="number"
            inputProps={{ min: 0 }}
            {...genProps("amount")}
          />
          <TextField
            label="คะแนน"
            type="number"
            inputProps={{ min: 0 }}
            {...genProps("point")}
          />
          <TextField
            label="จำนวนครั้ง"
            type="number"
            inputProps={{ min: 0 }}
            {...genProps("fixnumber")}
          />
          <FormControl className={classes.input} fullWidth variant="outlined">
            {auto ? (
              <ListItem
                component="div"
                dense
                style={{
                  border: "solid 1px rgba(0,0,0,0.12)",
                  borderRadius: 8,
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faCalendar} size="2x" />
                </ListItemIcon>
                <ListItemText
                  primary={t("ChangeAt", {
                    name: lists[auto.value.type],
                    date: moment(auto.epoch).format("ll LT"),
                  })}
                />
              </ListItem>
            ) : (
              <>
                <InputLabel id="add-quiz-label">{t("Visibility")}</InputLabel>
                <Select
                  labelId="add-quiz-label"
                  labelWidth={108}
                  value={v}
                  onChange={handleChangeTime}
                  // value={data.type}
                  // onChange={handleChange("type")}
                >
                  <MenuItem
                    value={`${process.env.REACT_APP_PREFIX}question-private`}
                  >
                    Private
                  </MenuItem>
                  <MenuItem value={`${process.env.REACT_APP_PREFIX}question`}>
                    Public
                  </MenuItem>
                </Select>
              </>
            )}

            {/* <InputLabel id="add-quiz-label">{t("Visibility")}</InputLabel>
            <Select
              labelId="add-quiz-label"
              labelWidth={108}
              value={v}
              onChange={handleChangeTime}
              // value={data.type}
              // onChange={handleChange("type")}
            >
              <MenuItem
                value={`${process.env.REACT_APP_PREFIX}question-private`}
              >
                Private
              </MenuItem>
              <MenuItem value={`${process.env.REACT_APP_PREFIX}question`}>
                Public
              </MenuItem>
            </Select> */}
          </FormControl>
        </DialogContent>
        <DialogActions>
          {data?.id &&
            (auto ? (
              <Button color="secondary" onClick={handleScheduleCancel}>
                {t("Cancel")}
              </Button>
            ) : (
              <Fragment>
                <Schedule onChange={handleSchedule}>
                  <Button disabled={!completed}>{t("Schedule")}</Button>
                </Schedule>
              </Fragment>
            ))}

          <Button color="secondary" onClick={handleClear}>
            {t("Clear")}
          </Button>
          <Box flex={1} />
          <KuiButton tx="confirm" onClick={handleAdd}>
            {props.data ? t("Save") : t("Add")}
          </KuiButton>
          <KuiButton tx="close" onClick={handleOpen(false)} />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

export const SubjectiveEdit = withStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(2),
  },
}))(({ classes, doc, ...props }) => {
  const { user, firebase, addAlert, t } = useContext(KGContext);
  const { id } = useContext(LessonListsContext);
  const [data, setData] = useState(doc);
  const ref = useRef({});
  const isComplete = Boolean(
    data?.title && data?.question && data?.duedate && data?.type
  );

  const handleChange =
    (key) =>
    ({ target: { value } }) =>
      setData((d) => ({ ...d, [key]: value }));
  const [v, setV] = useState(
    `${process.env.REACT_APP_PREFIX}subjective-private`
  );
  const [auto, setAuto] = useState(null);
  const [types] = useState({
    type: `${process.env.REACT_APP_PREFIX}subjective-private`,
  });
  const lists = {
    [`${process.env.REACT_APP_PREFIX}subjective`]: t("Public"),
    [`${process.env.REACT_APP_PREFIX}subjective-private`]: t("Private"),
  };
  const completed = Boolean(types.type !== v);
  const handleSchedule = async (date) => {
    const dataTime = {
      parent: data?.id,
      path: [{ collection: "courses", doc: data?.id }],
      time: date.utc,
      epoch: date.epoch,
      type: "update",
      user: user.uid,
      value: {
        type: v,
      },
    };
    await firebase.firestore().collection("automates").add(dataTime);
  };
  const handleScheduleCancel = async () =>
    auto &&
    (await firebase.firestore().collection("automates").doc(auto.id).delete());
  const getAuto = useCallback(() => {
    if (data?.id) {
      return firebase
        .firestore()
        .collection("automates")
        .where("parent", "==", data.id)
        .onSnapshot((snap) => {
          const docs = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          if (docs.length) {
            setAuto(docs[0]);
          } else {
            setAuto(null);
          }
        });
    }
  }, [firebase, data?.id]);
  // const handleChangeTime = ({ target }) => setV(target.value);
  const genProps = (key) => ({
    className: classes.input,
    inputRef: (r) => {
      ref.current[key] = r;
    },
    variant: "outlined",
    fullWidth: true,
    value: (data && data[key]) || "",
    onChange: handleChange(key),
  });
  const handleFileAttach = (files) => {
    // console.log(files[0].id)
    if (data.files) {
      console.log("pushData");
    } else {
      setData((s) => ({ ...s, files: [id] }));
    }
  };
  const handleConfirm = async () => {
    const path = firebase.firestore().collection("courses");
    const timestamp = () => firebase.firestore.FieldValue.serverTimestamp();

    if (isComplete) {
      if (doc) {
        const { datecreate, ...saveData } = data;
        await path.doc(doc.id).update({
          ...saveData,
          type: auto ? types.type : v,
          datemodified: timestamp(),
        });
        addAlert({ label: "Update success" });
      } else {
        const sort = (
          await path
            .where("user", "==", user.uid)
            .where("parent", "==", id)
            .get()
        ).docs.length;
        await path.add({
          ...data,
          type: v,
          datecreate: timestamp(),
          datemodified: timestamp(),
          user: user.uid,
          parent: id,
          sort: sort,
          prefix: process.env.REACT_APP_PREFIX,
        });
        addAlert({ label: "Save success" });
      }
    } else {
      addAlert({ label: "Data incomplete", severity: "error" });
    }
  };

  useEffect(() => {
    if (data?.type) {
      setV(data?.type);
    }
  }, [data?.type]);

  useEffect(() => getAuto(), [getAuto]);

  return (
    <Fragment>
      <DialogPre
        maxWidth="sm"
        title="Subjective"
        button={props.children}
        onConfirm={handleConfirm}
        confirmButtonProps={{ disabled: !isComplete }}
        secondaryActions={
          <>
            {data?.id &&
              (auto ? (
                <Box ml={2}>
                  <Button color="secondary" onClick={handleScheduleCancel}>
                    {t("Cancel")}
                  </Button>
                </Box>
              ) : (
                <Fragment>
                  <Box ml={2}>
                    <Schedule onChange={handleSchedule}>
                      <Button disabled={!completed}>{t("Schedule")}</Button>
                    </Schedule>
                  </Box>
                </Fragment>
              ))}
          </>
        }
      >
        <TextField label={t("Title")} {...genProps("title")} />
        <Box mt={3} mb={5}>
          <Paragraph
            editOnly
            value={data?.question ?? null}
            onChange={(question) => setData((s) => ({ ...s, question }))}
          />
        </Box>
        <Box mb={2}>
          <FilePicker onConfirm={handleFileAttach}>
            <Button
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faPaperclip} />}
            >
              Attach Files
            </Button>
          </FilePicker>
          <List>
            {data?.files?.map((doc, index) => (
              <ListItem key={index}>
                <ListItemText primary={doc} />
                <ListItemSecondaryAction>
                  <KuiActionIcon tx={"remove"} />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
        <TextField
          label={t("DateDue")}
          {...genProps("duedate")}
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        <FormControl className={classes.input} fullWidth variant="outlined">
          {auto ? (
            <ListItem
              component="div"
              dense
              style={{
                border: "solid 1px rgba(0,0,0,0.12)",
                borderRadius: 8,
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faCalendar} size="2x" />
              </ListItemIcon>
              <ListItemText
                primary={t("ChangeAt", {
                  name: lists[auto.value.type],
                  date: moment(auto.epoch).format("ll LT"),
                })}
              />
            </ListItem>
          ) : (
            <>
              <InputLabel id="add-quiz-label">{t("Visibility")}</InputLabel>
              <Select
                labelId="add-quiz-label"
                labelWidth={108}
                {...genProps("type")}
                // value={v}
                // onChange={handleChangeTime}
              >
                <MenuItem value="" disabled>
                  -- {t("Visibility")} --
                </MenuItem>
                <MenuItem
                  value={`${process.env.REACT_APP_PREFIX}subjective-private`}
                >
                  {t("Private")}
                </MenuItem>
                <MenuItem value={`${process.env.REACT_APP_PREFIX}subjective`}>
                  {t("Public")}
                </MenuItem>
              </Select>
            </>
          )}
        </FormControl>

        {/* {data?.id &&
          (auto ? (
            <Button color="secondary" onClick={handleScheduleCancel}>
              {t("Cancel")}
            </Button>
          ) : (
            <Fragment>
              <Schedule onChange={handleSchedule}>
                <Button disabled={!completed}>{t("Schedule")}</Button>
              </Schedule>
            </Fragment>
          ))} */}

        {/* <FormControl className={classes.input} fullWidth variant="outlined">
          <InputLabel id="add-quiz-label">{t("Visibility")}</InputLabel>
          <Select
            labelId="add-quiz-label"
            labelWidth={108}
            {...genProps("type")}
          >
            <MenuItem value={v} disabled>
              -- {t("Visibility")} --
            </MenuItem>
            <MenuItem
              value={`${process.env.REACT_APP_PREFIX}subjective-private`}
            >
              {t("Private")}
            </MenuItem>
            <MenuItem value={`${process.env.REACT_APP_PREFIX}subjective`}>
              {t("Public")}
            </MenuItem>
          </Select>
        </FormControl> */}
      </DialogPre>
    </Fragment>
  );
});
