import { DateToStr, SkeletonController } from "@knowgistics/core";
import { firebase, db } from "Controller/firebase";

export class QuestionController extends SkeletonController {
  constructor(user, id) {
    super(firebase);
    this.user = user;
    this.id = id;
  }
  updateModified = async () =>
    await firebase.firestore().collection("courses").doc(this.id).update({
      datemodified: firebase.firestore.FieldValue.serverTimestamp(),
    });
  watch = (callback) => {
    return firebase
      .firestore()
      .collection("questions")
      .where("user", "==", this.user.uid)
      .where("type", "==", `${process.env.REACT_APP_PREFIX}category`)
      .orderBy("datemodified", "desc")
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            date: DateToStr(doc.data(), "YYYY-MM-DD LT"),
          }))
          .filter((doc) => doc.parent === this.id);

        callback(docs);
      });
  };
  add = async () => {
    await this.updateModified();
    return await firebase
      .firestore()
      .collection("questions")
      .add({
        title: "Untitled Question",
        datecreate: firebase.firestore.FieldValue.serverTimestamp(),
        datemodified: firebase.firestore.FieldValue.serverTimestamp(),
        parent: this.id,
        type: `${process.env.REACT_APP_PREFIX}category`,
        user: this.user.uid,
      });
  };
  remove = async (id) => {
    await this.updateModified();
    return await firebase
      .firestore()
      .collection("questions")
      .doc(id)
      .update({
        type: `${process.env.REACT_APP_PREFIX}category-remove`,
        user: this.user.uid,
      });
  };
  duplicate = async (olddoc) => {
    const id = olddoc.id;
    const doc = (await this.path("questions", id).get()).data();
    const child = await db
      .collection("questions")
      .where("parent", "==", id)
      .get();
    const ref = await db.collection("questions").doc();
    const count = await (
      await db
        .collection("questions")
        .where("user", "==", this.user.uid)
        .where("type", "==", `${process.env.REACT_APP_PREFIX}category`)
        .where("ref", "==", id)
        .get()
    ).docs.map((d) => ({ ...d.data(), id: d.id }));
    const batch = db.batch();
    batch.set(ref, {
      ...doc,
      ref: id,
      title: `${doc.title} #${count.length + 1}`,
      datecreate: this.timestamp(),
      datemodified: this.timestamp(),
    });
    const childlist = child.docs.map((d) => ({ ...d.data(), id: d.id }));
    childlist.forEach(async (data) => {
      batch.set(db.collection("questions").doc(), {
        ...data,
        ref: data.id,
        parent: ref.id,
        datecreate: this.timestamp(),
        datemodified: this.timestamp(),
      });
    });
    batch.commit();
  };
}
