import {
  Container,
  ContentHeader,
  ActionIcon,
  KGContext,
} from "@knowgistics/core";
import { DialogExcel } from "@knowgistics/kgui";
import React, { useContext } from "react";
import { connectSection, SectionContext } from "../SectionEdit/context";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { Box } from "@material-ui/core";
import { ImportButton } from "./import.button";
import { DialogAdd } from "./dialog.add";
import { usePopup } from "Components/react-popup";
import update from "react-addons-update";
import { SectionStudent } from "Controller/section.ctl";
import { TableDocs } from "Components/table.docs";

export const PageSecStudent = connectSection((props) => {
  const { addAlert, t } = useContext(KGContext);
  const { Popup } = usePopup();
  const {
    id,
    secid,
    control,
    data: [data, setData],
    state: [state, setState],
  } = useContext(SectionContext);
  const [excel, setExcel] = React.useState(null);
  const handleRemove = (remove) => () => {
    Popup.remove({
      title: "Remove Student",
      text: `Do you want to remove "${remove.studentID}"?`,
      icon: "trash",
      onConfirm: () => {
        if (secid && remove?.studentID) {
          setState((s) => ({ ...s, fetched: false }));
          setData((s) =>
            update(s, {
              studentSec: {
                $apply: (studentSec) => {
                  console.log(studentSec);
                  return studentSec.filter(
                    (student) => student.studentID !== remove.studentID
                  );
                },
              },
            })
          );
          SectionStudent.remove(secid, remove.studentID);
          setState((s) => ({ ...s, fetched: true }));
          addAlert({ label: "Delete Success." });
        }
      },
    });
  };
  const handleImportSection = async (res) => {
    await control.Importexcel(res);
    setExcel(null);
  };

  return (
    <Box py={6}>
      <Container maxWidth="sm">
        <ContentHeader
          label={data.section.title}
          breadcrumbs={[
            { label: t("Home"), to: "/" },
            { label: t("Courses.MyCourse"), to: "/" },
            { label: t("Section"), to: `/edit/${id}/section` },
            { label: t("Student") },
          ]}
          actions={
            <React.Fragment>
              <ImportButton sectionId={secid} />
              &nbsp;
              <DialogAdd />
            </React.Fragment>
          }
        />
        <Box sx={{ height: 400 }}>
          <TableDocs
            density="compact"
            loading={!Boolean(state?.fetched)}
            rows={data.studentSec}
            columns={[
              {
                field: "action",
                headerName: " ",
                renderCell: ({ row }) => (
                  <ActionIcon
                    icon={faTrash}
                    color="secondary"
                    onClick={handleRemove(row)}
                  />
                ),
                width: 36,
                align: "center",
                filterable: false,
                sortable: false,
              },
              { field: "studentID", headerName: "ID", width: 120 },
              { field: "studentName", headerName: "Name", width: 240 },
            ]}
            checkboxSelection
          />
        </Box>
        <DialogExcel
          data={excel}
          open={Boolean(excel)}
          onClose={() => setExcel(null)}
          onImport={(res) => handleImportSection(res)}
        />
      </Container>
    </Box>
  );
});
