import { QuizDisplay } from "@knowgistics/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { useContext, useState } from "react";
import { QuestionBankEditContext } from "./context";

export const ViewQuiz = ({ data }) => {
  const { state: states } = useContext(QuestionBankEditContext);
  const [state, setState] = states;
  const [check, setCheck] = useState(null);

  const handleChange = (check) => setCheck(check);
  const handleClose = () => setState((s) => ({ ...s, show: null }));

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={Boolean(state.show)}
      onClose={handleClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "down" }}
    >
      <DialogTitle>View Question</DialogTitle>
      <DialogContent>
        <QuizDisplay
          value={state.show}
          checked={check}
          onCheckChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
