import { DialogPre, KuiActionIcon } from "@knowgistics/core";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useState } from "react";
import { roleLabels } from ".";
import pt from "prop-types";

export const TeacherChangeRole = ({ value: defaultValue, onConfirm }) => {
  const [value, setValue] = useState(defaultValue || "teacher");

  const handleChange = ({ target: { value } }) => setValue(value);
  const handleConfirm = () => onConfirm?.(value);

  return (
    <DialogPre
      title="Change Role"
      button={<KuiActionIcon tx="edit" />}
      onConfirm={handleConfirm}
      confirmButtonProps={{
        disabled: value === defaultValue,
      }}
    >
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Role</InputLabel>
        <Select
          labelWidth={36}
          value={value ?? "teacher"}
          onChange={handleChange}
        >
          {["owner", "teacher"].map((role) => (
            <MenuItem key={role} value={role}>
              {roleLabels?.[role] ?? "Teacher"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </DialogPre>
  );
};
TeacherChangeRole.propTypes = {
  value: pt.string.isRequired,
  onConfirm: pt.func.isRequired,
};
