export const categories = [
  "การเงินและบัญชี",
  "การพัฒนา",
  "ธุรกิจ",
  "ไอทีและซอฟต์แวร์",
  "การทำงานในสำนักงาน",
  "การพัฒนาตนเอง",
  "การออกแบบ",
  "การตลาด",
  "ไลฟ์สไตล์",
  "การถ่ายภาพและวีดิโอ",
  "สุขภาพและความแข็งแรง",
  "ดนตรี",
  "การสอนและวิชาการ",
  "อื่นๆ",
];