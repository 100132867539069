import { faMinus, faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  DialogPre,
  DuotoneButton,
  KGContext,
  TableDocs,
} from "@knowgistics/core";
import { Button } from "@material-ui/core";
import { useContext } from "react";
import { SectionContext } from "../SectionEdit/context";

export const DialogAdd = (props) => {
  const { addAlert,t } = useContext(KGContext);
  const {
    control,
    state: [state, setState],
    data: [data, setData],
  } = useContext(SectionContext);
  const configAddStudent = {
    onChangeChecked: (checked) => console.log(checked),
    checkbox: true,
    fetched: state.fetched,
    search: ["studentID", "studentName"],
    docs: data.student,
    rowId: "id",
    defaultSort: "edit",
    defaultOrder: "desc",
    columns: [
      { label: "ID", id: "studentID", width: 20, sortable: true },
      { label: "Name", id: "studentName", width: 75, sortable: true },
      {
        id: "action",
        value: (row) => {
          return (
            <>
              {data.selected.map((std) => std.id).includes(row.id) ? (
                <ActionIcon
                  icon={faMinus}
                  color="secondary"
                  onClick={handleChangeSelected(row.id)}
                />
              ) : (
                <ActionIcon
                  icon={faPlus}
                  color="primary"
                  onClick={handleChangeSelected(row.id)}
                />
              )}
            </>
          );
        },
        width: 5,
        padding: "none",
      },
    ],
  };

  const handleAdd = (stdList) => () => {
    setData((s) => ({ ...s, selected: stdList }));
  };
  const handleConfirm = () => {
    setState((s) => ({ ...s, fetched: false }));
    setData((s) => {
      const selected = [...s.selected];
      const updateData = [...new Set(selected.map((doc) => doc.studentID))];
      control.update({ students: updateData });
      return { ...s, studentSec: selected };
    });
    setState((s) => ({ ...s, fetched: true }));
    addAlert({ label: "Update Success." });
  };
  const handleChangeSelected = (id) => () =>
    setData((s) => {
      let selected = [...s.selected];
      let stdData = [...s.student];
      if (selected.map((std) => std.id).includes(id)) {
        const index = selected.map((stdIndex) => stdIndex.id).indexOf(id);
        selected.splice(index, 1);
      } else {
        selected.push(stdData.filter((doc) => doc.id === id)[0]);
      }
      return { ...s, selected: selected };
    });

  return (
    <DialogPre
      title={t("Student")}
      maxWidth="sm"
      label="Input Label"
      button={
        <DuotoneButton
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          onMouseUp={handleAdd(data.studentSec)}
        >
          {t("Add")}
        </DuotoneButton>
      }
      secondaryActions={
        <Button
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          href={"/manage/student"}
          target={"_blank"}
        >
          {t("Create")}
        </Button>
      }
      onConfirm={handleConfirm}
    >
      <TableDocs {...configAddStudent} />
    </DialogPre>
  );
};
