import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  Paragraph,
  ImageDisplay,
  DialogAlert,
} from "@knowgistics/core";
import { forwardRef } from "react";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ImageRatio, ImageWrapper } from "./Components";
import { Link } from "react-router-dom";

const {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogContent,
  Slide,
  Box,
  Typography,
  withStyles,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
} = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "white",
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[0],
  },
}));

const ListItemChoice = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    border: "solid 2px " + theme.palette.success.main,
  },
}))(ListItem);
const ListItemChoiceworng = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    border: "solid 2px " + theme.palette.error.main,
  },
}))(ListItem);

const ShowOption = ({ option }) => {
  if (!option) {
    return null;
  }

  switch (option.type) {
    case "paragraph":
      return <Paragraph value={option.value} />;
    case "image":
      return (
        <ImageDisplay
          image={option.data}
          mb={1}
          style={{
            width: "100%",
            maxWidth: 256,
            marginBottom: 24,
          }}
        />
      );
    default:
      return null;
  }
};

const Transition = forwardRef((props, ref) => (
  <Slide ref={ref} direction="up" {...props} />
));

const CheckAnswerView = ({ selected, id, parentID, data, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = (open) => () => setOpen(open);
  return (
    <>
      <DialogAlert
        title="ส่งคำตอบ"
        label="คุณยืนยันที่จะส่งคำตอบใช่หรือไม่ ?"
        confirmLabel="Ok"
        onConfirm={() => {
          setOpen(true);
        }}
      >
        <Button
          variant="contained"
          disableElevation
          color="primary"
          //disabled={props.disabled}
        >
          ตรวจคำตอบ
        </Button>
      </DialogAlert>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} position="fixed" color="default">
          <Toolbar>
            <Box flexGrow={1} />
            <IconButton
              color="inherit"
              edge="end"
              onClick={handleOpen(false)}
              component={Link}
              to={`/question/edit/${data.parent}/${parentID}`}
            >
              <FontAwesomeIcon size="2x" icon={faTimesCircle} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box mt={5} />
          <Container maxWidth="sm">
            <Typography variant="h6">
              {data.qtype === "image" ? (
                <>
                  <ImageDisplay id={data.question[0]} />
                </>
              ) : (
                <Paragraph value={data.question} />
              )}
            </Typography>
            <Box mt={1} />
            <Box>
              {() => {
                switch (data.type) {
                  case "truefalse":
                    if (selected[id] === data.answers) {
                      return (
                        <ListItemChoice>
                          <ListItemText
                            style={{ lineHeight: 1.2 }}
                            primary="True"
                          />
                        </ListItemChoice>
                      );
                    } else {
                      return (
                        <>
                          <ListItemChoiceworng>
                            <ListItemText
                              style={{ lineHeight: 1.2 }}
                              primary="False"
                            />
                          </ListItemChoiceworng>
                        </>
                      );
                    }
                  case "multiple":
                    if (selected[id] === data.answer) {
                      const [rightOption] = data.options.filter(
                        (o) => `${o.key}` === `${data.answer}`
                      );
                      return (
                        <ListItemChoice>
                          <ListItemText style={{ lineHeight: 1.2 }}>
                            <ShowOption option={rightOption} />
                          </ListItemText>
                        </ListItemChoice>
                      );
                    } else {
                      const [rightOption] = data.options.filter(
                        (o) => `${o.key}` === `${data.answer}`
                      );
                      const [wrongOption] = data.options.filter(
                        (o) => `${o.key}` === `${selected[id]}`
                      );

                      return (
                        <>
                          <ListItemChoiceworng>
                            <ListItemText style={{ lineHeight: 1.2 }}>
                              <ShowOption option={wrongOption} />
                            </ListItemText>
                          </ListItemChoiceworng>
                          <Box mt={1} />
                          <ListItemChoice>
                            <ListItemText style={{ lineHeight: 1.2 }}>
                              <ShowOption option={rightOption} />
                            </ListItemText>
                          </ListItemChoice>
                        </>
                      );
                    }
                  case "matching":
                    return (
                      <Table>
                        <TableBody>
                          {selected[id] &&
                            data.answers &&
                            data.answers.map((ans) => (
                              <TableRow key={ans.key}>
                                <TableCell>
                                  {(() => {
                                    switch (ans.type) {
                                      case "paragraph":
                                        return <Paragraph value={ans.label} />;
                                      case "image":
                                        return (
                                          <ImageWrapper
                                            item
                                            md={3}
                                            sm={4}
                                            xs={6}
                                            style={{ position: "relative" }}
                                          >
                                            <ImageRatio>
                                              <ImageDisplay image={ans.data} />
                                            </ImageRatio>
                                          </ImageWrapper>
                                        );
                                      default:
                                        return null;
                                    }
                                  })()}
                                </TableCell>
                                <TableCell>
                                  {selected[id][ans.key] === ans.key ? (
                                    <ListItemChoice>
                                      <ListItemText style={{ lineHeight: 1.2 }}>
                                        {ans.value}
                                      </ListItemText>
                                    </ListItemChoice>
                                  ) : (
                                    <>
                                      <ListItemChoiceworng>
                                        <ListItemText
                                          style={{ lineHeight: 1.2 }}
                                        >
                                          {
                                            (() => {
                                              const result = data.answers.find(
                                                (ansx) =>
                                                  `${ansx.key}` ===
                                                  `${selected[id][ans.key]}`
                                              );
                                              return result && result.value;
                                            })()
                                            //selected[vid][ans.key]
                                          }
                                        </ListItemText>
                                      </ListItemChoiceworng>
                                      <Box mt={1} />
                                      <ListItemChoice>
                                        <ListItemText
                                          style={{ lineHeight: 1.2 }}
                                        >
                                          {ans.value}
                                        </ListItemText>
                                      </ListItemChoice>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    );
                  default:
                    return null;
                }
              }}
            </Box>
          </Container>
          <Box mb={6} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CheckAnswerView;
