import { faChevronDown, faCog } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DialogPrompt,
  DialogRemove,
  DuotoneButton,
  KuiActionIcon,
  KuiButton,
  KuiListItemText,
} from "@knowgistics/core";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemSecondaryAction,
  Slide,
  withStyles,
  Box,
} from "@material-ui/core";
import { getTypeIcon } from "main.comp";
import { Fragment, useState } from "react";
import { injectGrading } from "./ctx";
import { DialogAddField, visibilities } from "./dialog.add.field";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
    ...theme.palette.duotone,
  },
  content: {
    alignItems: "center",
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

const expandedIcon = <FontAwesomeIcon icon={faChevronDown} size="xs" />;

const isHas = (type, ...fields) =>
  fields.map((f) => type.includes(f)).filter((t) => t).length > 0;

export const GradingSetting = injectGrading(
  ({
    store: {
      states: [state, setState],
      control,
      t,
    },
    ...props
  }) => {
    const [open, setOpen] = useState(false);
    const [remove, setRemove] = useState(null);
    const [expanded, setExpanded] = useState(null);

    const handleOpen = (o) => () => setOpen(o);
    const handleExpand = (id) => (e, n) => setExpanded(n ? id : null);
    const handleHeaderChange = (id, field) => async (value) => {
      await control.header.edit({ id, field, value });
    };
    const handleRemove = (remove) => () => setRemove(remove);
    const handleRemoveConfirm = async () => {
      await control.header.remove(remove?.id);
      setRemove(null);
    };

    return (
      <>
        <DuotoneButton icon={faCog} onClick={handleOpen(true)}>
          {t("Setting")}
        </DuotoneButton>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleOpen(false)}
          TransitionComponent={Slide}
          TransitionProps={{ direction: "left" }}
        >
          <DialogTitle>{t("Setting")}</DialogTitle>
          <DialogContent>
            {state?.fields?.map((f) => (
              <Accordion
                expanded={f?.id === expanded}
                onChange={handleExpand(f?.id)}
                key={f?.id}
              >
                <AccordionSummary expandIcon={expandedIcon}>
                  {getTypeIcon(f?.type, { style: { marginRight: 8 } })}
                  {f?.title}
                </AccordionSummary>
                <AccordionDetails>
                  <List style={{ width: "100%" }}>
                    {isHas(f?.type, "custom-score") && (
                      <ListItem dense>
                        <KuiListItemText
                          tx="body"
                          primary={`${t("Title")}: ${f?.title}`}
                        />
                        <ListItemSecondaryAction>
                          <DialogPrompt
                            title={t("Edit")}
                            label={t("Title")}
                            value={f?.title}
                            onConfirm={handleHeaderChange(f?.id, "title")}
                          >
                            <KuiActionIcon tx="edit" />
                          </DialogPrompt>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                    <ListItem dense>
                      <KuiListItemText
                        tx="body"
                        primary={`${t("Weight")}: ${
                          f?.weight ?? control.dfWeight
                        }`}
                      />
                      <ListItemSecondaryAction>
                        <DialogPrompt
                          title={t("Edit")}
                          label={t("Weight")}
                          value={`${f?.weight ?? control.dfWeight}`}
                          onConfirm={handleHeaderChange(f?.id, "weight")}
                        >
                          <KuiActionIcon tx="edit" />
                        </DialogPrompt>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {isHas(f?.type, "custom-score", "subjective") && (
                      <ListItem dense>
                        <KuiListItemText
                          tx="body"
                          primary={`${t("Full")}: ${f?.full ?? "0"}`}
                        />
                        <ListItemSecondaryAction>
                          <DialogPrompt
                            title={t("Edit")}
                            label={t("Full")}
                            value={f?.full}
                            onConfirm={handleHeaderChange(f?.id, "full")}
                          >
                            <KuiActionIcon tx="edit" />
                          </DialogPrompt>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                    {isHas(f?.type, "custom-score") && (
                      <Fragment>
                        <ListItem dense>
                          <KuiListItemText
                            tx="body"
                            primary={`${t("Visibility")}: ${t(
                              visibilities.find((v) => v?.value === f?.type)
                                ?.label ?? "Public"
                            )}`}
                          />
                          <ListItemSecondaryAction>
                            <KuiActionIcon tx="edit" />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Box textAlign="right" px={2} py={1}>
                          <KuiButton
                            tx="remove"
                            size="small"
                            variant="outlined"
                            onClick={handleRemove(f)}
                          />
                        </Box>
                      </Fragment>
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </DialogContent>
          <DialogActions>
            <DialogAddField />
            <Box flex={1} />
            <Button onClick={handleOpen(false)}>{t("Close")}</Button>
          </DialogActions>
        </Dialog>
        <DialogRemove
          title={t("Remove")}
          label={t("Do you want to remove", { name: remove?.title })}
          open={Boolean(remove)}
          onClose={handleRemove(null)}
          onConfirm={handleRemoveConfirm}
        />
      </>
    );
  }
);
