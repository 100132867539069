import { firebase, db } from "Controller/firebase";

export class SectionController {
    constructor(user, id) {
        this.user = user;
        this.id = id;
    }
    watch = (callback) => {
        return db.collection("sections")
            .where("parent", "==", `${this.id}`)
            .onSnapshot((snapshot) => {
                const docs = snapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                })).sort((a, b) => {
                    const getDate = date => Boolean(date && date.datemodified && date.datemodified.toMillis) ? date.datemodified.toMillis() : Date.now();
                    return (getDate(b) - getDate(a));
                });
                callback(docs);
            });
    };
    watchstaff = (callback) => {
        return db.collection("sections")
            .where("ta", "array-contains", this.user.uid)
            .onSnapshot((snapshot) => {
                const docs = snapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                })).sort((a, b) => {
                    const getDate = date => Boolean(date && date.datemodified && date.datemodified.toMillis) ? date.datemodified.toMillis() : Date.now();
                    return (getDate(b) - getDate(a));
                });
                callback(docs);
            });
    };
    add = async () => {
        return await db.collection("sections")
            .add({
                title: 'Untitled',
                datecreate: firebase.firestore.FieldValue.serverTimestamp(),
                datemodified: firebase.firestore.FieldValue.serverTimestamp(),
                user: this.user.uid,
                parent: this.id,
                type: "section",
                prefix: process.env.REACT_APP_PREFIX,
            });
    }
    remove = async (id) => {
        return await db.collection("sections")
            .doc(id)
            .delete();
    }
}