import { KGContext } from "@knowgistics/core";
import { createContext, useContext, useEffect, useState } from "react";
import { firebase } from "Controller/firebase";

class StudentControl {
  constructor({ user, addAlert }) {
    this.user = user;
    this.addAlert = addAlert;
  }
  watch = (callback) => {
    firebase
      .firestore()
      .collection("students")
      .where("prefix", "==", process.env.REACT_APP_PREFIX)
      .onSnapshot((snap) => {
        const docs = snap.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        callback(docs);
      });
  };
  add = (data) => {
    firebase
      .firestore()
      .collection("students")
      .doc(data.studentID)
      .set({
        ...data,
        prefix: process.env.REACT_APP_PREFIX,
      });
  };
  update = (data) => {
    firebase
      .firestore()
      .collection("students")
      .doc(data.id)
      .update({
        ...data,
      });
  };
  remove = (id) => {
    firebase.firestore().collection("students").doc(id).delete();
  };
  selectedremove = (data) => {
    if (Array.isArray(data)) {
      data.map((docid) => {
        return firebase.firestore().collection("students").doc(docid).delete();
      });
    }
  };
  Importexcel = async (data) => {
    if (Array.isArray(data)) {
      // student ID length must be 11 digit
      const filteredData = data
        .map((row) => row.map((cell) => cell.toString().trim()))
        .filter((row) => row.length > 1 && row[1].length > 8);
      if (filteredData.length) {
        const batch = firebase.firestore().batch();
        filteredData.map((row) =>
          batch.set(
            firebase.firestore().collection("students").doc(row[1]),
            {
              studentName: row[2],
              studentID: row[1],
              prefix: process.env.REACT_APP_PREFIX,
            },
            { merge: true }
          )
        );
        await batch.commit();
        this.addAlert({ label: "Import data success.", severity: "success" });
      } else {
        this.addAlert({ label: "No valid data to import.", severity: "error" });
      }
    } else {
      this.addAlert({ label: "Invalid data.", severity: "error" });
    }
  };
}

export const ManageStudentContext = createContext({});

const ManageStudentProvider = ({ children, ...props }) => {
  const { user, addAlert, t } = useContext(KGContext);
  const [control, setControl] = useState(null);
  const [state, setState] = useState({
    fetched: false,
  });
  const [data, setData] = useState({
    student: [],
  });
  const store = {
    ...props,
    ...props.match.params,
    control,
    data: [data, setData],
    state: [state, setState],
  };
  useEffect(() => {
    document.title = `${t("Courses.ManageStudent")} | ${
      process.env.REACT_APP_SITE_NAME
    }`;
    if (user) {
      const control = new StudentControl({ user, addAlert });
      setControl(control);
      return control.watch((data) => {
        setState((s) => ({ ...s, fetched: true }));
        setData({ student: data });
      });
    }
  }, [user, store.id, addAlert, t]);

  return (
    <ManageStudentContext.Provider value={store}>
      {children}
    </ManageStudentContext.Provider>
  );
};

export const connectManageStudent = (Comp) => (props) =>
  (
    <ManageStudentProvider {...props}>
      <Comp {...props} />
    </ManageStudentProvider>
  );
