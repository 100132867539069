import { KGContext } from "@knowgistics/core";
import { createContext, useContext, useEffect, useState } from "react";
import { firebase, db } from "Controller/firebase";

const CTLQuestion = {
  labels: {
    multiple: "Multiple Choice",
    truefalse: "True / False",
    matching: "Matching",
    sorting: "Sorting",
  },
};

class CourseControl {
  constructor({ user, id }) {
    this.user = user;
    this.id = id;
  }
  watch = (callback) => {
    return firebase
      .firestore()
      .collection("questions")
      .doc(this.id)
      .onSnapshot((snap) => {
        callback({ ...snap.data() });
      });
  };
  update = async (data) => {
    await firebase
      .firestore()
      .collection("questions")
      .doc(this.id)
      .update({
        ...data,
        datemodified: firebase.firestore.FieldValue.serverTimestamp(),
      });
  };
}

export const QuestionBankEditContext = createContext({});

const QuestionBankEditProvider = ({ children, ...props }) => {
  const { user } = useContext(KGContext);
  const [control, setControl] = useState(null);

  const [state, setState] = useState({
    fetched: false,
    remove: null,
    docs: [],
    show: null,
  });
  const [data, setData] = useState({});

  const store = {
    ...props,
    ...props.match.params,
    controller: CTLQuestion,
    control,
    data: [data, setData],
    state: [state, setState],
    handle: {
      remove: async () => {
        if (state.remove) {
          await db
            .collection("questions")
            .doc(state.remove.id)
            .delete();
          setState((s) => ({ ...s, remove: null }));
        }
      },
      removeSelected: async () => {
        const proms = state.selected.map(
          async (id) =>
            await db
              .collection("questions")
              .doc(id)
              .delete()
        );
        await Promise.all(proms);
        setState((s) => ({ ...s, selected: [], removeSelected: false }));
      },
    },
  };

  useEffect(() => {
    document.title = `Edit — QuestionBank | ${process.env.REACT_APP_SITE_NAME}`;
    if (user) {
      const control = new CourseControl({ user, id: store.id });
      setControl(control);
      return control.watch((data) => {
        setState((s) => ({ ...s, fetched: true }));
        setData(data);
      });
    }
  }, [user, store.id]);

  useEffect(() => {
    let unwatch = () => false;
    if (user) {
      unwatch = db
        .collection("questions")
        .where("user", "==", user.uid)
        .where("parent", "==", store.id)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setState((s) => ({ ...s, docs, fetched: true }));
        });
    }
    return unwatch;
  }, [user, store.id]);

  return (
    <QuestionBankEditContext.Provider value={store}>
      {children}
    </QuestionBankEditContext.Provider>
  );
};

export const connectQuestionBankEdit = (Comp) => (props) =>
  (
    <QuestionBankEditProvider {...props}>
      <Comp {...props} />
    </QuestionBankEditProvider>
  );
