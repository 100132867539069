import { ContentEdit, KGContext } from "@knowgistics/core";
import { createContext, useContext, useEffect, useState } from "react";
import { firebase } from "Controller/firebase";

class Control {
  constructor(user, id) {
    this.user = user;
    this.id = id;
  }
  get = async () => {
    const snapshot = await firebase
      .firestore()
      .collection("courses")
      .doc(this.id)
      .get();
    const home =
      snapshot.data() && snapshot.data().home ? snapshot.data().home : {};
    return home;
  };
  save = async (data) => {
    await firebase.firestore().collection("courses").doc(this.id).update({
      home: data,
      datemodified: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return true;
  };
}
const Context = createContext({});
const Provider = ({ children, ...props }) => {
  const {
    t,
    user,
    state: [{ userFetched }],
  } = useContext(KGContext);
  const [control, setControl] = useState();
  const [data, setData] = useState({});
  const store = {
    ...props,
    ...props.match.params,
    control,
    datas: [data, setData],
    t,
  };
  useEffect(() => {
    if (userFetched && store.id) {
      if (user) {
        const control = new Control(user, store.id);
        setControl(control);
        control.get().then((home) => setData(home));
      }
    }
  }, [user, userFetched, store.id]);
  return <Context.Provider value={store}>{children}</Context.Provider>;
};
export const connect = (Comp) => (props) =>
  (
    <Provider {...props}>
      <Comp {...props} />
    </Provider>
  );
export const inject = (Comp) => (props) => {
  const store = useContext(Context);
  return <Comp {...props} store={store} />;
};

export const PageCourseHome = connect(
  inject(({ store: { t, control, datas, id, ...store }, ...props }) => {
    const [data, setData] = datas;
    const config = {
      prefix: "cu-",
      back: `/edit/${id}/`,
      show: [
        "heading",
        "paragraph",
        "vdo",
        "image",
        "table",
        "file",
        "divider",
      ],
    };

    const handleSave = async ({ title, ...data }) => {
      setData(data);
      await control.save(data);
    };

    return (
      <>
        <ContentEdit
          data={{ title: t("Home"), ...data }}
          onSave={handleSave}
          config={config}
          post
        />
      </>
    );
  })
);
