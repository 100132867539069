import { React } from "react";
import { Container, ContentHeader, MainContainer, Sortable, SortListItem } from "@knowgistics/core";
import { Box, Divider, ListItemText } from "@material-ui/core";
import { Sidebar } from "./Sidebar";

const QuestionSortingEdit = (props) => {

    const handleSortEnd = () => {

    }

  return (
    <MainContainer dense signInOnly sidebar={<Sidebar />}>
      <Box py={6}>
        <Container maxWidth="sm">
          <ContentHeader label="Sorting" breadcrumbs={[{ label: "Home" }]} />
          <Box my={5} children={<Divider />} />
          <Sortable
            divider
            docs={[1,2,3,4]}
            component={(doc, index) => (
              <SortListItem
                index={index}
                key={doc}
                listItemProps={{ divider: true }}
              >
                <ListItemText primary={doc} />
              </SortListItem>
            )}
            onSortEnd={handleSortEnd}
          />
        </Container>
      </Box>
    </MainContainer>
  );
};
export default QuestionSortingEdit;
