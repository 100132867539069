import { MainCtl } from "./mainctl";

export class SectionStudent extends MainCtl {
  static remove(sectionId: string, studentId: string) {
    this.db()
      .collection("sections")
      .doc(sectionId)
      .update({ students: this.arrayRemove(studentId) });
  }
}
