import { Chat, KGContext, getStudentsInfo } from "@knowgistics/core";
import React, { useContext, useEffect, useState } from "react";
import { db } from "Controller/firebase";

class Controller {
  constructor(user, id, secId) {
    this.user = user;
    this.id = id;
    this.secId = secId;
    this.prefix = process.env.REACT_APP_PREFIX;
  }
  get = (callback) => {
    db.collection("sections")
      .doc(this.secId)
      .get()
      .then(async (snap) => {
        const section = snap.data();
        const students = await getStudentsInfo(
          section?.students || [],
          this.prefix
        );
        const contacts = section?.students
          ?.map((id) => {
            return students[id]
              ? {
                  uid: students[id]?.studentID,
                  displayName: students[id]?.studentName,
                }
              : null;
          })
          .filter((u) => Boolean(u));
        callback({ section, students, contacts });
      });
  };
}

export const PageChat = (props) => {
  const { user } = useContext(KGContext);
  const { id, secid, studentid } = props.match.params;
  const [, setControl] = useState(null);
  const [state, setState] = useState({
    fetched: false,
    open: null,
  });

  useEffect(() => {
    if (user) {
      const control = new Controller(user, id, secid);
      setControl(control);
      return control.get(({ count, ...data }) => {
        setState((s) => ({ ...s, ...data, fetched: count >= 5 }));
      });
    }
  }, [user, id, secid]);
  return (
    <Chat
      back={props.back ? `${props.back}/${id}/${secid}/`  : `/edit/${id}/section/${secid}/student`}
      contacts={state?.contacts ?? []}
      baseURL={`/edit/${id}/section/${secid}/chat`}
      as={id}
      view={studentid}
    />
  );
};
