import React, { useContext } from "react";
import {
  Container,
  ContentHeader,
  MainContainer,
  ImageDisplay,
  KGContext,
  Paragraph,
  DialogRemove,
} from "@knowgistics/core";
import { Box, Divider, Toolbar, Typography, Select, MenuItem, Button, IconButton } from "@material-ui/core";
import { Sidebar } from "./Sidebar";
import { AddButton, Heading, OptionsBox } from "../components";
import { connectContext, MultipleContext, newOptions } from "./Context";
import { Skeleton } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { ImagePicker } from "@knowgistics/imagepicker";
import { firebaseConfig } from "Controller/firebase";

const QuestionmultipleEdit = (props) => {
  const { user } = useContext(KGContext);
  const store = useContext(MultipleContext);
  const [data, setData] = store.data;
  const [state, setState] = store.state;

  const handleChange = (key) => (e) => {
    if (e) {
      if(key==="question" && data.qtype === "image" && e.length){
        const { content } = e[0];
        setData((d) => ({
          ...d,
          "question": { ...content, id:e[0].id },
        }));
      } else if (key === "qtype") {
        setData((d) => ({
          ...d,
          [key]: e.target ? e.target.value : e,
          question: null,
        }));
      } else {
        setData((d) => ({ ...d, [key]: e.target ? e.target.value : e }));
      }
    }
  };
  const handleTypeChange = (index) => (ev) =>
    setData((d) => {
      let options = [...d.options];
      options[index].type = ev.target.value;
      return { ...d, options };
    });
  const handleAnswerChange = (index) => (value) => {
    setData((data) => {
      let options = [...data.options];
      options[index].value = value;
      return { ...data, options };
    });
  };
  const handleChangeImage =
    (index) =>
    ([id]) =>
      setData((d) => {
        let lists = [...d.options];
        lists[index].data = {
          ...id.content,
          id: id.id,
        };
        return { ...d, lists };
      });
  const handleAddOptions = () => {
    setData((data) => {
      let lists = [...data.options];
      lists.push(newOptions());
      return { ...data, options: lists };
    });
  };
  const handleRemoveOptions = (remove) => () =>
    setState((s) => ({ ...s, remove }));
  const handleRemoveOptionsConfirm = () =>
    setData((d) => {
      let lists = [...d.options];
      lists.splice(state.remove, 1);
      setState((s) => ({ ...s, remove: -1 }));
      return { ...d, options: lists };
    });

  return (
    <MainContainer dense signInOnly sidebar={<Sidebar />}>
      <Box py={6}>
        <Container maxWidth="sm">
          <ContentHeader label="Multiple" breadcrumbs={[{ label: "Home" }]} />
          <Heading>Question</Heading>
          <Box display="flex" alignItems="center" mb={1}>
            <Box flexGrow={1} />
            <Typography variant="caption" color="textSecondary">
              Type:&nbsp;
            </Typography>
            <Select
              value={data.qtype || "paragraph"}
              onChange={handleChange("qtype")}
            >
              <MenuItem value="paragraph">Paragraph</MenuItem>
              <MenuItem value="image">Image</MenuItem>
            </Select>
          </Box>
          {(() => {
            switch (data.qtype) {
              case "paragraph":
                return state.fetching ? (
                  <Skeleton width="50%" />
                ) : (
                  <Paragraph
                    editOnly
                    value={data.question}
                    onChange={handleChange("question")}
                  />
                );
              case "image":
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {data.question && (
                      <ImageDisplay
                        image={data.question}
                        mb={1}
                        style={{
                          width: "100%",
                          maxWidth: 256,
                          marginBottom: 24,
                        }}
                      />
                    )}
                    {!state.fetching && (
                      <ImagePicker
                        user={user}
                        config={firebaseConfig}
                        //onConfirm={handleChangeImage(index)}
                        onConfirm={handleChange("question")}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                        >
                          Browse
                        </Button>
                      </ImagePicker>
                    )}
                  </Box>
                );
              default:
                return state.fetching ? (
                  <Skeleton width="50%" />
                ) : (
                  <Paragraph
                    editOnly
                    value={data.question}
                    onChange={handleChange("question")}
                  />
                );
            }
          })()}
          <Box my={5} children={<Divider />} />
          {data.options.map((opt, index) => (
            <OptionsBox key={opt.key}>
              <Toolbar>
                <Typography variant="h5">
                  Choice {index + 1}
                </Typography>
                <Box flexGrow={1} />
                {data.options.length > 2 && (
                  <IconButton
                    color="secondary"
                    size="small"
                    edge="end"
                    onClick={handleRemoveOptions(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                )}
              </Toolbar>
              <Divider />
              <Box p={3}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                >
                  <Box flexGrow={1} />
                  <Typography variant="caption" color="textSecondary">
                    Type:&nbsp;
                  </Typography>
                  <Select
                    value={opt.type}
                    onChange={handleTypeChange(index)}
                  >
                    <MenuItem value="paragraph">Paragraph</MenuItem>
                    <MenuItem value="image">Image</MenuItem>
                  </Select>
                </div>

                {(() => {
                  switch (opt.type) {
                    case "paragraph":
                      return state.fetching ? (
                        <Skeleton width="50%" />
                      ) : (
                        <Paragraph
                          editOnly
                          value={opt.value}
                          onChange={handleAnswerChange(index)}
                        />
                      );
                    case "image":
                      return (
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          {opt.data.id && (
                            <ImageDisplay
                              image={opt.data}
                              mb={1}
                              id={opt.data.id}
                              style={{
                                width: "100%",
                                maxWidth: 256,
                                marginBottom: 24,
                              }}
                            />
                          )}
                          {!state.fetching && (
                            <ImagePicker
                              user={user}
                              config={firebaseConfig}
                              onConfirm={handleChangeImage(index)}
                            >
                              <Button
                                variant="outlined"
                                startIcon={
                                  <FontAwesomeIcon icon={faFolderOpen} />
                                }
                              >
                                Browse
                              </Button>
                            </ImagePicker>
                          )}
                        </Box>
                      );
                    default:
                      return null;
                  }
                })()}
              </Box>
            </OptionsBox>
          ))}
          <Box my={5} children={<Divider />} />
          <OptionsBox>
            <Toolbar>
              <Typography variant="h5">Answer</Typography>
              <Box flexGrow={1} />
            </Toolbar>
            <Divider />
            <Box p={3}>
              <Box display="flex" alignItems="center" mb={1}>
                {state.fetching ? (
                  <Skeleton width="50%" />
                ) : (
                  <Select
                    fullWidth
                    value={data.answer || ""}
                    onChange={handleChange("answer")}
                  >
                    {data.options.map((lists, index) => (
                      <MenuItem key={lists.key} value={lists.key}>
                        {`Choice ${index + 1}`}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
            </Box>
          </OptionsBox>
          <AddButton onClick={handleAddOptions} />
          <DialogRemove
            title="Remove Answer"
            label={state.remove ? <>Do you want to remove ?</> : null}
            open={state.remove > -1}
            onClose={handleRemoveOptions(-1)}
            onConfirm={handleRemoveOptionsConfirm}
          />
        </Container>
      </Box>
    </MainContainer>
  );
};

export default connectContext(QuestionmultipleEdit);
