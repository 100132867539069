import { FeatureImage, KGContext, MainContainer } from "@knowgistics/core";
import { useContext, useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { QuestionBank } from "../QuestionBank";
import { PageCourseDesc } from "../CourseDesc";
import { CourseControl, CourseEditContext } from "./context";
import { Sidebar } from "./Sidebar";
import { PageLesson } from "../Lesson";
import { PageSection } from "../Section";
import { CourseDocument } from "Controller/courseController.t";

export const PageCourseEdit = (props: RouteComponentProps<{ id: string, tab?:string }>) => {
  const { id, tab } = props.match.params;
  const { user } = useContext(KGContext);
  const [control, setControl] = useState<CourseControl>();

  const [state, setState] = useState({
    fetched: false,
  });
  const [data, setData] = useState<CourseDocument>();

  const store = {
    id,
    tab,
    control,
    data,
    setData,
    state,
    setState,
  };

  useEffect(() => {
    document.title = `Course Manager | ${process.env.REACT_APP_SITE_NAME}`;

    if (user && store.id) {
      const control = new CourseControl({ user, id: store.id });
      setControl(control);
      return control.watch((data) => {
        setState((s) => ({ ...s, fetched: true }));
        setData(data);
      });
    }
  }, [user, store.id]);

  return (
    <CourseEditContext.Provider value={store}>
      <MainContainer dense signInOnly sidebar={<Sidebar />}>
        {Boolean(data?.cover) && <FeatureImage image={data?.cover} />}
        <Switch>
          <Route path="/edit/:id/section" component={PageSection} />
          <Route path="/edit/:id/lesson" component={PageLesson} />
          <Route path="/edit/:id/question" component={QuestionBank} />
          <Route path="/" component={PageCourseDesc} />
        </Switch>
      </MainContainer>
    </CourseEditContext.Provider>
  );
};
