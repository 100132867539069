import { firebase } from "Controller/firebase";

export class MainCtl {
  static prefix: string = `${process.env.REACT_APP_PREFIX || ""}`;

  protected static db(): firebase.firestore.Firestore {
    return firebase.firestore();
  }
  protected static arrayUnion = (value: any) =>
    firebase.firestore.FieldValue.arrayUnion(value);
  protected static arrayRemove = (value: any) =>
    firebase.firestore.FieldValue.arrayRemove(value);

  protected static toDoc<T extends unknown>(
    doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
  ): T {
    return { ...doc.data(), id: doc.id } as T;
  }
}
