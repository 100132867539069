import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  ContentHeader,
  DialogRemove,
  KuiActionIcon,
  MainContainer,
} from "@knowgistics/core";
import { IconButton, Link, Tooltip } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { TAAdd } from "./add";
import { connectManageTA, injectManageTA } from "./context";

export const roleLabels = {
  owner: "Administrator",
  teacher: "Teacher",
};

export const PageManageTA = connectManageTA(
  injectManageTA(
    ({
      store: {
        states: [state, setState],
        control,
        user,
        t,
        id,
        secid,
      },
      ...props
    }) => {
      const handleRemove = (remove) => () =>
        setState((s) => ({ ...s, remove }));
      const handleRemoveConfirm = async () => {
        await control.removeUser({ uid: state?.remove?.id });
        handleRemove(null)();
      };

      return (
        <MainContainer>
          <Container maxWidth="md">
            <ContentHeader label="Manage TA"
              breadcrumbs={[
                { label: t("Home"), to: "/" },
                { label: t("Courses.MyCourse"), to: "/" },
                { label: t("Section"), to: `/edit/${id}/section` },
                { label: t("ManageTA") },
              ]}
              actions={<TAAdd />} />
            <DataGrid
              loading={!state?.fetched}
              columns={[
                {
                  field: "displayName",
                  headerName: "Name",
                  width: 360,
                  valueGetter: (params) => {
                    const { uid } = user;
                    return params.row.info.displayName + (uid === params.id ? ` (You)` : "");
                  },
                },
                {
                  field: "email",
                  headerName: "E-mail",
                  renderCell: (params) => (
                    <Link href={`mailto:${params.row.info.email}`} target="_blank">
                      {params.row.info.email}
                    </Link>
                  ),
                  width: 240,
                },
                {
                  field: "action",
                  headerName: "Edit",
                  sortable: false,
                  filterable: false,
                  renderCell: (params) => {
                    return (
                      <>
                        <KuiActionIcon
                              tx="remove"
                              onClick={handleRemove(params?.row)}
                            />
                        <Tooltip title={`User ID: ${params.id}`}>
                          <IconButton size="small">
                            <FontAwesomeIcon size="xs" icon={faInfoCircle} />
                          </IconButton>
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
              autoHeight
              rows={state?.docs}
              pageSize={25}
              disableSelectionOnClick
            />
          </Container>
          <DialogRemove
            title={t("Remove")}
            label={t("Do you want to remove", {
              name: state?.remove?.displayName,
            })}
            open={Boolean(state?.remove)}
            onClose={handleRemove(null)}
            onConfirm={handleRemoveConfirm}
          />
        </MainContainer>
      );
    }
  )
);
