import { createContext, useContext, useEffect, useState } from "react";
import { firebase } from "Controller/firebase";
import { KGContext, SkeletonController } from "@knowgistics/core";
import { UserCtl } from "Controller/users";

class Controller extends SkeletonController {
  constructor(user) {
    super(firebase);

    this.user = user;
    this.prefix = process.env.REACT_APP_PREFIX;
  }
  watch = (callback) => {
    return this.path("roles", this.prefix).onSnapshot(async (snapshot) => {
      const users = Object.keys(snapshot.data()).map((id) => ({
        id,
        role: snapshot.data()[id],
      }));
      const uids = users.map((user) => user.id);
      UserCtl.getUsers(this.user, uids).then((data) => {
        const newUsers = users
          .map((user) => {
            const info = data.find(u => u.uid === user.id)
            return info ? { ...user, ...info } : null;
          })
          .filter((u) => u);
        callback(newUsers);
      });
    });
  };
  add = async ({ uid }) => {
    await this.path("roles", this.prefix).update({ [uid]: "teacher" });
  };
  removeUser = async ({ uid }) => {
    await this.path("roles", this.prefix).update({ [uid]: this.delete() });
  };
  changeRole = async (uid, value) => {
    await this.path("roles", this.prefix).update({ [uid]: value });
  };
}

const ManageTeacherContext = createContext({});

const ManageTeacherProvider = ({ children, ...props }) => {
  const { t, addAlert, user } = useContext(KGContext);
  const [control, setControl] = useState();
  const [state, setState] = useState({
    fetched: false,
    docs: [],
    remove: null,
  });
  const store = {
    states: [state, setState],
    control,
    addAlert,
    user,
    t,
  };

  useEffect(() => {
    if (user) {
      const control = new Controller(user);
      setControl(control);
      return control.watch((docs) => {
        setState((s) => ({ ...s, fetched: true, docs }));
      });
    }
  }, [user]);

  return (
    <ManageTeacherContext.Provider value={store}>
      {children}
    </ManageTeacherContext.Provider>
  );
};

export const connectManageTeacher = (Comp) => (props) =>
  (
    <ManageTeacherProvider {...props}>
      <Comp {...props} />
    </ManageTeacherProvider>
  );

export const injectManageTeacher = (Comp) => (props) => {
  const store = useContext(ManageTeacherContext);
  return <Comp {...props} store={store} />;
};
