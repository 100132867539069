import { db } from "Controller/firebase";

export const Controller = {
  getViewQuestions: async (id) => {
    const query = await db
      .collection("questions")
      .doc(id)
      .get();
    return { ...query.data() };
  },
  
};
