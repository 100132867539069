import "firebase/auth";
import { DateToStr, SkeletonController } from "@knowgistics/core";
import { firebase } from "Controller/firebase";

export type SnapshotDoc =
  firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
export type QuerySnapshot =
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
export type QuerySnapshotDoc =
  firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

export type CourseDocument = {
  id: string;
  title: string;
  date: string;
  cover?: {
    thumbnail?: string;
    original?: string;
  };
  type: string;
};

export class CourseController extends SkeletonController {
  user: firebase.User;

  constructor(user: firebase.User) {
    super(firebase);

    this.user = user;
    this.prefix = process.env.REACT_APP_PREFIX;
  }

  private toDoc = <T extends unknown>(doc: SnapshotDoc | QuerySnapshotDoc): T =>
    ({
      ...doc.data(),
      id: doc.id,
    } as T);

  watch = (callback: (docs: CourseDocument[]) => void) => {
    return firebase
      .firestore()
      .collection("courses")
      .where("user", "==", this.user.uid)
      .where("type", "in", [
        `${process.env.REACT_APP_PREFIX}course`,
        `${process.env.REACT_APP_PREFIX}course-private`,
      ])
      .orderBy("datemodified", "desc")
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              id: doc.id,
              date: DateToStr(doc.data(), "YYYY/MM/DD LT"),
            } as CourseDocument)
        );
        callback(docs);
      });
  };

  watchBin = (callback: (docs: CourseDocument[]) => void) => {
    return this.path("courses")
      .where("user", "==", this?.user?.uid)
      .where("type", "==", `${this.prefix}course-remove`)
      .onSnapshot((snap: QuerySnapshot) => {
        const docs = snap.docs.map((doc) => this.toDoc<CourseDocument>(doc));
        callback(docs);
      });
  };

  add = (): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > => {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("courses")
        .add({
          title: "Untitled course",
          datecreate: firebase.firestore.FieldValue.serverTimestamp(),
          datemodified: firebase.firestore.FieldValue.serverTimestamp(),
          type: `${process.env.REACT_APP_PREFIX}course-private`,
          user: this.user.uid,
          prefix: process.env.REACT_APP_PREFIX,
        })
        .then((snapshot) => resolve(snapshot))
        .catch((err) => reject(err));
    });
  };
  remove = async (id: string) => {
    return await firebase
      .firestore()
      .collection("courses")
      .doc(id)
      .update({
        type: `${process.env.REACT_APP_PREFIX}course-remove`,
        user: this.user?.uid,
      });
  };
  restoreBin = async (id: string) =>
    await this.path("courses", id).update({
      type: `${this.prefix}course-private`,
    });
  removeBin = async (id: string) => {
    const setBatch = () => firebase.firestore().batch();
    const refC = (id: string) => this.path("courses", id);
    const refQ = (id: string) => this.path("questions", id);

    const lessons = (
      await this.path("courses").where("parent", "==", id).get()
    ).docs.map(this.toDoc);

    const banks = (
      await this.path("questions").where("parent", "==", id).get()
    ).docs.map(this.toDoc);

    for (let i = 0; i < banks.length; i++) {
      const batch = setBatch();
      const bank = banks[i];
      (
        await this.path("questions").where("parent", "==", bank?.id).get()
      ).docs.map((doc: SnapshotDoc) => batch.delete(refQ(doc.id)));
      await batch.commit();
    }

    const mainBatch = setBatch();

    banks.forEach((bank: SnapshotDoc) => mainBatch.delete(refQ(bank?.id)));
    lessons.forEach((lesson: SnapshotDoc) =>
      mainBatch.delete(refC(lesson?.id))
    );
    mainBatch.delete(refC(id));

    await mainBatch.commit();

    return 0;
  };

  // #toDoc = (doc) => ({ ...doc.data(), id: doc.id });
  // course = {
  //   get: async () => {
  //     const courses = (
  //       await this.path("courses")
  //         .where("user", "==", this?.user?.uid)
  //         .where("type", "in", [
  //           `${this.prefix}course`,
  //           `${this.prefix}course-private`,
  //         ])
  //         .get()
  //     ).docs
  //       .map(this.#toDoc)
  //       .sort((a, b) => a?.title?.localeCompare(b?.title));
  //     return courses;
  //   },
  //   watchBin: (callback) => {
  //     return this.path("courses")
  //       .where("user", "==", this?.user?.uid)
  //       .where("type", "==", `${this.prefix}course-remove`)
  //       .onSnapshot((snap) => {
  //         callback(snap.docs.map(this.#toDoc));
  //       });
  //   },
  //   removeBin: async (id) => {
  //     const setBatch = () => this.firebase.firestore().batch();
  //     const refC = (id) => this.path("courses", id);
  //     const refQ = (id) => this.path("questions", id);

  //     const lessons = (
  //       await this.path("courses").where("parent", "==", id).get()
  //     ).docs.map(this.#toDoc);

  //     const banks = (
  //       await this.path("questions").where("parent", "==", id).get()
  //     ).docs.map(this.#toDoc);

  //     for (let i = 0; i < banks.length; i++) {
  //       const batch = setBatch();
  //       const bank = banks[i];
  //       (
  //         await this.path("questions").where("parent", "==", bank?.id).get()
  //       ).docs.map((doc) => batch.delete(refQ(doc.id)));
  //       await batch.commit();
  //     }

  //     const mainBatch = setBatch();

  //     banks.forEach((bank) => mainBatch.delete(refQ(bank?.id)));
  //     lessons.forEach((lesson) => mainBatch.delete(refC(lesson?.id)));
  //     mainBatch.delete(refC(id));

  //     await mainBatch.commit();

  //     return 0;
  //   },
  // };

  // questionbank = {
  //   get: async (id) => {
  //     return (await this.path("questions", id).get()).data();
  //   },
  //   getBin: async (parent) => {
  //     return (
  //       await this.path("questions")
  //         .where("parent", "==", parent)
  //         .where("type", "==", `${this.prefix}category-remove`)
  //         .get()
  //     ).docs.map(this.#toDoc);
  //   },
  //   list: async (parent) => {
  //     const lists = (
  //       await this.path("questions").where("parent", "==", parent).get()
  //     ).docs.map(this.#toDoc);
  //     return lists;
  //   },
  //   clone: async ({ questionBankId, courseId }) => {
  //     const bank = await this.questionbank.get(questionBankId);
  //     const lists = await this.questionbank.list(questionBankId);

  //     const batch = this.firebase.firestore().batch();

  //     const ref = () => this.firebase.firestore().collection("questions").doc();
  //     const refBank = this.firebase.firestore().collection("questions").doc();

  //     batch.set(refBank, {
  //       ...bank,
  //       title: bank?.title + " copy",
  //       datemodified: this.timestamp(),
  //       parent: courseId,
  //     });

  //     lists.forEach((list) =>
  //       batch.set(ref(), {
  //         ...list,
  //         datamodified: this.timestamp(),
  //         parent: refBank.id,
  //       })
  //     );

  //     await batch.commit();

  //     return refBank.id;
  //   },
  //   restoreBin: async (id) => {
  //     this.path("questions", id).update({ type: `${this.prefix}category` });
  //   },
  //   removeBin: async (id) => {
  //     const batch = this.firebase.firestore().batch();

  //     const ref = (id) =>
  //       this.firebase.firestore().collection("questions").doc(id);
  //     const lists = await this.questionbank.list(id);

  //     batch.delete(ref(id));
  //     lists.forEach((list) => batch.delete(ref(list.id)));

  //     await batch.commit();
  //   },
  // };

  // lesson = {
  //   get: async (id) => (await this.path("courses", id).get()).data(),
  //   lastSort: async (courseId) => {
  //     const result = (
  //       await this.path("courses").where("parent", "==", courseId).get()
  //     ).docs.map(this.#toDoc);
  //     if (result.length) {
  //       const sorted = result.sort((a, b) => b?.sort - a?.sort);
  //       return (sorted?.[0]?.sort ?? 0) + 1;
  //     } else {
  //       return 0;
  //     }
  //   },
  //   clone: async ({ lessonId, courseId }) => {
  //     if (!Boolean(lessonId && courseId)) {
  //       throw new Error("Invalid parameter");
  //     }

  //     const lesson = await this.lesson.get(lessonId);

  //     const data = {
  //       ...lesson,
  //       title: `${lesson?.title} copy`,
  //       datemodified: this.timestamp(),
  //       parent: courseId,
  //       sort: await this.lesson.lastSort(courseId),
  //     };

  //     if (lesson?.type.includes("question")) {
  //       const questionid = await this.questionbank.clone({
  //         questionBankId: lesson?.questionid,
  //         courseId,
  //       });
  //       const result = await this.path("courses").add({ ...data, questionid });
  //       return result.id;
  //     } else {
  //       const result = await this.path("courses").add(data);
  //       return result.id;
  //     }
  //   },
  // };
}
