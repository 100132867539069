import {
  Container,
  ContentHeader,
  DialogRemove,
  DialogPre,
  DuotoneButton,
  ActionIcon,
  ListDocs,
  KGContext,
} from "@knowgistics/core";
import { useContext, useEffect, useState } from "react";
import { connectSection, SectionContext } from "../SectionEdit/context";
import { faEdit, faPlus, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { SectionController } from "Controller/sectionController";

export const PageSecClass = connectSection((props) => {
  const { id, secid, control, ...store } = useContext(SectionContext);
  const { addAlert, user, t } = useContext(KGContext);
  const [data] = store.classes;
  const [addNew, setAddNew] = useState(null);
  const [edit, setEdit] = useState(false);
  const [remove, setRemove] = useState(null);
  const [state] = store.state;
  const [controller, setController] = useState(null);
  const getProps = (key) => ({
    fullWidth: true,
    variant: "outlined",
    value: (addNew && addNew[key]) || "",
    onChange: handleChange(key),
    style: {
      marginBottom: 16,
    },
  });

  useEffect(() => {
    document.title = `Course | ${process.env.REACT_APP_SITE_NAME}`;
    if (user) {
      const controller = new SectionController(user, secid);
      setController(controller);
    }
  }, [user, secid]);

  const handleRemove = (remove) => () => setRemove(remove);
  const handleRemoveConfirm = async () => {
    if (remove) {
      await controller.remove(remove.id);
      setRemove(null);
    }
    addAlert({ label: "Delete Success." });
  };
  const handleEdit = (stdList) => () => {
    setEdit(true);
    setAddNew(stdList);
  };
  const handleChange = (key) => (e) =>
    setAddNew((s) => ({ ...s, [key]: e.target.value }));
  const handleConfirm = () => {
    if (addNew) {
      let textAlert = null;
      if (edit) {
        control.updateClass(addNew);
        textAlert = "Update";
      } else {
        control.addClass(addNew);
        textAlert = "Add";
      }
      setAddNew(null);
      setEdit(false);
      addAlert({ label: `${textAlert} Success.` });
      textAlert = null;
    }
  };

  return (
    <Box py={6}>
      <Container maxWidth="sm">
        <ContentHeader
          label={t("Class")}
          breadcrumbs={[
            { label: t("Home"), to: `/` },
            { label: t("Courses.MyCourse"), to: `/` },
            { label: t("Section"), to: `/edit/${id}/section` },
            { label: t("Class") },
          ]}
          actions={
            <DialogPre
              title="Add Class"
              maxWidth="sm"
              label="Input Label"
              button={
                <DuotoneButton
                  variant="outlined"
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  onMouseUp={() => {
                    setAddNew(null);
                  }}
                >
                  Add
                </DuotoneButton>
              }
              onConfirm={handleConfirm}
            >
              <TextField label="Title" {...getProps("title")} />
              <TextField
                label="Date"
                type="date"
                {...getProps("date")}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Start Time"
                type="time"
                {...getProps("sttime")}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="End Time"
                type="time"
                {...getProps("edtime")}
                InputLabelProps={{ shrink: true }}
              />
              <TextField label="Room" {...getProps("room")} />
            </DialogPre>
          }
        />
        <ListDocs
          divider
          fetched={state.fetched}
          docs={data.docs}
          component={(doc, index) => (
            <ListItem
              divider
              button
              dense
              key={doc.id}
              component={Link}
              to={`/edit/${id}/section/${secid}/class/${doc.id}`}
              target="_blank"
            >
              <ListItemText
                primary={doc.label || doc.title}
                secondary={`Time: ${doc.sttime} - ${doc.edtime} | Room: ${doc.room}`}
                secondaryTypographyProps={{ variant: "caption" }}
              />
              <ListItemSecondaryAction>
                <DialogPre
                  title="Edit Class"
                  maxWidth="sm"
                  label="Input Label"
                  button={
                    <ActionIcon
                      icon={faEdit}
                      color="primary"
                      onMouseUp={handleEdit(doc)}
                    />
                  }
                  onConfirm={handleConfirm}
                >
                  <TextField label="Title" {...getProps("title")} />
                  <TextField label="Date" type="date" {...getProps("date")} />
                  <TextField
                    label="Start Time"
                    type="time"
                    {...getProps("sttime")}
                  />
                  <TextField
                    label="End Time"
                    type="time"
                    {...getProps("edtime")}
                  />
                  <TextField label="Room" {...getProps("room")} />
                </DialogPre>
                <ActionIcon
                  color="secondary"
                  icon={faTrash}
                  onClick={handleRemove(doc)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        />
      </Container>
      <DialogRemove
        title="Remove Classes"
        label={
          remove ? (
            <>
              Do you want to remove <strong>"{remove.title}"</strong>?
            </>
          ) : null
        }
        open={Boolean(remove)}
        onClose={handleRemove(null)}
        onConfirm={handleRemoveConfirm}
      />
    </Box>
  );
});
