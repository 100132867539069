import { Typography, withStyles, Box, Fab } from "@material-ui/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Heading = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}))((props) => <Typography variant="h5" {...props} />);

export const MatchBox = withStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius * 2,
    "&:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}))(Box);

export const OptionsBox = withStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius * 2,
    "&:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}))(Box);

export const AddButton = withStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}))(({ classes, ...props }) => {
  return (
    <div>
    <Fab aria-label="Add" className={classes.fab} color="primary" {...props}>
      <FontAwesomeIcon size="2x" icon={faPlus} />
    </Fab>
    </div>
  );
});

export const SubHeading = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}))(props => <Typography variant="h6" {...props} />);

