import { db } from "Controller/firebase";
import { createContext, useEffect, useState } from "react";

export const MultipleContext = createContext({});

export const genKey = () => Math.floor(Math.random() * 100000);

export const newOptions = () => ({
  key: genKey(),
  value: null,
  data: {},
  type: "paragraph",
});

const defaultData = () => ({
  question: null,
  answer: null,
  options: [newOptions(), newOptions()],
  qtype: "paragraph",
});

const MultipleProvider = (props) => {
  const { parent, id } = props.match.params;
  const [data, setData] = useState({
    question: null,
    answer: null,
    options: [newOptions(), newOptions()],
  });
  const [state, setState] = useState({
    fetching: false,
    remove: -1,
  });

  const store = {
    parent,
    id,
    history: props.history,
    data: [data, setData],
    state: [state, setState],
  };

  useEffect(() => {
    if (id) {
      setState((s) => ({ ...s, fetching: true }));
      db.collection("questions")
        .doc(id)
        .get()
        .then((snap) => {
          const data = snap.data();
          if (data) {
            setData((d) => ({ ...d, ...data }));
          }
          setState((s) => ({ ...s, fetching: false }));
        });
    } else {
      setData({ ...defaultData() });
    }
  }, [id]);
  return (
    <MultipleContext.Provider value={store}>
      {props.children}
    </MultipleContext.Provider>
  );
};

export const connectContext = (Comp) => (props) =>
  (
    <MultipleProvider {...props}>
      <Comp {...props} />
    </MultipleProvider>
  );
