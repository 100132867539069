import { Container, ContentHeader, KGContext } from "@knowgistics/core";
import { Box, styled } from "@material-ui/core";
import { TableDocs } from "Components/table.docs";
import {
  SectionSubjectiveCtl,
  SubjectiveDocument,
} from "Controller/section.subjective";
import { useContext, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";

export interface SectionSubjectiveProps
  extends RouteComponentProps<{ id: string; secid: string }> {
  ta?: boolean;
}

const LinkStyled = styled(Link)(({ theme }) => ({
  color: theme.palette.info.main,
}));

export const SectionSubjective = (props: SectionSubjectiveProps) => {
  const {
    state: [{ userFetched }],
    user,
    t,
  } = useContext(KGContext);
  const { id, secid } = props.match.params;
  const [state, setState] = useState<{
    loading: boolean;
    docs: SubjectiveDocument[];
  }>({ loading: true, docs: [] });

  useEffect(() => {
    if (id && secid && userFetched && user) {
      SectionSubjectiveCtl.watchList(id, secid).then((docs) => {
        setState((s) => ({ ...s, docs, loading: false }));
      });
    }
  }, [id, secid, user, userFetched]);

  return (
    <Box sx={{ py: 6 }}>
      <Container maxWidth="sm">
        <ContentHeader
          label={t("Subjective")}
          breadcrumbs={[{ label: "Home", to: "/" }, { label: "Course" }]}
        />
        <TableDocs
          loading={state.loading}
          rows={state.docs}
          columns={[
            {
              field: "title",
              headerName: t("Title"),
              renderCell: ({ row, value }) => (
                <LinkStyled
                  to={
                    props.ta
                      ? `/ta/${id}/${secid}/subjective/${row.id}`
                      : `/edit/${id}/section/${secid}/subjective/${row.id}`
                  }
                >
                  {value}
                </LinkStyled>
              ),
              width: 360,
            },
            { field: "duedate", headerName: t("Date") },
          ]}
          autoHeight
          disableSelectionOnClick
        />
      </Container>
    </Box>
  );
};
