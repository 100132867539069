import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Container,
  ContentHeader,
  isQuizComplete,
  KGContext,
  MainContainer,
  QuizDisplay,
  DuotoneButton,
} from "@knowgistics/core";
import { firebase } from "Controller/firebase";
import { PageNotFound } from "../NotFound";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-duotone-svg-icons";
import { CheckAnswer } from "./CheckAnswer";

/** ####################################################################################################
 * 
 * CONTROLLER
 * 
 #################################################################################################### */
class Controller {
  constructor(qid, user) {
    this.qid = qid;
    this.user = user;
  }
  get = async () => {
    const result = await firebase.functions().httpsCallable("ViewQuestion")({
      questionId: this.qid,
      token: await this.user.getIdToken(),
    });
    return result.data;
  };
}
const Context = createContext({});
const Provider = ({ children, ...props }) => {
  const { user } = useContext(KGContext);
  const [control, setControl] = useState(null);
  const [state, setState] = useState({
    fetched: false,
    data: null,
    error: false,
    page: 1,
  });
  const [checked, setChecked] = useState({});
  const store = {
    ...props,
    ...props.match.params,
    control,
    state: [state, setState],
    checked: [checked, setChecked],
  };
  useEffect(() => {
    if (store.qid && user) {
      const control = new Controller(store.qid, user);
      setControl(control);
      control.get().then((data) => {
        if (data.error) {
          setState((s) => ({ ...s, fetched: true, error: data.error }));
        } else {
          document.title = `${data.title} | Quiz — ${process.env.REACT_APP_SITE_NAME}`;
          setState((s) => ({ ...s, data, fetched: true, error: false }));
        }
      });
    }
  }, [store.qid, user]);
  return (
    <Context.Provider value={store}>
      {Boolean(state.fetched && state.error) ? <PageNotFound /> : children}
    </Context.Provider>
  );
};
const connect = (Comp) => (props) =>
  <Provider {...props} children={<Comp {...props} />} />;

/** ####################################################################################################
 * 
 * COMPONENTS
 * 
 #################################################################################################### */
const Header = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 2,
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
}))(Box);

const Progress = withStyles((theme) => ({
  root: {
    height: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
  },
}))((props) => <LinearProgress variant="determinate" {...props} />);

/** ####################################################################################################
 * 
 * MAIN
 * 
 #################################################################################################### */

export const PageViewQuestion = connect((props) => {
  const store = useContext(Context);
  const [state, setState] = store.state;
  const [checked, setChecked] = store.checked;

  const [answers, setAnswers] = React.useState(false);

  const getBank = (key) => (state.data && state.data[key]) || null;
  const getCount = Boolean(state.data && state.data.questions)
    ? state.data.questions.length
    : 1;
  const progress = (state.page / getCount) * 100;

  const handleChangePage = (e, page) => setState((s) => ({ ...s, page }));
  const handleChangeChecked = (key) => (n) =>
    setChecked((c) => ({ ...c, [key]: n }));
  const handleNext = (doc) => () => {
    if (isQuizComplete(doc, checked[doc.id])) {
      setState((s) => ({ ...s, page: s.page + 1 }));
    }
  };

  const NextButton = ({ doc }) => (
    <Button
      variant="contained"
      disableElevation
      color="primary"
      disabled={!isQuizComplete(doc, checked[doc.id])}
      onClick={handleNext(doc)}
      endIcon={<FontAwesomeIcon icon={faChevronRight} />}
    >
      NEXT
    </Button>
  );

  return (
    <MainContainer loading={!state.fetched} signInOnly>
      <Container maxWidth="sm">
        {Boolean(answers) ? (
          <CheckAnswer data={state.data} checked={checked} />
        ) : (
          <>
            <ContentHeader
              label={getBank("title")}
              secondary={`แบบทดสอบนี้มีจำนวนทั้งหมด ${getCount} ข้อ`}
              actions={
                Boolean(answers) && (
                  <DuotoneButton variant="outlined">
                    {"Restart Quiz"}
                  </DuotoneButton>
                )
              }
            />
            <Header mb={2}>
              <Box p={2}>
                <Typography color="primary" variant="caption">
                  {Math.ceil(progress)}% Complete
                </Typography>
                <Box mb={1} />
                <Progress value={progress} />
              </Box>
              <Divider />
              <Box display="flex" justifyContent="center" p={2}>
                <Pagination
                  count={getCount}
                  color="primary"
                  page={state.page}
                  onChange={handleChangePage}
                  disabled
                />
              </Box>
            </Header>
            {state.data &&
              Array.isArray(state.data.questions) &&
              state.data.questions.map((doc, index) => (
                <Box hidden={state.page !== index + 1} key={doc.id}>
                  <QuizDisplay
                    value={doc}
                    checked={checked[doc.id]}
                    onCheckChange={handleChangeChecked(doc.id)}
                  />
                  <Box display="flex">
                    <Box flex={1} />
                    {state.page >= getCount ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setAnswers(true);
                        }}
                        disabled={!isQuizComplete(doc, checked[doc.id])}
                      >
                        submit
                      </Button>
                    ) : (
                      <NextButton doc={doc} />
                    )}
                  </Box>
                </Box>
              ))}
          </>
        )}
      </Container>
    </MainContainer>
  );
});
