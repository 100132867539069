import { Skeleton } from "@material-ui/lab";
import {
  Container,
  ContentHeader,
  ListDocs,
  MainContainer,
  DialogRemove,
  DialogAlert,
  ActionIcon,
} from "@knowgistics/core";
import { useContext } from "react";
import { connectClassSection, ClassSectionContext } from "./context";
import QRCode from "react-qr-code";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  Box,
  Grid,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.success.main,
  },
}));

export const PageClassSection = connectClassSection((props) => {
  const classes = useStyles();
  const { id, secid, classid, control, ...store } =
    useContext(ClassSectionContext);
  const [state, setState] = store.state;

  const handleRemoveSet = (remove) => () => setState((s) => ({ ...s, remove }));
  const handleChecked = (stdchecked) => () =>
    setState((s) => ({ ...s, stdchecked }));

  const handleRemoveConfirm = async () => {
    await control.checkoutStudent(state.remove.id);
    setState((s) => ({ ...s, remove: null }));
  };
  const handleCheckedConfirm = async () => {
    await control.checkInStudent(state.stdchecked.id);
    setState((s) => ({ ...s, stdchecked: null }));
  };
  const getUncheckStudent = () =>
    state.section && Array.isArray(state.section.users)
      ? state.section.users
          .map((std) => ({ ...std, id: std.id }))
          .filter(
            (user) =>
              !(state.checked || []).map((item) => item.id).includes(user.id)
          )
      : [];
  return (
    <MainContainer>
      <Container maxWidth="lg">
        <ContentHeader
          breadcrumbs={[
            { label: "Home", to: `/` },
            { label: "Section", to: `/edit/${id}/section/${secid}/class` },
          ]}
          label={
            state.fetched ? (
              state.data ? (
                state.data.title
              ) : (
                "Class not found"
              )
            ) : (
              <Skeleton width="50%" />
            )
          }
        />

        {state.fetched ? (
          state.data && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Box p={2}>
                    <QRCode
                      value={window.btoa(
                        JSON.stringify({
                          type: "check-in",
                          section: secid,
                          class: classid,
                        })
                      )}
                    />
                    <Typography>
                      <b>{state.data.title}</b>
                    </Typography>
                    <Typography variant="caption" component="div">
                      วันที่ {state.data.date}
                    </Typography>
                    <Typography variant="caption" component="div">
                      เวลา {state.data.sttime} - {state.data.edtime} น.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box p={2}>
                    <Typography variant="h5" color="textPrimary">
                      เข้าเรียนแล้ว ({state.checked.length}/
                      {state.section && state.section.users.length})
                    </Typography>
                    <ListDocs
                      fetched={state.fetched}
                      docs={state.checked}
                      component={(doc, index) => {
                        const student =
                          (state.section &&
                            state.section.users.find((i) => i.id === doc.id)) ||
                          {};
                        return (
                          <ListItem divider dense key={index}>
                            <ListItemAvatar>
                              <Avatar className={classes.avatar}>
                                <FontAwesomeIcon icon={faCheck} />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                student.studentName ||
                                `Student "${doc.id}" not in this class`
                              }
                              secondary={moment(
                                doc.time ? doc.time.toMillis() : Date.now()
                              ).format("LL เวลา LT น.")}
                              secondaryTypographyProps={{ variant: "caption" }}
                            />
                            <ListItemSecondaryAction>
                              <ActionIcon
                                icon={faTrash}
                                color="secondary"
                                onClick={handleRemoveSet(doc)}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box p={2}>
                    <Typography variant="h5" color="textPrimary">
                      ยังไม่เข้าเรียน ({getUncheckStudent().length}/
                      {state.section && state.section.users.length})
                    </Typography>
                    <ListDocs
                      fetched={state.fetched}
                      docs={getUncheckStudent()}
                      component={(doc, index) => (
                        <ListItem divider dense key={index}>
                          <ListItemAvatar>
                            <Avatar>
                              <FontAwesomeIcon icon={faClock} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={doc.studentName} />
                          <ListItemSecondaryAction>
                            <DialogAlert
                              title={`CheckIn`}
                              label={
                                <>
                                  คุณต้องการ CheckIn{" "}
                                  <b>
                                    "
                                    {state.stdchecked
                                      ? state.stdchecked.studentName
                                      : ""}
                                    "
                                  </b>{" "}
                                  ใช่หรือไม่?{" "}
                                </>
                              }
                              onConfirm={handleCheckedConfirm}
                            >
                              <ActionIcon
                                icon={faCheck}
                                color="primary"
                                style={{ color: "#4BB543" }}
                                onMouseUp={handleChecked(doc)}
                              />
                            </DialogAlert>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </>
          )
        ) : (
          <Skeleton width="50%" />
        )}
        <DialogRemove
          title={`Checkout`}
          label={(() => {
            if (state.remove) {
              const data = state.section.users.find(
                (val) => val.id === state.remove.id
              );
              return data ? (
                <>
                  คุณต้องการ Checkout <b>{data.studentName}</b> ใช่หรือไม่?
                </>
              ) : (
                "Username not found"
              );
            } else {
              return "";
            }
          })()}
          open={Boolean(state.remove)}
          onConfirm={handleRemoveConfirm}
          onClose={handleRemoveSet(null)}
        />
      </Container>
    </MainContainer>
  );
});
