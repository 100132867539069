import { faCalendar, faEdit } from "@fortawesome/pro-duotone-svg-icons";
import {
  ActionIcon,
  BackLink,
  FeatureImageEdit,
  KGContext,
  KuiButton,
  ListItemLink,
  Schedule,
  TitleEdit,
} from "@knowgistics/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  Select,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  ListItem,
  Button,
  Box,
  Slide,
  SlideProps,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useCourseEdit } from "./context";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { QuerySnapshot } from "Controller/courseController.t";

type ScheduleData = { utc: string; epoch: number };
export type AutomateTypes = {
  id?: string;
  parent: string;
  path: { collection: string; doc: string }[];
  time: string;
  epoch: number;
  type: "update";
  user: string;
  value: {
    type: string;
  };
};

interface SelectVisibilityProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
}

const SelectVisibility = ({
  id,
  value: oldValue,
  onChange,
  ...props
}: SelectVisibilityProps) => {
  const { t, user, firebase } = useContext(KGContext);
  const lists = {
    [`${process.env.REACT_APP_PREFIX}course`]: t("Public"),
    [`${process.env.REACT_APP_PREFIX}course-private`]: t("Private"),
  };
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(
    `${process.env.REACT_APP_PREFIX}course-private`
  );
  const [auto, setAuto] = useState<AutomateTypes | null>(null);
  const completed = Boolean(value !== oldValue);

  const handleOpen = (o: boolean) => () => setOpen(o);
  const handleChange = ({
    target: { value },
  }: ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => setValue(value as string);
  const handleConfirm = () => {
    onChange(value);
    setOpen(false);
  };
  const handleSchedule = async (date: ScheduleData) => {
    const data: AutomateTypes = {
      parent: id,
      path: [{ collection: "courses", doc: id }],
      time: date.utc,
      epoch: date.epoch,
      type: "update",
      user: user.uid,
      value: {
        type: value,
      },
    };
    await firebase.firestore().collection("automates").add(data);
  };
  const handleCancelSchedule = () => {
    if (auto?.id) {
      firebase.firestore().collection("automates").doc(auto.id).delete();
    }
  };

  const getAutomates = useCallback(() => {
    return firebase
      .firestore()
      .collection("automates")
      .where("parent", "==", id)
      .onSnapshot((query: QuerySnapshot) => {
        const docs = query.docs.map(
          (doc) => ({ ...doc.data(), id: doc.id } as AutomateTypes)
        );
        if (docs.length) {
          setAuto(docs[0]);
        } else {
          setAuto(null);
        }
      });
  }, [firebase, id]);

  useEffect(() => {
    setValue(oldValue);
    return getAutomates();
  }, [oldValue, getAutomates]);

  return (
    <List>
      <ListItem divider dense>
        <ListItemText
          primary={t("Visibility")}
          secondary={lists[oldValue] || <Skeleton width="50%" />}
          primaryTypographyProps={{
            variant: "caption",
            color: "textSecondary",
          }}
          secondaryTypographyProps={{
            variant: "body2",
            color: "textPrimary",
          }}
        />
        <ListItemSecondaryAction>
          <ActionIcon icon={faEdit} onClick={handleOpen(true)} />
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleOpen(false)}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "right" } as SlideProps}
      >
        <DialogTitle>{t("Change")}</DialogTitle>
        <DialogContent>
          {auto ? (
            <ListItem
              dense
              component={"div"}
              style={{
                border: "solid 1px rgba(0,0,0,0.12)",
                borderRadius: 8,
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faCalendar} size="2x" />
              </ListItemIcon>
              <ListItemText
                primary={t("ChangeAt", {
                  name: lists[auto.value.type],
                  date: moment(auto.epoch).format("ll LT"),
                })}
              />
            </ListItem>
          ) : (
            <Select
              fullWidth
              variant="outlined"
              value={value}
              onChange={handleChange}
            >
              {Object.keys(lists).map((key) => (
                <MenuItem value={key} key={key}>
                  {lists[key]}
                </MenuItem>
              ))}
            </Select>
          )}
        </DialogContent>
        <DialogActions>
          {auto ? (
            <Button color="secondary" onClick={handleCancelSchedule}>
              {t("Cancel")}
            </Button>
          ) : (
            <Fragment>
              <Schedule onChange={handleSchedule}>
                <Button disabled={!completed}>{t("Schedule")}</Button>
              </Schedule>
              <Box flex={1} />
              <Button
                color="primary"
                disabled={!completed}
                onClick={handleConfirm}
              >
                {t("Save")}
              </Button>
            </Fragment>
          )}

          <KuiButton tx="close" onClick={handleOpen(false)} />
        </DialogActions>
      </Dialog>
    </List>
  );
};

export const Sidebar = () => {
  const { addAlert, t } = useContext(KGContext);
  const { id, tab, control, data } = useCourseEdit();

  const handleChange = (key: string) => async (value: unknown) => {
    const filterValue = JSON.parse(JSON.stringify(value));
    await control?.update({ [key]: filterValue });
    addAlert({ label: "Update Success." });
  };

  return (
    <>
      <BackLink to={"/"} />
      <TitleEdit value={data?.title} onChange={handleChange("title")} />
      <FeatureImageEdit value={data?.cover} onChange={handleChange("cover")} />
      <SelectVisibility
        id={id}
        value={data?.type || `${process.env.REACT_APP_PREFIX}course-private`}
        onChange={handleChange("type")}
      />
      <List disablePadding>
        {[
          { label: t("Detail"), key: "desc" },
          { label: t("Home"), key: "home" },
          { label: t("Lesson"), key: "lesson" },
          { label: t("Courses.QuestionBank"), key: "question" },
          { label: t("Section"), key: "section" },
        ].map((item, index) => (
          <ListItemLink
            button
            divider
            dense
            component={Link}
            key={item.key}
            to={`/edit/${id}/${item.key}`}
            selected={tab === item.key || (index === 0 && !tab)}
          >
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItemLink>
        ))}
      </List>
    </>
  );
};
