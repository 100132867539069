import React from "react";
import { Container, MainContainer } from "@knowgistics/core";
import { QuizDisplay } from "@knowgistics/lms";
import "@knowgistics/lms/dist/index.css";
import { Controller } from "./Controller";
import { Box, Button } from "@material-ui/core";
import CheckAnswerView from "./CheckAnswerView";

export const QuestionView = (props) => {
  const { id } = props.match.params;
  const [fetching, setFetching] = React.useState(true);
  const [doc, setDoc] = React.useState(null);
  const [selected, setSelected] = React.useState({});

  const handleSelectedAnswer = (id) => (ans) =>
    setSelected((s) => ({ ...s, [id]: ans }));
  const handleClear = () => {
    setSelected({});
  };

  React.useEffect(() => {
    if (id) {
      Controller.getViewQuestions(id).then((doc) => {
        setDoc(doc);
        setFetching(false);
      });
    } else {
      console.log("No question props id ..");
    }
  }, [id]);
  return (
    <MainContainer dense signInOnly>
      <Box py={6}>
        <Container maxWidth="sm">
          {!fetching && doc && (
            <React.Fragment>
              <QuizDisplay
                value={doc}
                checked={selected[id]}
                onCheckChange={handleSelectedAnswer(id)}
              />
            </React.Fragment>
          )}
          <Box display="flex">
            <Box flexGrow={1}>
              <CheckAnswerView
                selected={selected}
                data={!fetching && doc}
                id={id}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClear}
              >
                เลือกคำตอบใหม่
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </MainContainer>
  );
};
