import { faSearch } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DialogPre,
  getUserFromEmail,
  KuiButton,
  KuiListItemText,
} from "@knowgistics/core";
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { injectManageTA } from "./context";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const TAAdd = injectManageTA(
  ({
    store: {
      states: [state],
      control,
      t,
      addAlert,
      secid,
    },
    ...props
  }) => {
    const [email, setEmail] = useState("");
    const [result, setResult] = useState(null);
    const isValid = validateEmail(email);
    const added = state?.docs?.find((u) => u.id === result?.uid);

    const handleChangeEmail = ({ target: { value } }) => setEmail(value);
    const handleSearch = async () => {
      const user = await getUserFromEmail(email);
      if (user.code) {
        addAlert({ label: user?.message, severity: "error" });
      } else {
        setResult(user);
      }
    };
    const handleClear = () => setResult(null);
    const handleAdd = async () => {
      await control.add({ uid: result?.uid, secid: secid });
    };

    return (
      <>
        <DialogPre
          title="Add"
          maxWidth="sm"
          button={<KuiButton tx="add" />}
          secondaryActions={
            result && <KuiButton tx="clear" onClick={handleClear} />
          }
        >
          {result ? (
            <>
              <List>
                <Divider />
                <ListItem divider style={{ paddingRight: 96 }}>
                  <ListItemAvatar>
                    <Avatar src={result?.photoURL} />
                  </ListItemAvatar>
                  <KuiListItemText
                    tx="body"
                    primary={result?.displayName}
                    secondary={`${t("Email")}: ${result?.email}`}
                  />
                  <ListItemSecondaryAction>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleAdd}
                      disabled={Boolean(added)}
                    >
                      {t("Add")}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </>
          ) : (
            <>
              <TextField
                label={t("Email")}
                value={email}
                onChange={handleChangeEmail}
              />
              <Box mt={1} />
              <Button
                fullWidth
                variant="outlined"
                size="large"
                startIcon={<FontAwesomeIcon icon={faSearch} />}
                disabled={!isValid}
                onClick={handleSearch}
              >
                {t("Search")}
              </Button>
            </>
          )}
        </DialogPre>
      </>
    );
  }
);
