import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import { SubjectiveController } from "Controller/subjectiveController";
import { KGContext } from "@knowgistics/core";

export const SubjectiveContext = createContext({});

const SubjectiveProvider = ({ children, ...props }) => {
  const { user, t } = useContext(KGContext);
  const [state, setState] = useState({
    fetched: false,
  });
  const [controller, setController] = useState(null);

  const store = {
    ...props,
    ...props.match.params,
    controller,
    state: [state, setState],
  };

  useEffect(() => {
    document.title = `${t("SubjectiveQuiz")} | ${
      process.env.REACT_APP_SITE_NAME
    }`;
    if (user) {
      const controller = new SubjectiveController(
        user,
        store.id,
        store.sjvid,
        store.secid
      );
      setController(controller);
      return controller.watch((docs) => {
        if (docs.sections.length && !store.secid) {
          store.history.push(
            `/view/${store.id}/subjective/${store.sjvid}/section/${docs.sections[0].id}`
          );
        }
        setState((s) => ({
          ...s,
          course: docs.course,
          sections: docs.sections,
          fetched: true,
        }));
      });
    }
  }, [user, store.id, store.secid, store.sjvid, store.history, t]);

  return (
    <SubjectiveContext.Provider value={store}>
      {children}
    </SubjectiveContext.Provider>
  );
};

export const connectSubjective = (Comp) => (props) =>
  (
    <SubjectiveProvider {...props}>
      <Comp {...props} />
    </SubjectiveProvider>
  );
